import React, { useState } from 'react';
import axios from 'axios';
import { message as antdMessage } from 'antd';
import Login from '@components/login/Login';
import './FeedbackPage.css';
import { API_ENDPOINTS, API_HEADERS } from '@configs/api';
import { Button, Layout, Input } from 'antd';

const FeedbackPage = () => {
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const token = localStorage.getItem('token');
        if (!token) {
            setIsLoginModalVisible(true);
            return;
        }

        try {
            const response = await axios.post(API_ENDPOINTS.feedback, {
                message: feedbackMessage,
            }, {
                headers: {
                    ...API_HEADERS,
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                setResponseMessage('感谢你的反馈!');
                setFeedbackMessage('');
            } else {
                setResponseMessage('发送失败，请稍候再试！');
            }
        } catch (error) {
            console.error('Error during feedback submission:', error);
            setResponseMessage('发送失败，请稍候再试！');
        }
    };
    const handleLoginSuccess = () => {
        setIsLoginModalVisible(false);
        antdMessage.success('登录成功', 1.5);
        handleSubmit();
    };

    return (
        <div className="feedback-page">
            <h2>反馈</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <textarea
                        className="feedback-textarea"
                        value={feedbackMessage}
                        onChange={(e) => setFeedbackMessage(e.target.value)}
                        required
                    />
                </div>
                <div className="control-buttons">
                    <Button type="primary" htmlType="submit">提交</Button>
                </div>
            </form>
            {responseMessage && <p>{responseMessage}</p>}
            {isLoginModalVisible && (
                <Login
                    onClose={() => setIsLoginModalVisible(false)}
                    onLogin={handleLoginSuccess}
                />
            )}
        </div>
    );
};

export default FeedbackPage;

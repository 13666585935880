import React, { useState, useRef, useEffect } from 'react';
import { Input, Button } from 'antd';
import categories from '@configs/categories_choose.js';
import '@styles/Inpaint/CustomInput.css';

const { TextArea } = Input;

const CustomInput = ({ input, placeholder, onChange, ...props }) => {
  const [showBox, setShowBox] = useState(false);
  const [boxStyle, setBoxStyle] = useState({ top: 0, left: 0, width: 0 });
  const [searchTerm, setSearchTerm] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [pressedLabels, setPressedLabels] = useState(new Set());
  const [allLabels, setAllLabels] = useState([]); // 存储所有标签
  const [selectedCategory, setSelectedCategory] = useState(null);
  const inputRef = useRef(null);
  const boxRef = useRef(null);

  useEffect(() => {
    // 提取所有最小标签并存储在 allLabels 状态中
    const labels = [];
    Object.entries(categories).forEach(([_, subCategories]) => {
      Object.entries(subCategories).forEach(([_, subCategoryValue]) => {
        Object.entries(subCategoryValue).forEach(([_, subSubCategoryValue]) => {
          labels.push(...Object.keys(subSubCategoryValue));
        });
      });
    });
    setAllLabels(labels);
  }, []);

  useEffect(() => {
    const inputElement = inputRef.current.resizableTextArea.textArea;
    const resizeObserver = new ResizeObserver(() => {
      const rect = inputElement.getBoundingClientRect();
      setBoxStyle((prevStyle) => ({
        ...prevStyle,
        width: rect.width,
      }));
    });

    resizeObserver.observe(inputElement);

    return () => {
      resizeObserver.disconnect();
    };
  }, [inputRef]);

  const handleFocus = () => {
    const inputElement = inputRef.current.resizableTextArea.textArea;
    if (inputElement) {
      const rect = inputElement.getBoundingClientRect();
      const topPosition = rect.top > 200 ? rect.top - 200 : rect.bottom;

      setBoxStyle({
        top: topPosition,
        left: rect.left,
        width: rect.width,
      });
      setShowBox(true);
    }
  };

  const handleBlur = (e) => {
    if (boxRef.current && boxRef.current.contains(e.relatedTarget)) {
      return;
    }
    setShowBox(false);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    updatePressedLabels(newValue);
    onChange(newValue); // 调用回调函数，将值传递给父组件
  };

  const updatePressedLabels = (value) => {
    const newPressedLabels = new Set();
    allLabels.forEach((label) => {
      if (new RegExp(label, 'i').test(value)) {
        newPressedLabels.add(label);
      }
    });
    setPressedLabels(newPressedLabels);
  };

  const handleLabelClick = (label) => {
    const input = inputRef.current.resizableTextArea.textArea;
    const cursorPos = input.selectionStart;
    const textBeforeCursor = input.value.substring(0, cursorPos);
    const textAfterCursor = input.value.substring(cursorPos);

    const newValue = `${textBeforeCursor}${label}， ${textAfterCursor}`;
    setInputValue(newValue);
    setPressedLabels((prev) => new Set(prev).add(label));
    onChange(newValue);
    setTimeout(() => {
      input.focus();
      input.setSelectionRange(cursorPos + label.length + 1, cursorPos + label.length + 1);
    }, 0);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category === selectedCategory ? null : category);
  };

  const filteredCategories = Object.entries(categories).reduce((acc, [categoryKey, subCategories]) => {
    const filteredSubCategories = Object.entries(subCategories).reduce((subAcc, [subCategoryKey, subCategoryValue]) => {
      if (
        subCategoryKey.toLowerCase().includes(searchTerm) ||
        (typeof subCategoryValue === 'object' &&
          !Array.isArray(subCategoryValue) &&
          Object.keys(subCategoryValue).some((label) => label.toLowerCase().includes(searchTerm))) ||
        Object.keys(subCategoryValue).some((label) => label.toLowerCase().includes(searchTerm))
      ) {
        subAcc[subCategoryKey] = subCategoryValue;
      }
      return subAcc;
    }, {});

    if (categoryKey.toLowerCase().includes(searchTerm) || Object.keys(filteredSubCategories).length > 0) {
      acc[categoryKey] = filteredSubCategories;
    }

    return acc;
  }, {});

  return (
    <div className="custom-input-wrapper" style={{ position: 'relative' }}>
      <TextArea
        ref={inputRef}
        placeholder={placeholder}
        value={inputValue}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleInputChange}
        autoSize={{ minRows: 3, maxRows: 6 }}
        {...props}
      />
      {showBox && (
        <div
          className="custom-box"
          ref={boxRef}
          style={{
            position: 'absolute',
            width: `${boxStyle.width}px`,
            zIndex: 1000,
            pointerEvents: 'auto',
            display: 'flex',
          }}
        >
          <div className="category-list" style={{ width: '30%', overflowY: 'auto', backgroundColor: 'rgba(0,0,0,0.5)' }}>
            {Object.entries(filteredCategories).map(([categoryKey, subCategories]) => (
              <div key={categoryKey}>
                {Object.entries(subCategories).map(([subCategoryKey]) => (
                  <Button
                    key={subCategoryKey}
                    type={selectedCategory === subCategoryKey ? 'primary' : 'default'}
                    onClick={() => handleCategoryClick(subCategoryKey)}
                    style={{
                      margin: '5px',
                      width: '90%',
                      display: 'block',
                      borderBottom: '1px solid #000',
                      borderTop: '1px solid #000',
                    }}
                  >
                    {subCategoryKey}
                  </Button>
                ))}
              </div>
            ))}
          </div>
          <div className="label-list" style={{ width: '70%', overflowY: 'auto' }}>
            <Input
              placeholder="Search labels..."
              value={searchTerm}
              onChange={handleSearchChange}
              style={{ marginBottom: '10px' }}
            />
            {selectedCategory &&
              Object.entries(filteredCategories).map(([categoryKey, subCategories]) => (
                <div key={categoryKey}>
                  {Object.entries(subCategories).map(
                    ([subCategoryKey, subCategoryValue]) =>
                      subCategoryKey === selectedCategory && (
                        <div key={subCategoryKey} style={{ marginBottom: '10px' }}>
                          <h2 style={{ margin: 0 }}>{subCategoryKey}</h2>
                          <div style={{ marginTop: '5px' }}>
                            {typeof subCategoryValue === 'object' && !Array.isArray(subCategoryValue) ? (
                              Object.entries(subCategoryValue).map(([childKey, labels]) => (
                                <div key={childKey} style={{ marginBottom: '5px' }}>
                                  <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                                    <h5 style={{ margin: '5px 10px 5px 0' }}>{childKey}</h5>
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                      {Object.keys(labels).map((label) => (
                                        <Button
                                          key={label}
                                          style={{
                                            margin: '2px',
                                            backgroundColor: pressedLabels.has(label)
                                              ? 'gray'
                                              : 'rgba(0,0,0,0.8)',
                                            color: 'white',
                                            borderColor: 'rgba(0,0,0,0.8)',
                                            cursor: pressedLabels.has(label) ? 'not-allowed' : 'pointer',
                                          }}
                                          size="small"
                                          onClick={() => handleLabelClick(label)}
                                          disabled={pressedLabels.has(label)}
                                        >
                                          {label}
                                        </Button>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {Object.keys(subCategoryValue).map((label) => (
                                  <Button
                                    key={label}
                                    style={{
                                      margin: '2px',
                                      backgroundColor: pressedLabels.has(label) ? 'gray' : 'rgba(0,0,0,0.8)',
                                      color: 'white',
                                      borderColor: 'rgba(0,0,0,0.8)',
                                      cursor: pressedLabels.has(label) ? 'not-allowed' : 'pointer',
                                    }}
                                    size="small"
                                    onClick={() => handleLabelClick(label)}
                                    disabled={pressedLabels.has(label)}
                                  >
                                    {label}
                                  </Button>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      ),
                  )}
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomInput;

// src/components/ImageUploader.js
import React, { useState, useRef, useEffect } from 'react';
import { Card, Tooltip, Spin } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { fetchExampleImageData, uploadImage } from '@services/apiService';


const ImageUploader = ({ onImageSelected }) => {
  const [exampleImages, setExampleImages] = useState([]);
  const [loadingExampleImages, setLoadingExampleImages] = useState(true);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const loadExampleImages = async () => {
      try {
        const images = await fetchExampleImageData(sessionStorage.getItem('currentFunc'));
  
        if (images.data.length > 0) {
          const imgUrl = images.data[0];
          console.log(imgUrl)
          setExampleImages(imgUrl);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingExampleImages(false);
      }
    };
  
    loadExampleImages();
  }, [sessionStorage.getItem('currentFunc')]);
  

const handleImageUpload = (event) => {
    event.preventDefault();
    const file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    if (!file) return;
  
    const reader = new FileReader();
    reader.onload = (e) => {
        const img = new Image();
        img.onload = async () => {
          // 设置 Canvas 以便压缩图片
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          
          const maxWidth = 800; // 设置最大宽度
          const scaleFactor = maxWidth / img.width;
          canvas.width = maxWidth;
          canvas.height = img.height * scaleFactor;
     
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          const resizedImageBase64 = canvas.toDataURL('image/jpeg', 0.8); // 压缩到 80% 质量
          const base64Image = resizedImageBase64.split(',')[1]; // 移除前缀

          try {
              const response = await uploadImage(base64Image, sessionStorage.getItem('currentFunc'));
              const imageUrl = response.imageUrl;
              onImageSelected(imageUrl);
          } catch (error) {
            console.error("图片上传失败:", error);
          }
        };
        img.src = e.target.result;
      };
     
    reader.readAsDataURL(file);
  };
  

  const handleDrop = (event) => {
    event.preventDefault();
    handleImageUpload(event);
  };

  const handleDragOver = (event) => event.preventDefault();

  return (
    <Card
      className="upload-prompt-card"
      style={{
        margin: 'auto',
        textAlign: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        color: 'rgba(255, 255, 255, 0.8)',
      }}
      hoverable
      onClick={() => fileInputRef.current && fileInputRef.current.click()}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      <Tooltip
        title={
          <div>
            <div style={{ fontWeight: 'bold', color: '#333' }}>示例图片：</div>
            {loadingExampleImages ? (
              <Spin />
            ) : exampleImages.length > 0 ? (
              <img
                src={exampleImages}
                alt="示例"
                style={{ width: '100px', height: 'auto', cursor: 'pointer' }}
                onClick={(event) => {
                  event.stopPropagation();
                  onImageSelected(exampleImages);
                }}
              />
            ) : (
              <p>无示例图片</p>
            )}
          </div>
        }
        placement="topLeft"
        color="white"
      >
        <div>
          <UploadOutlined style={{ fontSize: '36px', color: '#1890ff' }} />
          <p style={{ marginTop: '10px' }}>点击或拖动图片到这里上传</p>
        </div>
      </Tooltip>
      <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleImageUpload} />
    </Card>
  );
};

export default ImageUploader;

// @services/apiOrganizationService.js
import axios from 'axios';
import { API_ENDPOINTS, API_HEADERS, API_ORGANIZATION_ENDPOINTS } from '@configs/api';
import { refreshToken } from '@services/authService';

const api = axios.create({
  baseURL: API_ENDPOINTS.BASE_URL,
  headers: API_HEADERS,
});

// 请求拦截器，自动添加 Authorization 头
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// 响应拦截器，处理 401 错误
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const data = await refreshToken();
      const newToken = data.access_token;

      if (newToken) {
        localStorage.setItem('token', newToken);
        originalRequest.headers.Authorization = `Bearer ${newToken}`;
        return api(originalRequest); // 重新发起原始请求
      } else {
        localStorage.clear();
        window.location.href = '/main'; // 重定向到登录页面
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);

// 通用的GET请求
const get = async (url, params = {}) => {
  return await api.get(url, { params });
};

// 通用的POST请求
const post = async (url, data = {}) => {
  return await api.post(url, data);
};

// 通用的PUT请求
const put = async (url, data = {}) => {
  return await api.put(url, data);
};

// 通用的DELETE请求
const del = async (url, data = {}) => {
  return await api.delete(url, { data });
};

// 获取组织列表
export const getOrganization = async () => {
  const response = await get(API_ORGANIZATION_ENDPOINTS.getUserOrganizations);
  return response.data;
};

// 创建组织
export const createOrganization = async (organizationData) => {
  const response = await post(API_ORGANIZATION_ENDPOINTS.createOrganization, organizationData);
  return response.data;
};

// 获取组织成员
export const getMembers = async (organizationUid) => {
  const response = await get(`${API_ORGANIZATION_ENDPOINTS.getMembers}/${organizationUid}`);
  return response.data;
};

// 删除组织成员
export const deleteMember = async (organizationUid, memberUid) => {
  const response = await del(API_ORGANIZATION_ENDPOINTS.deleteMember, {
    organization_uid: organizationUid,
    uuid: memberUid,
  });
  return response.data;
};

// 搜索用户
export const searchUser = async (query) => {
  const response = await get(API_ORGANIZATION_ENDPOINTS.searchUser, { query });
  return response.data;
};

// 邀请成员
export const inviteMember = async (inviteData) => {
  const response = await post(API_ORGANIZATION_ENDPOINTS.inviteMember, inviteData);
  return response.data;
};

// 创建组织用户
export const createOrganizationUser = async (organizationUuid, userData) => {
  const response = await post(`${API_ORGANIZATION_ENDPOINTS.organizationUser}/${organizationUuid}`, userData);
  return response.data;
};

// 更新组织用户
export const updateOrganizationUser = async (userUuid, updateData) => {
  const response = await put(`${API_ORGANIZATION_ENDPOINTS.organizationUser}/${userUuid}`, updateData);
  return response.data;
};

// 删除组织用户
export const deleteOrganizationUser = async (userUuid) => {
  const response = await del(`${API_ORGANIZATION_ENDPOINTS.organizationUser}/${userUuid}`);
  return response.data;
};

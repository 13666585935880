import React, { useState, useEffect, useContext } from 'react';
import { uploadAvatar, sendBindPhoneCode, confirmBindPhoneCode } from '@services/apiService';
import { AuthContext } from '@contexts/AuthContext';
import './Info.css';

const Info = () => {
    const [userInfo, setUserInfo] = useState(null);
    const { isAuthenticated } = useContext(AuthContext);
    const [isHovering, setIsHovering] = useState(false);
    const [isBindingPhone, setIsBindingPhone] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [isSendingCode, setIsSendingCode] = useState(false);
    const [isConfirming, setIsConfirming] = useState(false);

    useEffect(() => {
        if (isAuthenticated) {
            const storedUserInfo = localStorage.getItem('userInfo');
            if (storedUserInfo) {
                setUserInfo(JSON.parse(storedUserInfo));
            }
        }
    }, [isAuthenticated]);

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const response = await uploadAvatar(file);
                setUserInfo((prev) => ({ ...prev, avatar_url: response.avatar_url }));
                localStorage.setItem(
                    'userInfo',
                    JSON.stringify({
                        ...userInfo,
                        avatar_url: response.avatar_url,
                    })
                );
            } catch (error) {
                console.error('头像上传失败:', error);
            }
        }
    };

    const handleSendVerificationCode = async () => {
        if (!phoneNumber) {
            alert('请输入手机号！');
            return;
        }
        setIsSendingCode(true);
        try {
            await sendBindPhoneCode(phoneNumber);
            alert('验证码已发送至手机，请查收！');
        } catch (error) {
            console.error('发送验证码失败:', error);
            if (error.response && error.response.data && error.response.data.detail) {
                const { error_code, message } = error.response.data.detail;
                switch (error_code) {
                    case 2005:
                        alert('用户不存在，请检查登录状态！');
                        break;
                    case 2006:
                        alert('该手机号已被绑定，请更换手机号！');
                        break;
                    default:
                        alert(`发送验证码失败：${message}`);
                }
            } else {
                alert('发送验证码失败，请稍后再试。');
            }
        } finally {
            setIsSendingCode(false);
        }
    };
    
    const handleBindPhone = async () => {
        if (!phoneNumber || !verificationCode) {
            alert('请输入手机号和验证码！');
            return;
        }
        setIsConfirming(true);
        try {
            const response = await confirmBindPhoneCode(phoneNumber, verificationCode);
            if (response.code == 200) {
                // 更新 userInfo 和 localStorage
                const updatedUserInfo = { ...userInfo, phone_number: phoneNumber };
                setUserInfo(updatedUserInfo); // 更新状态
                localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo)); // 更新 localStorage
                
                setIsBindingPhone(false);
                setPhoneNumber('');
                setVerificationCode('');
                alert('手机号绑定成功！');
            } else {
                alert('绑定失败，请检查验证码是否正确！');
            }
        } catch (error) {
            console.error('绑定手机号失败:', error);
            if (error.response && error.response.data && error.response.data.detail) {
                const { message } = error.response.data.detail;
                alert(`绑定失败：${message}`);
            } else {
                alert('绑定手机号失败，请稍后再试。');
            }
        } finally {
            setIsConfirming(false);
        }
    };

    if (!userInfo) {
        return <div className="info-loading">加载中...</div>;
    }

    return (
        <div className="info-container">
            {/* 左侧头像及个人信息 */}
            <div className="info-personal-info">
                <div
                    className="info-avatar-section"
                    onMouseEnter={() => setIsHovering(true)}
                    onMouseLeave={() => setIsHovering(false)}
                >
                    <img
                        src={userInfo.avatar_url || '/default-avatar.png'}
                        alt="Avatar"
                        className="info-avatar-image"
                    />
                    {isHovering && (
                        <div className="info-camera-overlay">
                            <label htmlFor="avatar-upload" className="info-camera-icon">
                                <input
                                    type="file"
                                    id="avatar-upload"
                                    accept="image/*"
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                />
                                📷
                            </label>
                        </div>
                    )}
                </div>
                <div className="info-user-details">
                    <h2 className="info-username">{userInfo.username}</h2>
                    <p className="info-email">
                        {userInfo.email || (
                            <span className="info-bind-text">绑定</span>
                        )}
                    </p>
                    <p className="info-phone">
                        <span className="info-label">手机号：</span>
                        {userInfo.phone_number ? (
                            <>
                                📞 {userInfo.phone_number}
                            </>
                        ) : isBindingPhone ? (
                            <span className="info-bind-phone-inline">
                                <input
                                    type="text"
                                    placeholder="请输入手机号"
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    className="info-phone-input-inline"
                                />
                                <input
                                    type="text"
                                    placeholder="验证码"
                                    value={verificationCode}
                                    onChange={(e) => setVerificationCode(e.target.value)}
                                    className="info-code-input-inline"
                                />
                                <button
                                    onClick={handleSendVerificationCode}
                                    className="info-send-code-button-inline"
                                    disabled={isSendingCode}
                                >
                                    {isSendingCode ? '发送中...' : '发送验证码'}
                                </button>
                                <button
                                    onClick={handleBindPhone}
                                    className="info-confirm-bind-button-inline"
                                    disabled={isConfirming}
                                >
                                    {isConfirming ? '绑定中...' : '确认绑定'}
                                </button>
                                <button
                                    onClick={() => {
                                        setIsBindingPhone(false);
                                        setPhoneNumber('');
                                        setVerificationCode('');
                                    }}
                                    className="info-cancel-bind-button-inline"
                                >
                                    取消
                                </button>
                            </span>
                        ) : (
                            <span
                                className="info-bind-text"
                                onClick={() => setIsBindingPhone(true)}
                            >
                                绑定
                            </span>
                        )}
                    </p>
                </div>
            </div>

            {/* 右侧会员信息 */}
            <div className="info-membership-section">
                <h3 className="info-membership-title">会员信息</h3>
                {userInfo.memberships && userInfo.memberships.length > 0 ? (
                    userInfo.memberships.map((membership, index) => (
                        <div key={index} className="info-membership-info">
                            <p>
                                <strong>会员类型：</strong>
                                {membership.member_type}
                            </p>
                            <p>
                                <strong>有效期至：</strong>
                                {new Date(membership.expiry_date).toLocaleDateString()}
                            </p>
                            <p>
                                <strong>剩余额度：</strong>
                                {membership.remaining_quota}
                            </p>
                        </div>
                    ))
                ) : (
                    <p className="info-no-membership">暂无会员信息</p>
                )}
            </div>
        </div>
    );
};

export default Info;

// Home.js
import {React, useContext } from 'react';
import HomeDisplay from '@components/home/HomeDisplay';
import '@styles/home/home.css';
import WorksSquare from '@components/home/WorksSquare';
import QuickToolsIndex from '@components/home/QuickToolsIndex';
import Footer from '@components/home/Footer';
import { AuthContext } from '@contexts/AuthContext';

const Home = () => {
    const { token, isAuthenticated, currentFunc, setCurrentFunc } = useContext(AuthContext);
    setCurrentFunc('bridge-r');
    return (
        <div className="home-container">
            <div className="home-section home-section-display">
                <HomeDisplay />
            </div>
            <div className="home-section home-section-Tools">
                <h2 className="home-section-title">快速工具</h2>
                <QuickToolsIndex />
            </div>
            <div className="home-section home-section-works">
                <h2 className="home-section-title">出图展示</h2>
                <WorksSquare />
            </div>
            <Footer />
        </div>
    );
};

export default Home;

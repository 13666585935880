import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import assistantImage from '@images/assistant.png';
import VideoModal from '@components/Assistant/VideoModal'; // 导入弹窗组件
import '@styles/Assistant.css';

const Assistant = forwardRef((props, ref) => {
    const assistantRef = useRef(null);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [dragging, setDragging] = useState(false);
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
    const [offset, setOffset] = useState({ x: 0, y: 0 });
    const [bubbleContent, setBubbleContent] = useState('');
    const [bubbleVisible, setBubbleVisible] = useState(false);
    const [modalOpen, setModalOpen] = useState(false); // 控制弹窗的状态

    useEffect(() => {
        const assistantElement = assistantRef.current;
        if (assistantElement) {
            const screenWidth = window.innerWidth;
            const screenHeight = window.innerHeight;
            const rect = assistantElement.getBoundingClientRect();

            setPosition({
                x: screenWidth - rect.width - 120,
                y: screenHeight - rect.height - 100,
            });
        }
    }, []);

    const handleMouseDown = (e) => {
        const rect = assistantRef.current.getBoundingClientRect();
        setDragging(true);
        setDragStart({ x: e.clientX, y: e.clientY });
        setOffset({ x: e.clientX - rect.left, y: e.clientY - rect.top });
        e.preventDefault();
    };

    const handleMouseMove = (e) => {
        if (dragging) {
            setPosition({
                x: e.clientX - offset.x,
                y: e.clientY - offset.y,
            });
            setBubbleVisible(false);
        }
    };

    const handleMouseUp = () => {
        setDragging(false);
    };

    useEffect(() => {
        if (dragging) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        } else {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [dragging]);

    const showBubble = (content) => {
        setBubbleContent(content);
        setBubbleVisible(true);
        setTimeout(() => {
            setBubbleVisible(false);
        }, 3000);
    };

    const handleAssistantClick = () => {
        if (bubbleVisible) {
            setBubbleVisible(false);
        } else {
            showVideoBubble();
        }
    };

    const showVideoBubble = () => {
        setBubbleContent("点击这里查看演示视频");
        setBubbleVisible(true);
    
        
        setTimeout(() => {
            setBubbleVisible(false);
        }, 3000);
    };


    const handleBubbleClick = () => {
        setModalOpen(true);
        setBubbleVisible(false);
    };

    useImperativeHandle(ref, () => ({
        showBubble,
        showVideoBubble,
    }));

    return (
        <div>
            <img 
                src={assistantImage} 
                alt="Assistant" 
                className="assistant-image" 
                ref={assistantRef}
                style={{ left: `${position.x}px`, top: `${position.y}px`, position: 'fixed' }}
                onMouseDown={handleMouseDown} 
                onMouseUp={handleMouseUp}
                onDragStart={(e) => e.preventDefault()}
                onClick={handleAssistantClick} // 点击助手图标
            />
            {bubbleVisible && (
                <div 
                    className="assistant-bubble" 
                    style={{ left: `${position.x}px`, top: `${position.y - 40}px` }} // 将这里的 10 改为 40
                    onClick={handleBubbleClick} // 点击气泡打开弹窗
                >
                    {bubbleContent}
                </div>
            )}

            <VideoModal isOpen={modalOpen} onClose={() => setModalOpen(false)} /> {/* 弹窗组件 */}
        </div>
    );
});

export default Assistant;

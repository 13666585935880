// RealPaint.js
import React, { useState, useEffect, useRef, useContext } from 'react';
import { Button, Layout, Input, Spin, Card, Tooltip, Checkbox } from 'antd';
import ImageGallery from '@components/ImageGallery';
import CategorySelector from '@components/bridge/CategorySelector';
import BridgeSelector from '@components/bridge/BridgeSelector';
import Login from '@components/login/Login';
import { CloseCircleOutlined } from '@ant-design/icons';
import '@styles/bridge/Realpaint.css';
import { loras, bridgeLoras } from '@configs/loras';
import categories from '@configs/categories';
import { sendGenerationRequest, fetchExampleImageData } from '@services/apiService';
import '@styles/SplitPane.css';
import { MaskEditor, toMask } from '@components/mask-editor/MaskEditor';
import { AssistantContext } from '@contexts/AssistantContext';
import { handleError } from '@utils/apiUtils'; 
import { API_ENDPOINTS } from '@configs/api';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider'; 
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '@contexts/AuthContext';
import ImageUploader from '@components/image/ImageUploader';
import CustomTextArea from '@components/input/CustomTextArea';
import { useBreadcrumb } from '@contexts/BreadcrumbContext';
import Parameters from '@components/bridge/Parameters';

const { Content } = Layout;
const { TextArea } = Input;

const Realpaint = (props) => {
  const { showBubble, showVideoBubble } = useContext(AssistantContext);
  const [inputOne, setInputOne] = useState('');
  const [buttonPrompt, setButtonPrompt] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [rawImage, setRawImage] = useState(null);
  const [currentType, setCurrentType] = useState("g");
  const [showComparison, setShowComparison] = useState(false);
  const canvasRef = useRef(null);
  const [toggles, setToggles] = useState(loras.map(() => false));
  const togglesInfo = useRef(loras.reduce((acc, lora, index) => ({ ...acc, [index]: lora.value }), {})).current;
  const [modelVersion, setModelVersion] = useState(bridgeLoras[0].key);
  const models = useRef(bridgeLoras.reduce((acc, lora) => ({ ...acc, [lora.key]: lora.value }), {})).current;
  const [loraInput, setLoraInput] = useState(models[bridgeLoras[0].key]);
  const [selectedBridgeType, setSelectedBridgeType] = useState(Object.keys(categories.specific)[0]);
  const [resolution, setResolution] = useState('1280x720');
  const [customWidth, setCustomWidth] = useState(1280);
  const [customHeight, setCustomHeight] = useState(720);
  const [imageCount, setImageCount] = useState(1);
  const [seed, setSeed] = useState(-1);
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [page] = useState(1);
  const [size] = useState(10);
  const [isSessionStorageSet, setIsSessionStorageSet] = useState(false);
  const [hdOption, setHdOption] = useState(false);
  const { breadcrumbPath } = useBreadcrumb();

  const imageGalleryRef = useRef(null);
  const categorySelectorRef = useRef(null);
  const { token, isAuthenticated, currentFunc, setCurrentFunc } = useContext(AuthContext);

  const navigate = useNavigate();
  useEffect(() => {
    showVideoBubble();
    setCurrentFunc('bridge-r');
    setLoraInput(buildLoraInput(modelVersion, toggles));
    setIsSessionStorageSet(true);
  }, []);
  
  const handleSelectionChange = (selectedText) => setButtonPrompt(selectedText);

  const handleToggle = (index) => {
    const updatedToggles = toggles.map((item, idx) => (idx === index ? !item : item));
    setToggles(updatedToggles);
    setLoraInput(buildLoraInput(modelVersion, updatedToggles));
  };

  const handleBridgeTypeChange = (value) => {
    const selectedModel = bridgeLoras.find((lora) => lora.name === value);
    if (!selectedModel) {
      console.error(`No model found for bridge type: ${value}`);
      return;
    }
    setSelectedBridgeType(value);
    setModelVersion(selectedModel.key);
    setLoraInput(buildLoraInput(selectedModel.key, toggles));
    if (categorySelectorRef.current) categorySelectorRef.current.clearSelections();
  };

  const handleResolutionChange = (value) => {
    if (value === 'custom') {
      setResolution('custom');
    } else if (value.includes('x')) {
      const [width, height] = value.split('x').map(Number);
      setResolution(value);
      setCustomWidth(width);
      setCustomHeight(height);
    }
  };

  const handleCustomResolutionInput = (key, value) => {
    const filteredValue = value.replace(/[^0-9]/g, '');
    if (key === 'customWidth') setCustomWidth(filteredValue);
    if (key === 'customHeight') setCustomHeight(filteredValue);
  };

  const handleImageCountChange = (count) => setImageCount(count);

  const buildLoraInput = (modelVersion, toggles) => {
    let loraInput = models[modelVersion];
    toggles.forEach((active, index) => {
      if (active) loraInput += togglesInfo[index];
    });
    return loraInput;
  };


  const handleImageUpload = (event) => {
    event.preventDefault();
    const file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    if (!file) {
      showBubble('没选到图片哦!');
      return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
        const resizedImageBase64 = canvas.toDataURL('image/jpeg');
        setSelectedImage(resizedImageBase64);
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    handleImageUpload(event);
  };

  const handleDragOver = (event) => event.preventDefault();

  const onClickSend = async () => {
    if (!isAuthenticated) {
      setIsLoginModalVisible(true);
      return;
    }
    if (!selectedImage) {
      showBubble('请先选一张图片哦！');
      return;
    }
    const canvas = canvasRef.current;
    const mask = toMask(canvas);
    const payload = JSON.stringify({
      button_prompt: buttonPrompt,
      lora: loraInput,
      prompt: inputOne,
      n_iter: imageCount,
      seed,
      currentFunc,
      wf_code: 10004,
      requestClass: 'c2',
      width: customWidth,
      height: customHeight,
      force_task_id: "x-" + Math.random().toString(36).slice(-8),
      init_images: [selectedImage],
      mask,
      controlnet: [],
    });
    try {
      const response = await sendGenerationRequest(payload);
      handleResponse(response);
    } catch (error) {
      handleError(error, showBubble);
    }
  };

  const handleResponse = (response) => {
    if (response.code === 1000) {
      showBubble('提交任务成功！正在生成，请耐心等待哦!');
    }
    if (imageGalleryRef.current) {
      imageGalleryRef.current.loadHistory(page, size, currentFunc);
    }
  };

  const handleLoginModalClose = () => setIsLoginModalVisible(false);

  const handleLoginSuccess = () => {
    imageGalleryRef.current?.loadHistory(1, 10, currentFunc);
    setIsLoginModalVisible(false);
    navigate(0);
  };

  const selectImage = (imageDetails) => {
    setSelectedImage(imageDetails.fullUrl);
  };
  const handleInputOneChange = (e) => {
    setInputOne(e.target.value);
  };
  
  const handleHdOptionChange = (value) => {
    setHdOption(value);
  };

  return (
    <Layout style={{ height: '100%', width: '100%', display: 'flex', backgroundColor: '#101214' }}>
      <div className="flex-container">
        <Content className="l1-left-container">
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
            <span className="label-style" style={{ marginRight: '8px' }}>桥型</span>
            <BridgeSelector selectedBridgeType={selectedBridgeType} handleBridgeTypeChange={handleBridgeTypeChange} />
          </div>
          {toggles.map((active, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
              <span className="label-style" style={{ flex: 1, marginRight: '8px' }}>{loras[index].name}</span>
              <Checkbox 
                checked={active}
                onChange={() => handleToggle(index)}
                style={{ marginLeft: 'auto' }}
              />
            </div>
          ))}

              <Parameters 
                imageCount={imageCount}
                resolution={resolution}
                customWidth={customWidth}
                customHeight={customHeight}
                hdOption={hdOption}
                setHdOption={handleHdOptionChange}
                handleResolutionChange={handleResolutionChange}
                handleCustomResolutionInput={handleCustomResolutionInput}
                handleImageCountChange={handleImageCountChange}
              />
            <div className="breadcrumb__down__section">
            <CategorySelector
              ref={categorySelectorRef}
              selectedBridgeType={selectedBridgeType}
              onSelectionChange={handleSelectionChange}
            />
            </div>
          </Content>
          <Content className="l1-middle-container" onDrop={handleDrop} onDragOver={handleDragOver}>
          <div className="breadcrumb__path">
            {breadcrumbPath.join(' / ')}
          </div>
          {selectedImage ? (
            <div className="canvas-container">
              {selectedImage && currentType === 'g' && (
                <Button
                  style={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    zIndex: 10,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    color: 'black',
                  }}
                  onClick={() => setShowComparison(!showComparison)} // 切换比较模式
                >
                  {showComparison ? '关闭对比' : '对比'}
                </Button>
              )}
              
              {showComparison && rawImage ? (
                  <ReactCompareSlider
                    itemOne={<ReactCompareSliderImage src={selectedImage} alt="Selected Image" />}
                    itemTwo={<ReactCompareSliderImage src={rawImage} alt="Raw Image" />}
                  />
                ) : (
                  <MaskEditor src={selectedImage} canvasRef={canvasRef} isDrawingAllowed={true} />
                )}
              <CloseCircleOutlined
                style={{
                  position: 'absolute',
                  bottom: 10,
                  right: 10,
                  fontSize: '24px',
                  color: 'red',
                  cursor: 'pointer',
                }}
                onClick={() => setSelectedImage(null)}
              />
            </div>
          ) : (
            <ImageUploader onImageSelected={setSelectedImage}/>
          )}
          <CustomTextArea
            imageCount={imageCount}
            resolution={resolution}
            customWidth={customWidth}
            customHeight={customHeight}
            hdOption={hdOption}
            setHdOption={handleHdOptionChange}
            handleResolutionChange={handleResolutionChange}
            handleCustomResolutionInput={handleCustomResolutionInput}
            handleImageCountChange={handleImageCountChange}
            inputValue={inputOne}
            onInputChange={handleInputOneChange}
            onGenerate={onClickSend}
          />
        </Content>
          <Content className='l1-right-container'>
          <div className="breadcrumb__down__section">
          <span className="breadcrumb__path">历史记录</span>
            <div style={{ flex: 1, }}>
              {isSessionStorageSet && (
                <ImageGallery
                  ref={imageGalleryRef}
                  size={size}
                  page={page}
                  onSelectImage={selectImage}
                  setRawImage={setRawImage}
                  setShowComparison={setShowComparison}
                  setCurrentType={setCurrentType}
                />
              )}

            </div>
            </div>
          </Content>
        {isLoginModalVisible && <Login onClose={handleLoginModalClose} onLogin={handleLoginSuccess} />}
        </div>
      </Layout>
  );
};

export default Realpaint;

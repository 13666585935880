import React, { useState } from 'react';
import './ImageUnit.css';
import Watermark from '@utils/canvas/Watermark';

const ImageUnit = ({ data }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleImageClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div
      className="image-unit"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="image-unit__image-container" onClick={handleImageClick}>
        <img
          src={data.thumbnail_url}
          alt={data.username}
          className="image-unit__image"
        />
        <Watermark />
      </div>

      <div className="image-unit__overlay"> 
          <div className="image-unit__info">
            <span className="image-unit__prompt-seed">Seed: {data.seed}</span>
          </div>
        {/* <div className="image-unit__info">
          <div className="image-unit__user-container">
            <img
              src={data.avatar_url}
              alt="avatar"
              className="image-unit__avatar"
            />
            <span className="image-unit__username">{data.username}</span>
          </div>
          <span className="image-unit__favorites">❤️ {data.favorites_counts}</span>
        </div> */}
      </div>

      {isModalOpen && (
        <div className="image-unit__modal" onClick={handleModalClose}>
          <img
            src={data.url}
            alt={data.username}
            className="image-unit__modal-image"
          />
        </div>
      )}
    </div>
  );
};

export default ImageUnit;

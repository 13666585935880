import React, { useState, useRef, useContext, useEffect } from 'react';
import { Button, Layout, message } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import Split from 'react-split';
import ImageGallery from '@components/ImageGallery';
import BridgeSelector from '@components/bridge/BridgeSelector';
import CustomInput from '@components/Inpaint/CustomInput';
import Login from '@components/login/Login';
import { MaskEditor, toMask } from '@components/mask-editor/MaskEditor';
import { loras, bridgeLoras } from '@configs/loras';
import { AssistantContext } from '@contexts/AssistantContext';
import { sendGenerationRequest } from '@services/apiService';
import { API_ENDPOINTS } from '@configs/api';
import { handleError } from '@utils/apiUtils';
import { AuthContext } from '@contexts/AuthContext';
import ImageUploader from '@components/image/ImageUploader';
import '@styles/bridge/Transtyle.css';
import '@styles/SplitPane.css';
import Parameters from '@components/bridge/Parameters';
import categories from '@configs/categories';
import { useBreadcrumb } from '@contexts/BreadcrumbContext';

const { Content } = Layout;

const Transtyle = () => {
  const context = useContext(AssistantContext);
  const { token, isAuthenticated, currentFunc, setCurrentFunc } = useContext(AuthContext);
  const imageGalleryRef = useRef();
  const canvasRef = useRef();

  const [inputOne, setInputOne] = useState('');
  const [reversionStrength, setReversionStrength] = useState(1);
  const [resolution, setResolution] = useState('1280x720');
  const [customWidth, setCustomWidth] = useState(1280);
  const [customHeight, setCustomHeight] = useState(720);
  const [imageCount, setImageCount] = useState(1);
  const [hdOption, setHdOption] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [toggles, setToggles] = useState(loras.map(() => false));
  const [modelVersion, setModelVersion] = useState(bridgeLoras[0].key);
  const [loraInput, setLoraInput] = useState(bridgeLoras[0].value);
  const [selectedBridgeType, setSelectedBridgeType] = useState(Object.keys(categories.specific)[0]);
  const [seed, setSeed] = useState(-1);
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(1);
  const [isSessionStorageSet, setIsSessionStorageSet] = useState(false);
  const { breadcrumbPath } = useBreadcrumb();

  useEffect(() => {
    context.showBubble();
    context.showVideoBubble()
    setCurrentFunc('trans-q');
    const initialInput = buildLoraInput(modelVersion, toggles);
    setLoraInput(initialInput);
    setIsSessionStorageSet(true);
  }, []);

  const buildLoraInput = (modelVersion, toggles) => {
    let loraInput = bridgeLoras.find((lora) => lora.key === modelVersion).value;
    toggles.forEach((active, index) => {
      if (active) loraInput += loras[index].value;
    });
    return loraInput;
  };

  const handleToggle = (index) => {
    const updatedToggles = toggles.map((item, idx) => (idx === index ? !item : item));
    const updatedInput = buildLoraInput(modelVersion, updatedToggles);
    setToggles(updatedToggles);
    setLoraInput(updatedInput);
  };

  const handleReversionStrengthChange = (value) => {
    setReversionStrength(value);
  };

  const handleHdOptionChange = (value) => {
    setHdOption(value);
  };

  const handleBridgeTypeChange = (value) => {
    const selectedModel = bridgeLoras.find((lora) => lora.name === value);
    if (!selectedModel) {
      console.error(`No model found for bridge type: ${value}`);
      return;
    }
    const updatedInput = buildLoraInput(selectedModel.key, toggles);
    setSelectedBridgeType(value);
    setModelVersion(selectedModel.key);
    setLoraInput(updatedInput);
  };

  const selectImage = (imageDetails) => {
    setSelectedImage(imageDetails.fullUrl);
  };


  const onClickSend = async () => {
    if (!isAuthenticated) {
      setIsLoginModalVisible(true);
      return;
    }
    const canvas = canvasRef.current;

    const payload = JSON.stringify({
      lora: loraInput,
      prompt: inputOne,
      n_iter: imageCount,
      seed,
      denoising_strength: reversionStrength,
      currentFunc,
      requestClass: 'c4',
      width: 1280,
      height: 720,
      force_task_id: 'x-' + Math.random().toString(36).slice(-8),
      init_images: [selectedImage],
      mask: toMask(canvas),
      controlnet: [],
    });

    setLoadingImages(true);
    setGenerating(true);
    imageGalleryRef.current?.loadHistory(1, 10, currentFunc);

    try {
      const response = await sendGenerationRequest(API_ENDPOINTS.img2img, payload);
      handleResponse(response);
    } catch (error) {
      handleError(error, context.showBubble);
    }
  };

  const handleResponse = (response) => {
    if (response.code === 1000) {
      message.success('任务提交成功');
    }
    imageGalleryRef.current?.loadHistory(page, size, currentFunc);
  };
  const handleResolutionChange = (value) => {
    if (value === 'custom') {
      setResolution('custom');
    } else {
      const [width, height] = value.split('x').map(Number);
      setCustomWidth(width);
      setCustomHeight(height);
      setResolution(value);
    }
  };

  const handleCustomResolutionInput = (key, value) => {
    const filteredValue = value.replace(/[^0-9]/g, '');
    if (key === 'customWidth') {
      setCustomWidth(filteredValue);
    } else if (key === 'customHeight') {
      setCustomHeight(filteredValue);
    }
  };

  const handleImageCountChange = (count) => {
    setImageCount(count);
  };
  const handleLoginModalClose = () => setIsLoginModalVisible(false);

  const handleLoginSuccess = () => {
    imageGalleryRef.current?.loadHistory(page, size, currentFunc);
    setIsLoginModalVisible(false);
  };

  return (
    <Layout style={{ height: '100%', width: '100%', display: 'flex', backgroundColor: '#101214' }}>
      <div className="flex-container">
        <Content className="l1-left-container">
          <div className="breadcrumb__down__section">
          <span className="breadcrumb__path">历史记录</span>
              {isSessionStorageSet && (
                <ImageGallery
                  ref={imageGalleryRef}
                  size={size}
                  page={page}
                  onSelectImage={selectImage}
                  selectedImage={selectedImage}
                />
              )}
              </div>
          </Content>
          <Content className="l1-middle-container">
          <div className="breadcrumb__path">
            {breadcrumbPath.join(' / ')}
          </div>
            {selectedImage ? (
              <div className="canvas-container">
                <MaskEditor src={selectedImage} canvasRef={canvasRef} />
                <CloseCircleOutlined
                  className="close-icon"
                  onClick={() => setSelectedImage(null)}
                />
              </div>
            ) : (
              <ImageUploader onImageSelected={setSelectedImage} />
            )}
          </Content>
          <Content className="l1-right-container">
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
            <span className="label-style" style={{ marginRight: '8px' }}>桥型</span>
            <BridgeSelector selectedBridgeType={selectedBridgeType} handleBridgeTypeChange={handleBridgeTypeChange} />
          </div>
            <div className="style-selector-container">
              <div className="style-title">风格</div>
              <div className="style-buttons">
                {loras.map((lora, index) => (
                  <Button
                    key={index}
                    type={toggles[index] ? 'primary' : 'default'}
                    onClick={() => handleToggle(index)}
                    className="style-button"
                  >
                    {lora.name}
                  </Button>
                ))}
              </div>
            </div>
            <div className="reversion-strength-container">
              <div className="style-title">重绘幅度</div>
              <div className="reversion-strength-buttons">
                {['原图结构', '小有发挥', '大展宏图', '完全发散'].map((label, index) => (
                  <Button
                    key={index}
                    type={reversionStrength === index + 1 ? 'primary' : 'default'}
                    onClick={() => handleReversionStrengthChange(index + 1)}
                    className="reversion-strength-button"
                  >
                    {label}
                  </Button>
                ))}
              </div>
            </div>
             <Parameters 
                imageCount={imageCount}
                resolution={resolution}
                customWidth={customWidth}
                customHeight={customHeight}
                hdOption={hdOption}
                setHdOption={handleHdOptionChange}
                handleResolutionChange={handleResolutionChange}
                handleCustomResolutionInput={handleCustomResolutionInput}
                handleImageCountChange={handleImageCountChange}
              />
            <CustomInput
              placeholder="我想把背景变成......"
              value={inputOne}
              onChange={(value) => setInputOne(value)}
              className="custom-textarea__input"
            />
            <div className="control-buttons">
              <Button type="primary" onClick={onClickSend} className="generate-button">
                生成图片
              </Button>
            </div>
          </Content>
      {isLoginModalVisible && (
        <Login onClose={handleLoginModalClose} onLogin={handleLoginSuccess} />
      )}
    </div>
      </Layout>
  );
};

export default Transtyle;

// @services/apiServices.js
import axios from 'axios';
import { API_ENDPOINTS, API_HEADERS } from '@configs/api';
import { refreshToken } from '@services/authService';

const api = axios.create({
  baseURL: API_ENDPOINTS.BASE_URL,
  headers: API_HEADERS,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const data = await refreshToken();
        const newToken = data?.access_token;

        if (newToken) {
          localStorage.setItem('token', newToken);
          originalRequest.headers.Authorization = `Bearer ${newToken}`;
          return api(originalRequest); // 重试原始请求
        } else {
          throw new Error('Failed to refresh token');
        }
      } catch (refreshError) {
        localStorage.clear(); // 清除无效的 Token
        window.location.href = '/main'; // 跳转到登录页面
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);



const get = async (url, params = {}) => {
  return await api.get(url, { params });
};


const post = async (url, data = {}) => {
  return await api.post(url, data);
};


export const fetchProgress = async (taskId) => {
  const params = { id_task: taskId, id_live_preview: -1 };
  return await post(API_ENDPOINTS.progress, params);
};


export const getLoginImage = async () => {
  const response = await fetch(API_ENDPOINTS.loginImages);
  if (!response.ok) {
    throw new Error('Failed to fetch login images');
  }
  return await response.json();
};

// 获取示例图片数据
export const fetchExampleImageData = async (imageType) => {
  try {
    return await get(API_ENDPOINTS.exampleImages, { image_type: imageType });
  } catch (error) {
    console.error('请求错误:', error);
    throw error;
  }
};

// 获取历史数据
export const fetchHistory = async (page = 1, size = 10, currentFunc = 'all') => {
  const params = { request_func: currentFunc, page, size };
  return await get(API_ENDPOINTS.history, params);
};

// 发送生成请求
export const sendGenerationRequest = async (payload) => {
  return (await post(API_ENDPOINTS.generationRequest, payload)).data;
};

// 获取IP地址
export const getIpAddress = async () => {
  try {
    const response = await axios.get('http://ip-api.com/json');
    if (response.data && response.data.status === 'success') {
      return response.data.query;
    } else {
      console.error('Failed to fetch IP address');
      return null;
    }
  } catch (error) {
    console.error('Error fetching IP address:', error);
    return null;
  }
};


export const login = async (username, password, ip) => {
  const formBody = new URLSearchParams();
  formBody.append('username', username);
  formBody.append('password', password);
  if (ip) {
    formBody.append('client_ip', ip);
  }

  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), 5000);

  try {
    const response = await fetch(API_ENDPOINTS.login, {
      method: 'POST',
      headers: {
        ...API_HEADERS,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: formBody.toString(),
      signal: controller.signal,
      credentials: 'include'
    });

    clearTimeout(timeoutId);

    console.log(response)
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error('Login failed');
    }
  } catch (error) {
    if (error.name === 'AbortError') {
      throw new Error('Request timeout');
    } else {
      throw new Error('Login failed');
    }
  }
};

export const getCurrentUser = async () => {
  try {
    const response = await get(API_ENDPOINTS.currentUser);
    return response; // 返回用户数据
  } catch (error) {
    console.error('Failed to fetch current user:', error);
    throw error; // 抛出错误，供调用方处理
  }
};


// 自动分段
export const autoSegment = async (payload) => {
  return await post(API_ENDPOINTS.autosegment, payload);
};

// 切换收藏
export const toggleFavorite = async (request_id) => {
  return await post(API_ENDPOINTS.favorite, { request_id });
};

// 发送喜欢状态
export const sendifLike = async (image_id) => {
  return await post(API_ENDPOINTS.ifFit, { image_id });
};

// 发送图片反馈
export const sendImageFeedback = async (image_id, feedback) => {
  return await post(API_ENDPOINTS.imageFeedback, { image_id, feedback });
};

export const uploadImage = async (imageData, func) => {
  const response = await post(API_ENDPOINTS.uploadImage, { image: imageData, func: func });
  return response.data;
};

export const getHomeDisplay = async () => {
  const response = await post(API_ENDPOINTS.homeDisplay);
  return response.data;
};

export const getGroundImageData = async (page = 1, page_size = 30, sort_by = 'favorites_counts') => {
  try {
    const response = await axios.get(API_ENDPOINTS.groundImageData, {
      params: {
        page,
        page_size,
        sort_by,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching groundImageData:", error);
    throw error;
  }
};

export const uploadAvatar = async (file) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const response = await axios.post(API_ENDPOINTS.uploadAvatar, formData, {
      headers: {
        ...API_HEADERS,
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error uploading avatar:', error);
    throw error;
  }
};

// 发送绑定手机号的验证码
export const sendBindPhoneCode = async (phoneNumber) => {
  try {
    const response = await post(API_ENDPOINTS.sendBindPhoneCode, { phone_number: phoneNumber });
    return response.data;
  } catch (error) {
    console.error('Error sending bind phone code:', error);
    throw error;
  }
};

export const confirmBindPhoneCode = async (phoneNumber, code) => {
  try {
    const response = await post(API_ENDPOINTS.confirmBindPhoneCode, {
      phone_number: phoneNumber,
      code: code,
    });
    return response.data;
  } catch (error) {
    console.error('Error confirming bind phone code:', error);
    throw error;
  }
};


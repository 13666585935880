// src/pages/inpaint.js
import React, { useState, useEffect, useRef, useContext } from 'react';
import { Button, Layout, Checkbox } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import ImageGallery from '@components/ImageGallery';
import { DoodleSelector } from '@components/Inpaint/DoodleSelector';
import Login from '@components/login/Login';
import { MaskEditor, toMask } from '@components/mask-editor/MaskEditor';
import { loras, bridgeLoras } from '@configs/loras';
import categories from '@configs/categories';
import { AssistantContext } from '@contexts/AssistantContext';
import { sendGenerationRequest } from '@services/apiService';
import { handleError } from '@utils/apiUtils';
import CustomTextArea from '@components/input/CustomTextArea';
import '@styles/bridge/Inpaint.css';
import '@styles/SplitPane.css';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '@contexts/AuthContext';
import ImageUploader from '@components/image/ImageUploader';
import Parameters from '@components/bridge/Parameters';
import { useBreadcrumb } from '@contexts/BreadcrumbContext';

const { Content } = Layout;

const FluxInpaint = ({ inputCurrentFunc }) => {
  const { showBubble, showVideoBubble } = useContext(AssistantContext);
  const [inputOne, setInputOne] = useState('');
  const [buttonPrompt, setButtonPrompt] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const canvasRef = useRef(null);
  const [resolution, setResolution] = useState('1280x720');
  const [randomTaskId, setRandomTaskId] = useState('');
  const [rawImage, setRawImage] = useState(null);
  const [currentType, setCurrentType] = useState("g");
  const [showComparison, setShowComparison] = useState(false);
  const [customWidth, setCustomWidth] = useState(1280);
  const [hdOption, setHdOption] = useState(false);
  const [customHeight, setCustomHeight] = useState(720);
  const [imageCount, setImageCount] = useState(1);
  const [seed, setSeed] = useState(-1);
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [isDrawingAllowed, setIsDrawingAllowed] = useState(false);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(1);
  const [segResults, setSegResults] = useState(null);
  const { token, isAuthenticated, currentFunc, setCurrentFunc } = useContext(AuthContext);
  const { breadcrumbPath } = useBreadcrumb();
  const [isFuncSet, setIsFuncSet] = useState(false);

  const imageGalleryRef = useRef(null);

  const navigate = useNavigate();


  useEffect(() => {
    const pathname = window.location.pathname;
    sessionStorage.setItem('currentRoute', pathname);
    setCurrentFunc(inputCurrentFunc);
    setIsFuncSet(true);
  }, []);


  const handleHdOptionChange = (value) => {
    setHdOption(value);
  };


  const handleDoodleModeChange = (mode) => {
    setIsDrawingAllowed(mode === 'manual');
  };
  const handleInputOneChange = (e) => {
    setInputOne(e.target.value);
  };

  const onClickSend = async () => {
    if (!isAuthenticated) {
      setIsLoginModalVisible(true);
      return;
    }

    const payload = JSON.stringify({
      button_prompt: buttonPrompt,
      prompt: inputOne,
      n_iter: imageCount,
      seed,
      wf_code: 10007,
      currentFunc,
      requestClass: 'c3',
      width: customWidth,
      height: customHeight,
      force_task_id: "f-" + Math.random().toString(36).slice(-8),
      init_images: [selectedImage],
      mask: toMask(canvasRef.current),
      controlnet: [],
    });

    setRandomTaskId(randomTaskId);
    try {
      const response = await sendGenerationRequest(payload);
      handleResponse(response);
    } catch (error) {
      handleError(error, showBubble);
    }
  };

  const handleResolutionChange = (value) => {
    if (value === 'custom') {
      setResolution('custom');
    } else {
      const [width, height] = value.split('x').map(Number);
      setCustomWidth(width);
      setCustomHeight(height);
      setResolution(value);
    }
  };

  const handleCustomResolutionInput = (key, value) => {
    const filteredValue = value.replace(/[^0-9]/g, '');
    if (key === 'customWidth') {
      setCustomWidth(filteredValue);
    } else if (key === 'customHeight') {
      setCustomHeight(filteredValue);
    }
  };

  const handleImageCountChange = (count) => {
    setImageCount(count);
  };

  const handleResponse = (response) => {
    if (response.code === 1000) {
      showBubble('任务提交成功');
    }
    imageGalleryRef.current?.loadHistory(page, size, currentFunc);
  };

  const handleLoginModalClose = () => setIsLoginModalVisible(false);

  const handleLoginSuccess = () => {
    imageGalleryRef.current?.loadHistory(1, 10, currentFunc);
    setIsLoginModalVisible(false);
    navigate(0);
  };

  const selectImage = (imageDetails) => {
    setSelectedImage(imageDetails.fullUrl);
  };

  return (
    <Layout style={{ height: '100%', width: '100%', display: 'flex', backgroundColor: '#101214' }}>
      <div className="flex-container">
        <Content className="l1-left-container">
          <div className="breadcrumb__down__section">
          <span className="breadcrumb__path">历史记录</span>
          {isFuncSet && (
            <ImageGallery
              ref={imageGalleryRef}
              size={size}
              page={page}
              onSelectImage={selectImage}
              setRawImage={setRawImage}
              setShowComparison={setShowComparison}
              setCurrentType={setCurrentType}
            />
          )}
              </div>
          </Content>
          <Content className="l1-middle-container">
          <div className="breadcrumb__path">
            {breadcrumbPath.join(' / ')}
          </div>
            {selectedImage ? (
              <div className="canvas-container">
                {selectedImage && currentType === 'g' && (
                  <Button
                    style={{
                      position: 'absolute',
                      top: 10,
                      right: 10,
                      zIndex: 10,
                      backgroundColor: 'rgba(255, 255, 255, 0.8)',
                      color: 'black',
                    }}
                    onClick={() => setShowComparison(!showComparison)}
                  >
                    {showComparison ? '关闭对比' : '对比'}
                  </Button>
                )}

                {showComparison && rawImage ? (
                  <ReactCompareSlider
                    itemOne={<ReactCompareSliderImage src={selectedImage} alt="Selected Image" />}
                    itemTwo={<ReactCompareSliderImage src={rawImage} alt="Raw Image" />}
                  />
                ) : (
                  <MaskEditor
                    src={selectedImage}
                    canvasRef={canvasRef}
                    isDrawingAllowed={isDrawingAllowed}
                    segResults={segResults}
                  />
                )}
                <CloseCircleOutlined
                  style={{
                    position: 'absolute',
                    bottom: 10,
                    right: 10,
                    fontSize: '24px',
                    color: 'red',
                    cursor: 'pointer',
                  }}
                  onClick={() => setSelectedImage(null)}
                />
              </div>
            ) : (
              <ImageUploader onImageSelected={setSelectedImage}/>
            )}
          </Content>
          <Content className="l1-right-container">
            <DoodleSelector
              onModeChange={handleDoodleModeChange}
              src={selectedImage}
              setSegResult={setSegResults}
              showBubble={showBubble}
            />
             <Parameters 
                imageCount={imageCount}
                resolution={resolution}
                customWidth={customWidth}
                customHeight={customHeight}
                hdOption={hdOption}
                setHdOption={handleHdOptionChange}
                handleResolutionChange={handleResolutionChange}
                handleCustomResolutionInput={handleCustomResolutionInput}
                handleImageCountChange={handleImageCountChange}
              />
              
              <CustomTextArea
                imageCount={imageCount}
                resolution={resolution}
                customWidth={customWidth}
                customHeight={customHeight}
                handleResolutionChange={handleResolutionChange}
                handleCustomResolutionInput={handleCustomResolutionInput}
                handleImageCountChange={handleImageCountChange}
                inputValue={inputOne}
                onInputChange={handleInputOneChange}
                onGenerate={onClickSend}
              />
          </Content>
      {isLoginModalVisible && (
        <Login onClose={handleLoginModalClose} onLogin={handleLoginSuccess} />
      )}
    </div>
      </Layout>
  );
};

export default FluxInpaint;

import React, { useState, useContext } from 'react';
import { Button, Input, Select, Collapse, Slider, Checkbox, message } from 'antd';
import '@styles/bridge/GenerationControls.css';
import { AuthContext } from '@contexts/AuthContext';

const { Option } = Select;
const { Panel } = Collapse;

const GenerationControls = ({
  resolution,
  customWidth,
  customHeight,
  handleResolutionChange,
  handleCustomResolutionInput,
  handleResolutionBlur,
  imageCount,
  handleImageCountChange,
  seed,
  hdOption,
  setHdOption,
  handleInputChange,
  onClickSend,
  onClickEnhance,
  showAdvancedOptions = false,
}) => {
  const [isAdvancedVisible, setIsAdvancedVisible] = useState(showAdvancedOptions);
  const imageCountOptions = [1, 2, 4, 8]; 
  const [controlnetConditioningScale, setControlnetConditioningScale] = useState(0.3);
  const [strength, setStrength] = useState(0.7);
  const [guidanceScale, setGuidanceScale] = useState(7.5);

  const { token, isAuthenticated, currentFunc, setCurrentFunc } = useContext(AuthContext);
  const userInfoString = localStorage.getItem('userInfo');
  const userInfo = userInfoString ? JSON.parse(userInfoString) : {};

  const isNotMember = (userInfo?.memberships?.[0]?.member_code == 0) && 
                      (userInfo?.organization_info?.[0]?.o_groups == "0000");

  const handleSeedInputChange = (e) => {
    const value = e.target.value;
    const onlyNumbers = value.replace(/[^0-9]/g, '');
    handleInputChange('seed', onlyNumbers ? parseInt(onlyNumbers, 10) : -1);
  };

  const renderCustomResolution = () => (
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
      <Input
        id="customWidth"
        placeholder="宽度"
        type="number"
        value={customWidth}
        onChange={(e) => handleCustomResolutionInput('customWidth', e.target.value)}
        style={{ width: '40%', marginRight: '8px' }}
        onBlur={handleResolutionBlur}
      />
      <span style={{ marginRight: 8 }}>×</span>
      <Input
        id="customHeight"
        placeholder="高度"
        type="number"
        value={customHeight}
        onChange={(e) => handleCustomResolutionInput('customHeight', e.target.value)}
        style={{ width: '40%' }}
        onBlur={handleResolutionBlur}
      />
    </div>
  );

  const handleEnhanceClick = () => {
    if (!isNotMember) {
      const allParams = {
        controlnet_conditioning_scale: controlnetConditioningScale,
        strength,
        guidance_scale: guidanceScale,
      };
      onClickEnhance(allParams);
    } else {
      message.error('请先Go Pro哦！');
    }
  };

  const handleHdOptionChange = (e) => {
    if (!isNotMember) {
      setHdOption(e.target.checked);
    } else {
      message.error('请先Go Pro哦！');
    }
  };

  const handleGenerateClick = () => {
    if (currentFunc == 'bridge-f' && isNotMember) {
      message.error('请先Go Pro哦！');
    } else {
      onClickSend();
    }
  };

  return (
    <div className="input-section">
      <h2 className="section-title">参数</h2> 
      <div className="input-group">
        <div className="input-item">
          <span className="label">分辨率 :</span>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
            <Select
              value={resolution}
              style={{ width: '100%' }}
              onChange={handleResolutionChange}
              dropdownMatchSelectWidth={false}
            >
              <Option value="1280x720">1280x720</Option>
              <Option value="1344x768">1344x768</Option>
              <Option value="768x1344">768x1344</Option>
              {/* <Option value="custom">自定义</Option> */}
            </Select>
            {resolution === 'custom' && renderCustomResolution()}
          </div>
        </div>
        <div className="input-item">
          <span className="label">图片数 :</span>
          <div className="image-count-buttons">
            {imageCountOptions.map((count) => (
              <Button
                key={count}
                type={imageCount === count ? 'primary' : 'default'}
                onClick={() => handleImageCountChange(count)}
                className={imageCount === count ? 'active' : ''}
                style={{ width: '22%' }}
              >
                {count}
              </Button>
            ))}
          </div>
        </div>
        <div className="input-item">
          <span className="label">种子号 :</span>
          <Input
            placeholder="-1"
            value={seed === -1 ? '' : seed}
            onChange={handleSeedInputChange}
            className="seed-input"
            style={{ width: '100%' }}
          />
        </div>
      </div>

      {isAdvancedVisible && (
        <>
          <div className="input-item">
            <span className="label">
              高清 <span className="member-exclusive">pro</span>:
            </span>
            <Checkbox
              checked={hdOption}
              onChange={handleHdOptionChange}
              style={{ marginLeft: 'auto' }}
            />
          </div>
          <Collapse
            style={{ border: '1px solid rgba(255, 255, 255, 0.3)', boxShadow: 'none' }}
          >
            <Panel header="增强-高级" key="1">
              <div className="input-item">
                <span className="label">控制网条件尺度:</span>
                <Slider
                  min={0}
                  max={1}
                  step={0.01}
                  value={controlnetConditioningScale}
                  onChange={setControlnetConditioningScale}
                  style={{ width: '100%' }}
                />
              </div>
              <div className="input-item">
                <span className="label">改变程度:</span>
                <Slider
                  min={0}
                  max={1}
                  step={0.01}
                  value={strength}
                  onChange={setStrength}
                  style={{ width: '100%' }}
                />
              </div>
              <div className="input-item">
                <span className="label">guidance_scale:</span>
                <Slider
                  min={0}
                  max={20}
                  step={0.1}
                  value={guidanceScale}
                  onChange={setGuidanceScale}
                  style={{ width: '100%' }}
                />
              </div>
            </Panel>
          </Collapse>
        </>
      )}

      <div className="control-buttons">
        <Button type="primary" onClick={handleGenerateClick} style={{ flex: 1, marginRight: '10px' }}>
          生成 {currentFunc == 'bridge-f' && <span className="member-exclusive">pro</span>}
        </Button>
        {isAdvancedVisible && (
          <Button type="primary" onClick={handleEnhanceClick} style={{ flex: 1 }}>
            增强 <span className="member-exclusive">pro</span>
          </Button>
        )}
      </div>
    </div>
  );
};

export default GenerationControls;

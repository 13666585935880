import React, { createContext, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { verifyToken, refreshToken } from '@services/authService';
import { message } from 'antd';
import { WS_ENDPOINTS } from '@configs/api'; // WebSocket 端点配置
import eventEmitter from '@utils/eventEmitter';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [isAuthenticated, setIsAuthenticated] = useState(!!token);
  const websocketRef = useRef(null); // 使用 useRef 代替 useState
  const navigate = useNavigate();
  const heartbeatIntervalRef = useRef(null); // 心跳定时器引用
  const reconnectTimeoutRef = useRef(null); // 重连定时器引用

  useEffect(() => {
    const checkTokenValidity = async () => {
      if (token) {
        try {
          const valid = await verifyToken(token);
          if (valid.code != 200) {
            const data = await refreshToken();
            if (data && data.code === 200) {
              handleLogin(data.access_token);
            } else {
              handleLogout();
            }
          } else {
            setIsAuthenticated(true);
            if (!websocketRef.current) {
              initWebSocket();
            }
          }
        } catch (error) {
          message.error('Token 验证失败，请重新登录');
          handleLogout();
        }
      } else {
        setIsAuthenticated(false);
      }
    };

    checkTokenValidity();

    return () => {
      if (websocketRef.current) {
        websocketRef.current.close();
      }
      if (heartbeatIntervalRef.current) {
        clearInterval(heartbeatIntervalRef.current);
      }
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
    };
  }, [token]);

  const handleLogin = (newToken) => {
    setToken(newToken);
    localStorage.setItem('token', newToken);
    setIsAuthenticated(true);
    initWebSocket();
  };

  const handleLogout = () => {
    setToken(null);
    localStorage.clear();
    setIsAuthenticated(false);
    if (websocketRef.current) {
      websocketRef.current.close();
    }
  };

  const handleWebSocketOpen = (ws, uuid) => {
    ws.send(JSON.stringify({ message: 'User logged in', uuid }));
    startHeartbeat();
  };

  const handleWebSocketMessage = (event) => {
    const messageData = JSON.parse(event.data);
    if (messageData.type === 'forced_logout') {
      handleLogout();
      message.warning('您的账号在另一个设备上登录，您已被迫下线', 3);
    }
    dispatchWebSocketMessage(messageData);
  };

  const dispatchWebSocketMessage = (messageData) => {
    eventEmitter.emit('websocketMessage', messageData);
  };

  const handleWebSocketClose = () => {
    stopHeartbeat();
    attemptReconnect();
  };

  const handleWebSocketError = (error) => {
    console.error('WebSocket error:', error);
    if (websocketRef.current) {
      websocketRef.current.close();
    }
    attemptReconnect();
  };
  

  const startHeartbeat = () => {
    stopHeartbeat();
    heartbeatIntervalRef.current = setInterval(() => {
      if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN) {
        websocketRef.current.send(JSON.stringify({ type: 'heartbeat', message: "from client" }));
      }
    }, 30000);
  };

  const stopHeartbeat = () => {
    if (heartbeatIntervalRef.current) {
      clearInterval(heartbeatIntervalRef.current);
      heartbeatIntervalRef.current = null;
    }
  };

  const attemptReconnect = () => {
    stopHeartbeat();
    if (reconnectTimeoutRef.current) {
      return; // 已经在重连中
    }
    reconnectTimeoutRef.current = setTimeout(() => {
      reconnectTimeoutRef.current = null;
      if (isAuthenticated) {
        initWebSocket();
      }
    }, 5000); // 5秒后重连
  };

  const initWebSocket = () => {
    const uuid = localStorage.getItem('uuid');
    if (uuid && isAuthenticated) {
      if (websocketRef.current) {
        websocketRef.current.close();
      }
      const ws = new WebSocket(`${WS_ENDPOINTS.wsUser}/${uuid}`);
      ws.onopen = () => handleWebSocketOpen(ws, uuid);
      ws.onmessage = handleWebSocketMessage;
      ws.onclose = handleWebSocketClose;
      ws.onerror = handleWebSocketError;

      websocketRef.current = ws;
    }
  };

  const [currentFunc, setCurrentFunc] = useState(() => {
    return sessionStorage.getItem('currentFunc') || 'all';
  });

  useEffect(() => {
    sessionStorage.setItem('currentFunc', currentFunc);
  }, [currentFunc]);

  return (
    <AuthContext.Provider value={{ token, isAuthenticated, handleLogin, handleLogout, websocket: websocketRef.current, currentFunc, setCurrentFunc }}>
      {children}
    </AuthContext.Provider>
  );
};

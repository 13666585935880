const BASE_URL = "https://api.zhijiucity.com";
const WS_BASE_URL = "wss://api.zhijiucity.com/ws";

const HEADERS = {
  'Content-Type': 'application/json',
};

export const API_ENDPOINTS = {
  txt2img: `${BASE_URL}/api/v1/txt2img`,
  fluxt2i: `${BASE_URL}/api/test/flux`,
  img2img: `${BASE_URL}/api/v1/img2img`,
  progress: `${BASE_URL}/api/v1/progress`,
  history: `${BASE_URL}/api/v1/history`,
  login: `${BASE_URL}/api/v1/login`,
  register: `${BASE_URL}/api/v1/register`,
  currentUser: `${BASE_URL}/api/v1/users/me`,
  autosegment: `${BASE_URL}/api/v1/seg`,
  feedback: `${BASE_URL}/api/v1/feedback`,
  loginImages: `${BASE_URL}/api/v2/login-images`,
  exampleImages: `${BASE_URL}/api/v2/example-images`,
  verifyToken: `${BASE_URL}/api/v2/verify-token`,
  refreshToken: `${BASE_URL}/api/v2/token/cookie-refresh`,
  sendCode: `${BASE_URL}/api/v1/send_verification_code`,
  sendPhoneCode: `${BASE_URL}/api/v1/send_verification_code_phone`,
  sendBindPhoneCode: `${BASE_URL}/api/v1/send_verification_code_bind_phone`,
  confirmBindPhoneCode: `${BASE_URL}/api/v1/confirm_verification_code_bind_phone`,
  favorite: `${BASE_URL}/api/v1/favorite`,
  ifFit: `${BASE_URL}/api/v1/image/iffit`,
  imageFeedback: `${BASE_URL}/api/v1/image/feedback`,
  uploadImage: `${BASE_URL}/api/v1/image/upload`,
  homeDisplay: `${BASE_URL}/api/v1/displaySrc`,
  groundImageData: `${BASE_URL}/api/v1/groundImageData`,
  uploadAvatar: `${BASE_URL}/api/v1/upload_avatar`,
  forgetPassword: `${BASE_URL}/api/v1/user/forgetPassword`,

  generationRequest: `${BASE_URL}/api/v1/generation_request`,
};

export const API_ORGANIZATION_ENDPOINTS = {
  getUserOrganizations: `${BASE_URL}/api/v1/user/organizations`,
  createOrganization: `${BASE_URL}/api/v1/organization/create`,
  getMembers: `${BASE_URL}/api/v1/organization/members`,
  deleteMember: `${BASE_URL}/api/v1/organization/member/delete`,
  searchUser: `${BASE_URL}/api/v1/organization/search_user`,
  inviteMember: `${BASE_URL}/api/v1/organization/invite`,
  organizationUser: `${BASE_URL}/api/v1/organization/user`,
};

export const WS_ENDPOINTS = {
  wsUser: `${WS_BASE_URL}/user`,
};

export const API_HEADERS = HEADERS;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '@styles/Entrance.css';

const Entrance = () => {
  const navigate = useNavigate();
  const [isAnimating, setIsAnimating] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  useEffect(() => {
    document.body.classList.add('no-background');

    return () => {
      document.body.classList.remove('no-background');
    };
  }, []);

  const handleLogin = () => {
    setIsAnimating(true);
    setTimeout(() => {
      navigate('/main');
    }, 1000);
  };

  const handleVideoLoad = () => {
    setIsVideoLoaded(true);
  };

  return (
    <div className={`entrance-login-container ${isAnimating ? 'animate' : ''}`}>
      {!isVideoLoaded && (
        <div className="video-placeholder">
          <p>视频加载中...</p>
        </div>
      )}

      <video
        autoPlay
        muted
        loop
        className={`background-video ${isVideoLoaded ? 'show' : 'hide'}`}
        onLoadedData={handleVideoLoad}
      >
        <source src="https://backend.temp.fit/static/video/bk2.mp4" type="video/mp4" />
        您的浏览器不支持视频播放
      </video>

      <div className="entrance-login-box">
        <h1>智能创意，无限可能</h1>
        <p>AI绘图、工程规划、桥梁、道路、景观、设计师的创意加速器！只需轻松输入提示词或上传底图，瞬间呈现令人惊艳的创意图和效果图。</p>
        <button onClick={handleLogin}>立即使用</button>
      </div>
    </div>
  );
};

export default Entrance;

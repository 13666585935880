import React, { Component } from 'react';
import { Button, Row, Col } from 'antd';
import "./CategorySelector.css";
import categories from '@configs/categories.js';

class CategorySelector extends Component {
    constructor(props) {
        super(props);
        const { selectedBridgeType } = props;
        const commonCategories = categories.common;
        const otherCategories = categories.other;
        const specificCategories = selectedBridgeType ? categories.specific[selectedBridgeType] : {};
        const allCategories = { ...commonCategories, ...specificCategories, ...otherCategories };
        const categoryKeys = Object.keys(allCategories);

        this.state = {
            selectedOptions: {},
            selectedCategories: {},
            button_prompt: '',
            activeCategoryKey: categoryKeys.length > 0 ? categoryKeys[0] : '', // Set the first categoryKey as active
        };
    }

    handleSelect = (categoryKey, subcategoryKey, value) => {
        this.setState(prevState => {
            const selectedOptions = { ...prevState.selectedOptions };
            const selectedCategories = { ...prevState.selectedCategories };

            if (selectedOptions[categoryKey] === value) {
                delete selectedOptions[categoryKey];
                delete selectedCategories[categoryKey];
            } else {
                selectedOptions[categoryKey] = value;
                selectedCategories[categoryKey] = { key: subcategoryKey, value: value };
            }

            const selectedTextArray = Object.values(selectedOptions).filter(text => text !== '');
            const button_prompt = selectedTextArray.join(', ');

            return { selectedOptions, selectedCategories, button_prompt };
        }, () => {
            this.props.onSelectionChange(this.state.button_prompt);
        });
    };

    clearSelections = () => {
        this.setState({
            selectedOptions: {},
            selectedCategories: {},
            button_prompt: ''
        }, () => {
            this.props.onSelectionChange('');
        });
    };

    renderTabs = (categoryKeys) => {
        const { activeCategoryKey } = this.state;
        return (
            <Row gutter={[16, 16]} justify="start" className="category-tabs-row">
                {categoryKeys.map(categoryKey => (
                    <Col key={categoryKey}>
                        <Button
                            type={activeCategoryKey === categoryKey ? 'primary' : 'default'}
                            className={`category-tab-button ${activeCategoryKey === categoryKey ? 'active-tab' : ''}`}
                            onClick={() => this.setState({ activeCategoryKey: categoryKey })}
                        >
                            {categoryKey}
                        </Button>
                    </Col>
                ))}
            </Row>
        );
    };
    

    renderCategoryOptions = (categoryKey, subcategories) => {
        return (
            <Row key={categoryKey} className="category-row">
                <Col span={24}>
                    {Object.entries(subcategories).map(([key, value]) => {
                        if (!this.state.selectedCategories[categoryKey] || this.state.selectedCategories[categoryKey].value !== value) {
                            return (
                                <Button 
                                    key={`${categoryKey}-${value}`}
                                    type={this.state.selectedOptions[categoryKey] === value ? 'primary' : 'default'}
                                    className={`category-button ${this.state.selectedOptions[categoryKey] === value ? 'category-button-selected' : ''}`}
                                    onClick={() => this.handleSelect(categoryKey, key, value)}
                                >
                                    {key}
                                </Button>
                            );
                        }
                        return null;
                    })}
                </Col>
            </Row>
        );
    };

    renderActiveCategoryOptions = (allCategories) => {
        const { activeCategoryKey } = this.state;
        if (!activeCategoryKey || !allCategories[activeCategoryKey]) {
            return null;
        }
        const subcategories = allCategories[activeCategoryKey];
        return this.renderCategoryOptions(activeCategoryKey, subcategories);
    };

    renderSelectedCategories = () => {
        return (
            <Row className="selected-category-row">
                <Col span={24}>
                    {Object.entries(this.state.selectedCategories).map(([categoryKey, { key, value }]) => (
                        <Button 
                            key={`${categoryKey}-${value}`}
                            type='primary'
                            className='category-button-selected'
                            onClick={() => this.handleSelect(categoryKey, key, value)}
                        >
                            {key}
                        </Button>
                    ))}
                </Col>
            </Row>
        );
    };

    render() {
        const { selectedBridgeType } = this.props;
        const commonCategories = categories.common;
        const otherCategories = categories.other;
        const specificCategories = selectedBridgeType ? categories.specific[selectedBridgeType] : {};

        const allCategories = { ...commonCategories, ...specificCategories, ...otherCategories };
        const categoryKeys = Object.keys(allCategories);

        return (
            <div className="category-selector">
                <span className="selected-label">已选：</span>
                {this.renderSelectedCategories()}
                <div className="category-background">
                {this.renderTabs(categoryKeys)}
                <div className="category-container">
                    {this.renderActiveCategoryOptions(allCategories)}
                </div>
                </div>
            </div>
        );
    }
}

export default CategorySelector;

import React, { useState, useEffect } from 'react';
import { updateOrganizationUser } from '@services/apiOrganizationService';
import './EditMemberModal.css'; // BEM 样式文件

const EditMemberModal = ({ isOpen, onClose, member, onMemberUpdated }) => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    // 同步传入的 `member` 数据到组件状态
    useEffect(() => {
        if (member) {
            setUsername(member.name || '');
            setEmail(member.email || '');
            setPassword(''); // 密码不显示，用户输入新密码更新
        }
    }, [member]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const updateData = { username, email };
            if (password) {
                updateData.password = password; // 如果用户填写密码，则包含到更新数据中
            }

            const updatedMember = await updateOrganizationUser(member.uuid, updateData);

            onMemberUpdated(updatedMember); // 通知父组件成员更新成功
            onClose(); // 关闭模态框
        } catch (err) {
            console.error('更新成员失败', err);
            alert('更新成员信息失败，请稍后再试。');
        } finally {
            setLoading(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal">
            <div className="modal__overlay" onClick={onClose} />
            <div className="modal__content" onClick={(e) => e.stopPropagation()}>
                <h2 className="modal__title">修改成员信息</h2>
                <form onSubmit={handleSubmit} className="modal__form">
                    <div className="modal__field">
                        <label htmlFor="username" className="modal__label">用户名</label>
                        <input
                            type="text"
                            id="username"
                            className="modal__input modal__input--username"
                            placeholder="请输入用户名"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            autoComplete="off" // 禁用浏览器自动填充
                            required
                        />
                    </div>
                    <div className="modal__field">
                        <label htmlFor="email" className="modal__label">邮箱</label>
                        <input
                            type="email"
                            id="email"
                            className="modal__input modal__input--email"
                            placeholder="请输入邮箱"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            autoComplete="off" // 禁用浏览器自动填充
                            required
                        />
                    </div>
                    <div className="modal__field">
                        <label htmlFor="password" className="modal__label">密码 (留空则不修改)</label>
                        <input
                            type="password"
                            id="password"
                            className="modal__input modal__input--password"
                            placeholder="请输入新密码"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            autoComplete="new-password"
                        />
                    </div>
                    <div className="modal__actions">
                        <button
                            type="submit"
                            className="modal__button modal__button--submit"
                            disabled={loading}
                        >
                            {loading ? '保存中...' : '保存'}
                        </button>
                        <button
                            type="button"
                            className="modal__button modal__button--cancel"
                            onClick={onClose}
                            disabled={loading}
                        >
                            取消
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditMemberModal;

import React, { useState, useEffect, useRef } from 'react';
import { getHomeDisplay } from '@services/apiService';
import './home-display.css';
import Watermark from '@utils/canvas/Watermark';

const HomeDisplay = () => {
    const [items, setItems] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const sliderRef = useRef(null);

    // Fetch data on initial render
    useEffect(() => {
        const fetchData = async () => {
            const response = await getHomeDisplay();
            setItems(response.data);
        };
        fetchData();
    }, []);

    // Set interval for auto slider functionality
    useEffect(() => {
        if (items.length > 1) { // Only set interval if there are more than 1 items
            const intervalId = setInterval(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
            }, 3000);

            // Clear the interval on cleanup
            return () => clearInterval(intervalId);
        }
    }, [items.length]); // Depend on items length to reset when new items arrive

    // Function to handle "next" slide
    const handleNext = () => {
        if (items.length > 0) { // Check if items is not empty
            setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
        }
    };

    // Function to handle "previous" slide
    const handlePrev = () => {
        if (items.length > 0) { // Check if items is not empty
            setCurrentIndex((prevIndex) => (prevIndex - 1 + items.length) % items.length);
        }
    };

    // Function to handle click on indicator dots
    const handleIndicatorClick = (index) => {
        setCurrentIndex(index);
    };

    return (
        <div className="home-display-container" ref={sliderRef}>
            {items.length > 0 && (
                <>
                    <button className="home-display-nav left" onClick={handlePrev}>&lt;</button>
                    <div
                        className="home-display-item"
                        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                    >
                        {items.map((item, index) => (
                            <div key={index} className="home-display-content">
                                <div 
                                    className="home-display-blur-background" 
                                    style={{ backgroundImage: `url(${item.src})` }}
                                ></div>
                                {item.type === "image" ? (
                                    <div className="home-display-image-container">
                                        <img src={item.src} alt={`item-${index}`} className="home-display-image" />
                                        <Watermark />
                                    </div>
                                ) : (
                                    <div className="home-display-video-container">
                                        <video src={item.src} controls autoPlay loop muted className="home-display-video">
                                            您的浏览器不支持视频播放。
                                        </video>
                                        <Watermark />
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                    <button className="home-display-nav right" onClick={handleNext}>&gt;</button>
                    <div className="home-display-indicators">
                        {items.map((_, index) => (
                            <div
                                key={index}
                                className={`home-display-indicator ${index === currentIndex ? 'active' : ''}`}
                                onClick={() => handleIndicatorClick(index)}
                            ></div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default HomeDisplay;

import React, { createContext, useContext, useRef } from 'react';
import Assistant from '@components/Assistant';

const AssistantContext = createContext();

export const useAssistant = () => {
  return useContext(AssistantContext);
};

export const AssistantProvider = ({ children }) => {
  const assistantRef = useRef(null);

  const showBubble = (message) => {
    if (assistantRef.current) {
      assistantRef.current.showBubble(message);
    }
  };

  const showVideoBubble = () => {
    if (assistantRef.current) {
      assistantRef.current.showVideoBubble();
    }
  };

  return (
    <AssistantContext.Provider value={{ showBubble, showVideoBubble }}>
      {children}
      <Assistant ref={assistantRef} />
    </AssistantContext.Provider>
  );
};

export { AssistantContext };

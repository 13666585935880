// @components/CreateOrganizationModal.js
import React, { useState } from 'react';
import { createOrganization } from '@services/apiOrganizationService';

const CreateOrganizationModal = ({ isOpen, onClose, onOrganizationCreated }) => {
    const [orgName, setOrgName] = useState("");

    const handleCreate = async () => {
        if (orgName.trim() === "") {
            alert("请输入组织名称");
            return;
        }

        try {
            const newOrganization = await createOrganization({ name: orgName, max_count: 10 });
            onOrganizationCreated(newOrganization); // 触发回调更新组织列表
            setOrgName(""); // 重置表单
            onClose(); // 关闭弹出框
        } catch (error) {
            console.error("Failed to create organization", error);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>创建新组织</h2>
                <input
                    type="text"
                    value={orgName}
                    onChange={(e) => setOrgName(e.target.value)}
                    placeholder="请输入组织名称"
                    className="input-org-name"
                />
                <div className="modal-actions">
                    <button className="create-button" onClick={handleCreate}>创建</button>
                    <button className="cancel-button" onClick={onClose}>取消</button>
                </div>
            </div>
        </div>
    );
};

export default CreateOrganizationModal;

import React, { useState } from 'react';
import { Card, Row, Col, Divider, Button, Modal, message } from 'antd';
import { LikeOutlined, DislikeOutlined, CloseOutlined, DownloadOutlined } from '@ant-design/icons';
import './ImageDetail.css';
import { sendifLike, sendImageFeedback } from '@services/apiService';

const ImageDetail = ({ image, batchImages, onClose }) => {
  const [currentImage, setCurrentImage] = useState(image);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState([]);

  const feedbackOptions = [
    { label: '类型不符', value: '类型不符' },
    { label: '部位有错', value: '色彩丰富' },
    { label: '模糊', value: '模糊' },
    { label: '色彩不佳', value: '色彩不佳' },
    { label: '构图混乱', value: '构图混乱' },
  ];

  const onClickImage = (img) => {
    setCurrentImage(img);
  };

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen); // 切换全屏状态
  };

  const handleFeedbackClick = async (value) => {
    const newFeedback = selectedFeedback.includes(value)
      ? selectedFeedback.filter((item) => item !== value)
      : [...selectedFeedback, value];

    setSelectedFeedback(newFeedback);
    
    const feedbackString = newFeedback.join(',');
    try {
      await sendImageFeedback(currentImage.uuid, feedbackString);
      message.success('反馈提交成功！');
    } catch (error) {
      message.error('反馈提交失败！');
    }
  };

  const handleLikeDislike = async (type) => {
    try {
      await sendifLike(currentImage.uuid);
      message.success(`${type === 'like' ? '点赞' : '踩'}成功!`);
    } catch (error) {
      message.error(`${type === 'like' ? '点赞' : '踩'}失败!`);
    }
    if (type !== 'like') {
      setIsModalVisible(true); // 只在踩的情况下显示反馈弹窗
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const toggleFullscreenImage = (url) => {
    setCurrentImage({ ...currentImage, fullUrl: url });
    setIsFullscreen(true);
  };

  const handleDownload = async () => {
    if (!currentImage.rawUrl) {
      message.error('无法下载原图，未找到原图链接');
      return;
    }
  
    try {
      // Fetch the image data as a blob
      const response = await fetch(currentImage.rawUrl);
      if (!response.ok) {
        throw new Error('下载失败');
      }
  
      const blob = await response.blob();
  
      // Create a temporary URL for the blob
      const url = window.URL.createObjectURL(blob);
  
      // Create a temporary anchor element for downloading
      const link = document.createElement('a');
      link.href = url;
      link.download = `image-${currentImage.uuid}.png`; // 自定义下载的文件名
      document.body.appendChild(link);
      link.click();
  
      // Clean up after download
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
  
      message.success('图片下载成功！');
    } catch (error) {
      message.error('图片下载失败！');
    }
  };
  
  
  return (
    <div className="image-detail-container">
      <Card className="image-detail-card" bordered>
        <CloseOutlined className="image-detail-close-icon" onClick={onClose} />

        <Row gutter={[16, 16]} className="image-detail-header">
          <Col span={12}>
            <img
              src={currentImage.fullUrl}
              alt="详细图像"
              className={`image-detail-main-img ${isFullscreen ? 'fullscreen' : ''}`}
              onClick={toggleFullscreen}
            />
          </Col>
          <Col span={12}>
            <div className="image-detail-info">
              <h2>图片信息</h2>
              <p><strong>种子：</strong>{currentImage.seed}</p>
              <p><strong>提示词：</strong>{currentImage.prompt}</p>
              <p><strong>模型：</strong>{currentImage.lora}</p>
              <p><strong>UUID：</strong>{currentImage.uuid}</p>
              <p><strong>Raw：</strong>
                {currentImage.raw ? (
                  <img
                    src={currentImage.raw}
                    alt="Raw Image"
                    className="raw-thumbnail"
                    onClick={() => toggleFullscreenImage(currentImage.raw)}
                  />
                ) : '无'}
              </p>
            </div>

            {/* 点赞、踩和下载按钮 */}
            <div className="feedback-buttons">
              <Button
                icon={<LikeOutlined />}
                onClick={() => handleLikeDislike('like')}
                style={{ marginRight: '10px' }}
              >
                赞
              </Button>
              <Button
                icon={<DislikeOutlined />}
                onClick={() => handleLikeDislike('dislike')}
                style={{ marginRight: '10px' }}
              >
                踩
              </Button>
              <Button
                icon={<DownloadOutlined />}
                onClick={handleDownload}
              >
                下载
              </Button>
            </div>
          </Col>
        </Row>

        <Divider />

        <Row className="image-detail-thumbnails">
          {batchImages.map((img, index) => (
            <Col key={index} span={4} className="thumbnail-container">
              <img
                src={img.thumbUrl}
                alt={`批次图像-${index}`}
                onClick={() => onClickImage(img)}
                className="thumbnail-img"
              />
            </Col>
          ))}
        </Row>
      </Card>

      {/* 弹窗确认 */}
      <Modal
        title="选择反馈类型"
        visible={isModalVisible}
        onCancel={handleModalCancel}
        footer={null}  // 不需要额外的提交按钮
      >
        <Row gutter={[16, 16]}>
          {feedbackOptions.map((option) => (
            <Col span={8} key={option.value}>
              <Button
                type={selectedFeedback.includes(option.value) ? 'primary' : 'default'}
                onClick={() => handleFeedbackClick(option.value)}
                style={{ marginBottom: '10px', width: '100%' }}
              >
                {option.label}
              </Button>
            </Col>
          ))}
        </Row>
      </Modal>

      {isFullscreen && (
        <div className="fullscreen-overlay" onClick={toggleFullscreen}>
          <img src={currentImage.fullUrl} alt="全屏图像" className="fullscreen-image" />
          <CloseOutlined className="fullscreen-close-icon" onClick={toggleFullscreen} />
        </div>
      )}
    </div>
  );
};

export default ImageDetail;

import React from 'react';
import { Select } from 'antd';
import categories from '@configs/categories';
import '@styles/bridge/BridgeSelector.css';

const BridgeSelector = ({ selectedBridgeType, handleBridgeTypeChange }) => (
  <div>
    <Select
      defaultValue={selectedBridgeType}
      className="transparent-select"
      dropdownStyle={{ 
        backgroundColor: '#000000',
        color: 'white' 
      }}
      onChange={handleBridgeTypeChange}
      suffixIcon={null}
    >
      {Object.keys(categories.specific).map(bridgeType => (
        <Select.Option 
          key={bridgeType} 
          value={bridgeType} 
          style={{ backgroundColor: '#000000', color: 'white' }} 
        >
          {bridgeType}
        </Select.Option>
      ))}
    </Select>
  </div>
);

export default BridgeSelector;
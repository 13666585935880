import React from 'react';
import { Slider, InputNumber, Button } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import './AdvancedSettings.css';

const AdvancedSettings = ({
  paramsVisible,
  toggleParamsVisibility,
  controlnetConditioningScale,
  setControlnetConditioningScale,
  strength,
  setStrength,
  guidanceScale,
  setGuidanceScale,
}) => {
  return (
    <div className="advanced-settings">
      <div className="advanced-settings__toggle-button-label">
      <Button
        type="text"
        onClick={toggleParamsVisibility}
        className="advanced-settings__toggle-button"
      >
        高级增强 {paramsVisible ? <CaretUpOutlined /> : <CaretDownOutlined />}
      </Button>
      </div>
      {paramsVisible && (
        <div className="advanced-settings__controls">
          <div className="advanced-settings__slider-group">
            <span className="advanced-settings__label">Controlnet Conditioning Scale</span>
            <Slider
              min={0}
              max={2}
              step={0.1}
              value={controlnetConditioningScale}
              onChange={setControlnetConditioningScale}
              className="advanced-settings__slider"
            />
            <InputNumber
              value={controlnetConditioningScale}
              onChange={setControlnetConditioningScale}
              min={0}
              max={2}
              step={0.1}
              className="advanced-settings__input-number"
            />
          </div>

          <div className="advanced-settings__slider-group">
            <span className="advanced-settings__label">Strength</span>
            <Slider
              min={0}
              max={1}
              step={0.05}
              value={strength}
              onChange={setStrength}
              className="advanced-settings__slider"
            />
            <InputNumber
              value={strength}
              onChange={setStrength}
              min={0}
              max={1}
              step={0.05}
              className="advanced-settings__input-number"
            />
          </div>

          <div className="advanced-settings__slider-group">
            <span className="advanced-settings__label">Guidance Scale</span>
            <Slider
              min={1}
              max={20}
              step={0.5}
              value={guidanceScale}
              onChange={setGuidanceScale}
              className="advanced-settings__slider"
            />
            <InputNumber
              value={guidanceScale}
              onChange={setGuidanceScale}
              min={1}
              max={20}
              step={0.5}
              className="advanced-settings__input-number"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AdvancedSettings;

// MyOrganization.jsx
import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import {
  getOrganization,
  getMembers,
  deleteOrganizationUser, // 修改导入
  createOrganizationUser,
} from '@services/apiOrganizationService';
import { AuthContext } from '@contexts/AuthContext';
import CreateOrganizationModal from '@components/self/CreateOrganizationModal';
import EditMemberModal from '@components/self/EditMemberModal';
import './organization.css';
import MemberList from '@components/self/MemberList';
const MyOrganization = () => {
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [members, setMembers] = useState([]);
  const { token, isAuthenticated } = useContext(AuthContext);
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [memberToEdit, setMemberToEdit] = useState(null);

  // 新增状态变量，用于添加成员
  const [isAddingMember, setIsAddingMember] = useState(false);
  const [newMemberData, setNewMemberData] = useState({
    username: '',
    email: '',
    password: '',
  });

  // 获取组织列表
  useEffect(() => {
    if (isAuthenticated && token) {
      getOrganization()
        .then((data) => {
          setOrganizations(data.organizations);
          if (data.organizations.length) {
            setSelectedOrg(data.organizations[0]);
          }
        })
        .catch((err) => console.error('Failed to load organizations', err));
    }
  }, [isAuthenticated, token]);

  // 当选择的组织发生变化时，获取成员列表
  useEffect(() => {
    if (selectedOrg) {
      getMembers(selectedOrg.organization_uid)
        .then((data) => setMembers(data.members))
        .catch((err) => console.error('Failed to load members', err));
    }
  }, [selectedOrg]);

  const handleOrganizationSelect = (option) => setSelectedOrg(option.value);
  const openCreateModal = () => setCreateModalOpen(true);
  const closeCreateModal = () => setCreateModalOpen(false);
  const openEditModal = (member) => {
    setMemberToEdit(member);
    setEditModalOpen(true);
  };
  const closeEditModal = () => {
    setMemberToEdit(null);
    setEditModalOpen(false);
  };

  const handleOrganizationCreated = (newOrg) => {
    setOrganizations((prev) => [...prev, newOrg]);
    setSelectedOrg(newOrg);
    closeCreateModal();
  };

  // 修改了这里，使用 deleteOrganizationUser 方法
  const handleDeleteMember = (memberUuid) => {
    console.log(memberUuid)
    if (window.confirm('确定要删除该成员吗？')) {
      deleteOrganizationUser(memberUuid)
        .then(() => {
          setMembers((prev) => prev.filter((member) => member.uuid !== memberUuid));
        })
        .catch((err) => console.error('Failed to delete member', err));
    }
  };

  const handleMemberUpdated = (updatedMember) => {
    setMembers((prevMembers) =>
      prevMembers.map((member) =>
        member.uuid === updatedMember.uuid ? updatedMember : member
      )
    );
    closeEditModal();
  };

  // 添加成员的处理函数
  const handleAddMemberClick = () => {
    setIsAddingMember(true);
  };

  const handleSaveNewMember = () => {
    if (!newMemberData.username || !newMemberData.email || !newMemberData.password) {
      alert('请完整填写用户名、邮箱和密码');
      return;
    }
    if (selectedOrg) {
      // 检查是否超过成员上限
      if (selectedOrg.member_count >= selectedOrg.max_count) {
        alert('无法添加成员，已达到组织成员上限');
        return;
      }
  
      createOrganizationUser(selectedOrg.organization_uid, newMemberData)
        .then((newMember) => {
          setMembers((prev) => [...prev, newMember]);
          setIsAddingMember(false);
          setNewMemberData({
            username: '',
            email: '',
            password: '',
          });
  
          // 更新组织的成员数量
          setSelectedOrg((prev) => ({
            ...prev,
            member_count: prev.member_count + 1,
          }));
        })
        .catch((err) => {
          console.error('Failed to add member', err);
          alert('添加成员失败');
        });
    }
  };
  

  const handleCancelAddMember = () => {
    setIsAddingMember(false);
    setNewMemberData({
      username: '',
      email: '',
      password: '',
    });
  };

  if (!organizations) return <div className="info-loading">加载中...</div>;

  const orgOptions = organizations.map((org) => ({ value: org, label: org.name }));

  return (
    <div className="organization-container">
      {organizations.length === 0 ? (
        <div className="no-organization">
          <p>您还没有组织</p>
          <button className="create-organization-button" onClick={openCreateModal}>
            创建组织
          </button>
        </div>
      ) : (
        <>
          <div className="organization-header">
            <div className="organization-actions">
              <Select
                options={orgOptions}
                onChange={handleOrganizationSelect}
                value={orgOptions.find((option) => option.value === selectedOrg)}
                placeholder="选择组织"
                className="custom-select"
              />
              {selectedOrg?.is_admin && (
                <>
                  <button className="add-member-button" onClick={handleAddMemberClick}>
                    添加成员
                  </button>
                </>
              )}
            </div>
          </div>

          {selectedOrg && (
            <div className="organization-info">
              <h3>组织信息</h3>
              <div className="info-grid">
                <div>
                  <strong>名称：</strong>
                  {selectedOrg.name}
                </div>
                <div>
                  <strong>创建时间：</strong>
                  {new Date(selectedOrg.created_at).toLocaleDateString()}
                </div>
                <div>
                  <strong>到期时间：</strong>
                  {new Date(selectedOrg.expired_at).toLocaleDateString()}
                </div>
                <div>
                  <strong>成员数：</strong>
                  {selectedOrg.member_count}/{selectedOrg.max_count}
                </div>
                <div>
                  <strong>组织代码：</strong>
                  {selectedOrg.o_groups}
                </div>
                <div>
                  <strong>管理员权限：</strong>
                  {selectedOrg.is_admin ? '是' : '否'}
                </div>
              </div>
            </div>
          )}

          <MemberList
            members={members}
            selectedOrg={selectedOrg}
            isAddingMember={isAddingMember}
            newMemberData={newMemberData}
            setNewMemberData={setNewMemberData}
            handleDeleteMember={handleDeleteMember}
            handleSaveNewMember={handleSaveNewMember}
            handleCancelAddMember={handleCancelAddMember}
            openEditModal={openEditModal}
            handleAddMemberClick={handleAddMemberClick}
          />;
          {memberToEdit && (
            <EditMemberModal
              isOpen={isEditModalOpen}
              onClose={closeEditModal}
              member={memberToEdit}
              onMemberUpdated={handleMemberUpdated}
            />
          )}
        </>
      )}
      <CreateOrganizationModal
        isOpen={isCreateModalOpen}
        onClose={closeCreateModal}
        onOrganizationCreated={handleOrganizationCreated}
      />
    </div>
  );
};

export default MyOrganization;

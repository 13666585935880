import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Button } from 'antd';

const UserAgreement = ({ onClose }) => {
    const userAgreementContent = `
# City AI服务协议
## 最新版本生效日期：2024年11月21日
欢迎您使用City AI网站及相关服务。
City AI服务协议（以下简称“本协议”）是City AI产品（包括但不限于服务网页https:// zhijiucity.com/以及未来可能开发的App、小程序等新载体）的经营者上海智玖城智能科技有限公司（下称“智玖城”）与用户（以下称为“您”）共同缔结的、对双方具有约束力的有效契约。智玖城向您提供City AI上所展示的产品与服务（以下简称“产品服务”），并将不断更新产品与服务范围及服务内容，最新的产品服务以City AI相关产品及服务介绍页面展示的以及向您实际提供的为准。您续签或新订购某产品或服务后，应遵守当时的服务条款，City AI推出新的产品与服务后，将可能更新本协议中相关内容。
## 一、特别提示
- 在您开始使用City AI提供的相关服务（ “City AI服务”）之前，请您务必认真阅读并充分理解本协议的每一条款、您开通或使用某项服务时的附加条款以及智玖城不时发布或修订的相关协议。同时，您还应仔细阅读并充分理解智玖城的知识产权声明、免责声明、隐私权保护声明等系列声明。在本协议中，请您务必特别注意及重点阅读与您的权利及义务密切相关的条款，此类条款可能以粗体的方式标识，包括但不限于免除或者限制责任、管辖与法律适用的条款。
- 如果您使用City AI服务，您应当完全接受本协议的全部约定或规定。如您不同意本协议及/或随时对其的修改，请勿访问City AI或使用City AI服务，您可通过从产品中终止使用City AI所提供的相关服务来取消“服务”。一旦您开始使用City AI服务，就视为您已经了解并完全同意本协议及智玖城系列声明的各项内容及智玖城对其随时作出的修改，同时还表明您已经完全同意智玖城已经完全履行了相关的提示义务和说明义务。您应确保您有充分的权利、权力、权限及能力以同意及履行本协议。
- 智玖城有权不时对本协议进行修订，且无需事先及单独通知您。如果本协议内容发生变动，智玖城将在其平台上公布修订后的协议。您充分理解并同意，如果您在修订后的协议公布后继续使用City AI服务，即视为您同意修订后的协议。
- 除非您已充分阅读、完全理解并接受本协议所有条款，否则您才能使用City AI服务。您点击同意或下一步，或您使用City AI服务，或者以其他任何明示或者默示方式表示接受本协议的，均视为您已阅读并同意签署本协议所有内容，本协议即在您与智玖城之间产生法律效力，成为对双方均具有约束力的法律文件。如您不同意智玖城随时对本协议的修改或补充内容，您应放弃注册、停止使用或主动取消City AI服务。
- 如果您因年龄、智力等因素而不具有完全民事行为能力，请在监护人的陪同下阅读和同意本协议，并特别注意未成年人使用条款。
- 如果您是中国大陆地区以外的用户，您订立或履行本协议的行为需要同时遵守您所属国家或地区的法律。您应保证，您订立并履行本协议的行为不违反您所属的国家或地区法律法规的强制性规定。
- 如对本协议内容有任何疑问、意见或建议，您可以发送邮件到（feedback@zhijiucity.com）与我们联系。
## 二、City AI产品及相关服务
- City AI平台旨在提供文生图及相关功能，适用于启发设计师等视觉内容创作者的灵感并辅助完成创作。您可结合自身需求输入描述性文字、图片获取由AI为您定制图像等。在这里您可以生成不同风格的桥梁、道路、景观，为创作带来更多创意，为您的想象力赋能。
- 您需要注册并登录账号，以使用City AI产品服务。请妥善保护个人账号和密码。您将对以您注册账号的名义所从事的活动承担全部法律责任，包括但不限于因账号被盗或丢失而产生的一切法律责任。如果您发现有人未经授权使用您的账号或发现其他安全漏洞问题，您应立即通知本公司。
- 您确认，您按照公司的指定渠道和方式完成相关注册、登录流程时，您应当具备完全民事权利能力和与所从事的民事行为相适应的行为能力。若您不具备前述主体资格，请勿使用本服务，否则您及您的监护人应承担因此而导致的一切后果，且公司有权注销您的账号，而无需向该账号的用户承担法律责任，由此带来的因您使用本服务产生的全部数据和信息等被清空、丢失等的损失，由您自行承担。
- 您理解并同意，在使用账号时，应当遵守相关法律法规，不得有任何侵害国家利益、损害其他公民合法权益或有害社会道德风尚的行为。当系统识别到类似风险时，公司有权审核您的账户信息。如有上述不当行为，公司有权不经通知暂停或终止向该账号提供服务，并有权注销该账号，且无需向该账号的用户承担法律责任。
- 您的账号仅供您本人使用，未经公司书面同意，禁止以任何形式赠与、借用、出租、转让、售卖或以其他方式许可他人使用。如果公司发现或有合理理由认为使用者并非账号的初始注册人，公司有权不经通知暂停或终止向该账号提供服务，并有权注销该账号，且无需向该账号的用户承担法律责任。由此导致的您在使用本服务过程中产生的所有数据、信息等被清空、丢失等损失，您应自行承担。
- 未经公司事先书面授权，您不得以任何形式对City AI产品及相关服务进行未经授权的访问或使用，包括但不限于改编、复制、传播、垂直搜索、镜像或交易等。
- 为持续提升用户体验，公司有权不向您特别通知而不定期更新City AI产品及相关服务（包括但不限于网站修改、升级、功能强化、开发新服务等）。
- 公司授予您个人的、可撤销的、不可转让的、非独占的和非商业的合法使用City AI产品及相关服务的权利。本协议未明示授权的其他一切权利仍属于公司，您在行使上述权利前，需另行取得公司的书面许可。如果公司未行使前述任何权利，并不构成对该权利的放弃。
- 如您发现City AI产品上存在任何侵犯您权利的内容，请立即通过邮箱（contact@zhijiucity.com）通知公司，提供您有相关权利的证据，公司将会依法及时处理您的投诉。
- 您可以在City AI产品中通过人工方式申请注销账户。您可以发送邮件到（contact@zhijiucity.com）与我们联系。在您注销账号前，我们将验证您的个人身份、安全状态、设备信息等。您注销账号的行为是不可逆的；一旦您注销账号，我们将停止为您提供相关服务，并按照您的要求删除有关您账号的一切信息，但法律法规另有规定的除外。
## 三、试用与付费
- 您在City AI产品可以使用的功能、权限和服务效果取决于您是否是付费用户。
- 当升级成付费用户时，您必须指定有效的支付方式。 您授权City AI向您指定的账户收取与您所选择的服务有关的全部费用，您同意按照适用的支付方式、条款和条件支付全部的费用。
- City AI产品保留修改产品定价的权利，当进行价格修订时，会至少提前2个星期向用户通知。此类通知会以网站或微信小程序发布、站内信、邮件、短信或其他方式发送。您将被视为已经收到在网站上发布的任何此类通知。 如果您在2个星期后继续使用City AI产品的服务，将视为您接受新的产品定价。如果您不同意修改后的产品定价，您可以取消订阅。
- 取消订阅您可以发送邮件到（contact@zhijiucity.com）与我们联系。
- City AI产品将不定期为新用户提供免费试用或特价活动。当您访问或使用本网站、服务或软件时，即表示您已同意本服务条款之所有内容。如果您在试用期结束前注销账户或取消订阅，您将不再继续享有第二次免费试用的机会。
- City AI产品提供的虚拟服务和产品，不会按比例退还任何在服务期限结束前终止订阅之前所支付的费用， 本协议中另有明确规定的情况除外。
## 四、用户个人信息保护
- 公司致力于保护您的个人隐私信息。
- 在使用City AI产品及相关服务的过程中，您可能需要提供您的个人信息（包括但不限于您的手机号、头像、设备信息等），以便公司向您提供更好的服务和相应的技术支持。公司将运用加密技术、匿名化处理等安全措施保护您的个人信息。
- 公司将按照本协议及《City AI 隐私政策》的规定收集、使用、储存和分享您的个人信息。本协议对个人信息保护相关内容未作明确规定的，均应以《隐私政策》的内容为准。《隐私协议》是本协议不可分割的一部分，与本协议具有同等法律效力。签订本协议代表您同意《隐私协议》中的全部条款，并同意本公司按照《隐私协议》的规定使用数据不构成对您权利的侵犯。
- 公司不会将您的个人信息转移或披露给任何第三方，除非：
    - （1）相关法律法规或司法机关、行政机关要求；
    - （2）为完成合并、分立、收购或资产转让而转移；
    - （3）为提供您要求的服务所必需；
    - （4）依据《City AI 隐私政策》或其他相关协议规则可以转移或披露给任何第三方的情形。
## 五、用户文档
- “用户文档”是指您在使用我们的网站、软件和服务时输入、上传和传输的文本、文件及其他内容和信息。您拥有用户文档的知识产权。
- 在此，您对本公司进行授权，在世界范围内、非排他的，无版税的，长期的仅限于以下目的使用您的用户文档：
    - （1）提供和改进AI算法及服务；
    - （2）保护网站安全及服务稳定；
    - （3）为您提供个性化定制服务；
- 我们将尽最大权利保护您的用户文档安全，亦不会向第三方机构出售数据。
- City AI并不拥有、验证或背书用户文档内容。您对用户文档负有全部责任。
## 六、第三方网站及应用
- City AI可能设有向第三方网站、平台的跳转链接，也不排除其他用户上传或发布的内容中添加了指向外部网站的链接，您应仔细阅读这些外部网站的使用条款及隐私政策，在法律允许的范围内，我们不对外部网站的真实性、合法性或安全性承担责任。
- 您在访问City AI或使用City AI服务时如果使用了第三方提供的应用、产品、软件或服务，除遵守City AI的相关平台规则外，还可能需要同意并遵守第三方的协议或规则。如因第三方应用、产品、软件及相关服务产生争议、损失或损害，由您自行与第三方解决，我们并不就此而对您或任何第三方承担责任。
## 七、用户行为规范
- 用户行为要求：您应对您使用City AI产品及相关服务的行为负责，除非法律允许或者经公司事先书面许可，您使用City AI产品及相关服务不得具有下列行为：
    - （1）使用未经我们授权或许可的任何插件、外挂、系统或第三方工具对City AI产品及相关服务的正常运行进行干扰、破坏、修改或施加其他影响。
    - （2）恶意注册City AI账号，包括但不限于频繁、批量注册账号；
    - （3）非法侵入他人网络、干扰他人网络正常功能、窃取网络数据等危害网络安全的活动；
    - （4）提供专门用于从事侵入网络、干扰网络正常功能及防护措施、窃取网络数据等危害网络安全活动的程序、工具；
    - （5）明知他人从事危害网络安全的活动的，为其提供技术支持、广告推广、支付结算等帮助；
    - （6）使用未经许可的数据或进入未经许可的服务器/账号；
    - （7）未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；
    - （8）未经许可，企图探查、扫描、测试等破坏City AI系统或网络安全的行为；
    - （9）企图干涉、破坏City AI系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；
    - （10）伪造TCP/IP数据包名称或部分名称。
    - （11）对City AI网站进行反向工程、反向汇编、编译或以其他方式尝试发现City AI网站的源代码。
    - （12）对City AI网站或其运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及City AI网站运行所必需的系统数据，进行获取、复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂、群控或非经我们授权的第三方工具/服务接入City AI网站和相关系统。
    - （13）通过修改或伪造City AI网站运行中的指令、数据，增加、删减、变动City AI网站的功能或运行效果，或将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的。
    - （14）删除City AI网站和相关内容上关于知识产权的信息，或修改、删除、避开为保护知识产权而设置的任何技术措施。
    - （15）对我们拥有知识产权的内容或City AI网站内其他用户发布的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等。
    - （16）利用City AI账号参与任何非法或有可能非法的活动或交易，包括传授犯罪方法、出售任何非法药物、洗钱活动、诈骗等。
    - （17）其他违反法律法规、本协议、City AI平台规则及侵犯他人合法权益的其他行为。
如果我们有合理理由认为您的任何行为违反或可能违反上述约定的，我们可独立进行判断并采取必要措施进行处理，紧急情况时可在不事先通知的情况下终止向您提供服务，并依法依约追究相关责任。
- 信息运营规范：您理解并同意，City AI一直致力于为用户提供文明健康、规范有序的网络环境，您不得利用City AI账号或服务直接或间接输入、制作、复制、发布、传播、上传、输出下列违法违规、干扰正常运营，以及侵犯其他用户或第三方合法权益的内容，包括但不限于
    -（1）反对宪法所确定的基本原则的；
    -（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的，推翻社会主义制度，煽动分裂国家的；
    -（3）损害国家荣誉和利益的；
    -（4）宣扬恐怖主义、极端主义的；
    -（5）煽动民族仇恨、民族歧视，破坏民族团结的；
    -（6）煽动地域歧视、地域仇恨的；
    -（7）破坏国家宗教政策，宣扬邪教和封建迷信的；
    -（8）编造、散布谣言、虚假信息，扰乱经济秩序和社会秩序、破坏社会稳定的；
    -（9）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
    -（10）侵害未成年人合法权益或者损害未成年人身心健康的；
    -（11）侮辱或者诽谤他人，侵害他人合法权益的；
    -（12）涉及他人隐私、个人信息或资料的，侵犯他人隐私权、名誉权、肖像权、知识产权、商业秘密等合法权益内容的；
    -（13）危害网络安全、利用网络从事危害国家安全、荣誉和利益，妨碍互联网运行安全的信息；
    -（14）含有法律法规、政策禁止的或违反公序良俗、社会公德、干扰本软件正常运营和侵犯其他用户或第三方合法权益等内容的信息。
    -（15）制作、复制、发布、传播骚扰或含有任何性或性暗示的信息的；
    -（16）制作、复制、发布、传播商业推广信息、广告信息、过度营销信息或垃圾信息的；
    -（17）所发表的信息毫无意义的，或刻意使用字符组合以逃避技术审核的;
    -（18）冒充他人或利用他人的名义使用本服务或传播任何信息，恶意使用注册账号导致其他用户误认的
    -（19）包含恐怖、暴力血腥、高危险性、危害表演者自身或他人身心健康内容。
- 服务运营规范：除非法律允许或公司书面许可，第三方不得自行或授权、允许、协助任何他人对City AI中信息内容进行如下行为：
    - （1）提交、发布虚假信息，或冒充、利用他人名义的；
    - （2）诱导其他用户点击链接页面或分享信息的；
    - （3）虚构事实、隐瞒真相以误导、欺骗他人的；
    - （4）以任何形式使用本服务侵犯公司的商业利益，或从事任何可能对公司造成损害或不利于公司的行为；
    - （5）制作、发布、使用、传播用于窃取本软件账号及他人个人信息、财产的恶意程序的；
    - （6）其他违反法律法规规定、侵犯其他用户合法权益、干扰产品正常运营或公司未明示授权的行为。
    - （7）采用包括但不限于特殊标识、特殊代码等任何形式的识别方法，自行或协助第三人对City AI的信息内容产生流量、阅读量引导、转移、劫持等不利影响；
- 经我们书面许可后，任何用户、第三方对City AI的信息内容的分享、转发等行为，还应符合以下规范：
    - （1）对抓取、统计、获得的相关搜索热词、命中率、分类、搜索量、点击率、阅读量等相关数据，未经我们事先书面同意，不得将上述数据以任何方式公示、提供、泄露给任何第三人；
    - （2）不得对City AI的源网页进行任何形式的任何改动，包括但不限于City AI的首页（profile页面）链接，广告系统链接等入口，也不得对City AI的源页面的展示进行任何形式的遮挡、插入、弹窗等妨碍；
    - （3）应当采取安全、有效、严密的措施，防止City AI的信息内容被第三方通过包括但不限于“蜘蛛（spider）”程序等任何形式进行非法获取；
    - （4）不得把相关数据内容用于我们书面许可范围之外的目的，进行任何形式的销售和商业使用，或向第三方泄露、提供或允许第三方为任何方式的使用；
    - （5）向任何第三人分享、转发、复制City AI信息内容的行为，应当遵守本协议及我们为此制定的其他规范和标准。
- 对自己行为负责：
    - （1）我们有权采取技术或者人工方式对您的输入数据和AI的合成结果进行审核。您必须为自己注册账号下的一切行为负责，包括您所发布的任何内容以及由此产生的任何后果。您不得在使用本服务过程中进行任何违反中国法律、法规、规章、条例以及任何具有法律效力之规范的行为。
    - （2）City AI合成结果仅供您参考，您不应将输出的内容作为专业建议、商业用途或用于其他目的。若涉及对您或者相关方可能会产生重大影响的情形，建议您咨询相关专业人士。您根据输出的内容所作出的任何判断或者据此作出的后续相关操作行为，所带来的后果和责任均由您自行承担，包括因对输出的内容的真实性、准确性、可靠性、不侵权或因此产生的其他风险。
    - （3）您确保，使用本服务上传、制作、传播的信息内容，是您所有或已得到相关权利人授权同意处理的信息，City AI根据您的指令分析、处理该等信息内容以及生成新的信息内容不会侵犯任何第三方的合法权益（包括但不限于专利权、商标权、著作权及著作权邻接权、肖像权、隐私权、名誉权、财产权等）。如因您利用本服务上传、制作、传送或通过其他方式传播侵犯了第三方的合法权益（包括但不限于专利权、商标权、著作权及著作权邻接权、肖像权、隐私权、名誉权、财产权等）而导致我们或我们的合作伙伴面临任何投诉、举报、质询、索赔、诉讼，或者使我们或我们的合作伙伴因此遭受任何名誉、声誉或者财产上的损失，您应积极地采取一切可能采取的措施，以保证我们和我们的合作伙伴免受上述索赔、诉讼的影响。同时您对我们及我们的合作伙伴因此遭受的全部直接及全部间接损失负有损害赔偿责任。
- 您在使用本服务过程中应当遵守当地相关的法律法规，并尊重当地的道德和风俗习惯。如果您的行为违反了当地法律法规或道德风俗，您应当为此独立承担责任。
- 您应避免因使用本服务而使公司违反法律法规或卷入政治和公共事件，否则公司有权暂停或终止对您的服务。
- 您有责任自行备份存储在本服务中的数据。如果您的服务被终止，公司有权（但无义务）从服务器上永久地删除您的数据，法律法规另有规定的除外。服务中止或终止后，公司没有义务向您提供或返还数据。
- 如果您在使用本服务过程中，发现有如上违法违规内容的，您可通过City AI的用户反馈入口或发送邮件至contact@zhijiucity.com向我们投诉举报，我们将及时受理和处理您的投诉举报，共同营造风清气正的网络空间。
## 八、知识产权
- 您理解并同意，City AI平台提供的服务中包含的任何编码、商标、服务标志、商号、图形、美术品、照片、肖像、文字内容、音频、视频、按钮图标以及计算机软件、标识、数码下载、数据汇编等都是我们或其内容提供者的财产，受中华人民共和国著作权、商标权和其他财产所有权相关法律法规的保护，您不得进行任何侵犯公司或任何第三方知识产权或其他权益的行为。
- 您理解并同意，除非另有约定，您通过City AI平台上传、发布的用户内容的所有权、知识产权及其他法律权利，归您或您的许可方所有。为使用户内容得到更好的分享及推广，提高其传播价值及影响力，您同意和/或确保实际权利人同意：
    - （1）对抓取、统计、获得的相关搜索热词、命中率、分类、搜索量、点击率、阅读量等相关数据，未经我们事先书面同意，不得将上述数据以任何方式公示、提供、泄露给任何第三人；

在您的授权下，我们有权在必要时以自己的名义或委托专业第三方，对侵犯您上传发布的享有知识产权的内容进行代维权，维权形式包括但不限于：监测侵权行为、发送维权函、提起诉讼或仲裁等。

您对您在City AI平台上发布或以其他方式提供的内容拥有所有权、控制权和责任。您的内容不得违反任何本协议的内容、适用法律和法规，也不得侵犯或盗用任何个人或实体的任何权利。如果我们对您的内容违反以上协议内容有疑虑，我们可以随时自行决定删除您的内容；如您对处理结果有异议的，可通过本协议联系方式与我们联系并提供材料协商解决。

您使用City AI平台及相关服务生成图片时，在图像生成工具的交互界面、生成图片显示区域会带有“内容由City AI生成”字样的水印，您下载图片时可选择生成AI标识并保存。
您应当善意地将本服务作为辅助创作的工具，如您使用本服务模仿著作财产权仍在保护期内的特定作品的风格生成图片或其他类型内容，并拟就生成内容进行公开发行传播或商业化使用时，您应当对生成内容及使用行为是否符合法律法规规定进行必要审查，必要时还应当寻求专业鉴定部门的意见，以最大限度地排除知识产权侵权的风险。
    - （2）对于您上传到City AI平台公共部分的任何文件或内容（包括但不限于模型、图片等），您明确授予我们及我们的关联实体永久的、非排他性的、可转授权、再许可的、免费的、免版税的、不可撤销的全球许可，以在互联网当前或未来的媒体中以任何形式使用、展示、下载和/或分发上述文件或内容及创建的衍生作品，或根据您的指示产生的资产。上述许可用于与City AI平台和公司其他相关业务平台，包括但不限于与修改、改进和提高人工智能模型相关，以及以任何媒体格式和通过任何媒体渠道推广和重新分发的部分或全部服务（及其衍生作品）。
如果您从City AI平台的删除任何上述文件或内容，您根据前述内容授予City AI平台及公司的许可将自动终止，但对于City AI平台及公司已经复制和再许可或指定再许可产生的衍生作品或相关的任何文件或内容，不会被撤销或被视为非法。本许可在任何一方出于任何原因终止本协议后仍然有效。
此外，当内容包含合理且惯常的许可声明（例如开源许可）时，此类内容在进一步访问、分发或使用时应遵守该许可的条款，任何一方均不得删除对任何此类许可的引用。
- 请您在任何情况下都不要私自使用公司的包括但不限于City AI在内的任何商标、服务标记、商号、域名、网站名称或其他显著品牌特征等（以下统称为"标识"）。未经公司事先书面同意，您不得将本条款前述标识以单独或结合任何方式展示、使用或申请注册商标、进行域名注册等，也不得实施向他人明示或暗示有权展示、使用、或其他有权处理该些标识的行为。由于您违反本协议使用公司上述商标、标识等给公司或他人造成损失的，由您承担全部法律责任。
## 九、违约处理
- 针对您违反本协议或其他服务条款的行为，公司有权酌情采取包括但不限于冻结账号或限制、暂停、终止您使用本服务等措施。对涉嫌违法犯罪的行为，公司将保存有关记录，并有权依法向有关主管部门报告、配合有关主管部门的调查、向公安机关报案等。
- 如您因违反本协议或其他服务条款规定，引起第三方投诉或诉讼索赔的，您应当自行处理并承担可能因此产生的全部法律责任。因您的违法或违约等行为导致公司及其关联方、控制公司、继承公司向任何第三方赔偿或遭受国家机关处罚的，您还应足额赔偿公司及其关联方、控制公司、继承公司因此遭受的全部损失。
## 十、服务的变更、中断和终止
- 您理解并同意，City AI产品及相关服务是公司在现有技术和条件范围内提供的。公司将尽最大努力向您提供稳定、安全的服务。您理解本公司不能随时预见和防范技术风险或其他风险，包括但不限于不可抗力、网络原因、病毒、木马、黑客攻击、系统不稳定、政府行为、第三方服务瑕疵、第三方网站等原因可能导致的服务中断、数据丢失、不能正常使用本软件及服务以及其他的损失和风险。
- 您理解并同意，为保障平台整体运营与安全，本公司有权根据具体情况决定服务和功能的范围，修改、中断、暂停或终止City AI 网站及相关服务。
## 十一、免责声明
- 您理解并同意，本网站及相关服务可能会受多种因素的影响或干扰，公司不保证（包括但不限于）：
    - （1）City AI产品及服务完全适合用户的使用要求；
    - （2）City AI产品及服务不受干扰，及时、安全、可靠或不出现错误；用户经由公司取得的任何产品、服务或其他材料符合用户的期望；
    - （3）City AI产品及服务中不出现任何错误。
- 用户理解并同意，在使用本软件及相关服务过程中，可能遇到不可抗力等因素（不可抗力是指不能预见、不能克服并不能避免的客观事件），包括但不限于政府行为、自然灾害（如洪水、地震、台风等）、网络原因、黑客攻击、战争或任何其它类似事件。出现不可抗力情况时，公司将努力在第一时间及时修复，但因不可抗力造成的暂停、中止、终止服务或造成的任何损失，公司在法律法规允许范围内免于承担责任。
- 用户阅读、理解并同意，本协议是在保障遵守国家法律法规、维护公序良俗，保护他人合法权益的基础上实施，公司在能力范围内尽最大的努力按照相关法律法规进行判断，但并不保证公司判断完全与司法机关、行政机关的判断一致，如因此产生的后果用户已经理解并同意自行承担。
- 在任何情况下，公司均不对任何间接性、后果性、惩罚性、偶然性、特殊性或刑罚性的损害承担责任。公司对您承担的全部责任，无论因何原因或何种行为方式，始终不超过您在会员期内因使用City AI产品及相关服务而支付给公司的费用（如有）。
- 在法律允许的范围内，公司对以下情形导致的服务中断或受阻不承担责任：
    - （1）受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏；
    - （2）用户或公司的电脑软件、系统、硬件和通信线路出现故障；
    - （3）用户操作不当或用户通过非公司授权的方式使用本服务；
    - （4）程序版本过时、设备的老化和/或其兼容性问题；
    - （5）其他公司无法控制或合理预见的情形。
- 您理解并同意，在使用本服务的过程中，可能会遇到网络信息或其他用户行为带来的风险，公司不对任何信息的真实性、适用性、合法性承担责任，也不对因侵权行为给您造成的损害负责。这些风险包括但不限于：
    - （1）来自他人匿名或冒名的含有威胁、诽谤、令人反感或非法内容的信息；
    - （2）遭受他人误导、欺骗或其他导致或可能导致的任何心理、生理上的伤害以及经济上的损失；
    - （3）其他因网络信息或用户行为引起的风险。
- 公司依据本协议约定获得处理违法违规内容的权利，该权利不构成公司的义务或承诺，公司不能保证及时发现违法行为或进行相应处理。
- 在任何情况下，您不应轻信借款、索要密码或其他涉及财产的信息。涉及财产操作的，请一定先核实对方身份，并请经常留意公司有关防范诈骗犯罪的提示（如有）。  

## 十二、未成年人使用条款
- 若用户是未满18周岁的未成年人，应在监护人监护、指导并获得监护人同意情况下认真阅读本协议后，方可使用City AI产品及相关服务。
- 公司重视对未成年人个人信息的保护，未成年用户在填写个人信息时，请加强个人保护意识并谨慎对待，并在监护人指导时正确使用City AI 网站及相关服务。
- 未成年用户理解如因您违反法律法规、本协议内容，则您及您的监护人应依照法律规定承担因此而可能引起的全部法律责任。
- 未成年人用户特别提示：青少年使用本软件及相关服务应该在其监护人的监督指导下，在合理范围内正确学习使用网络，避免沉迷虚拟的网络空间，养成良好上网习惯。
- 已满18周岁的成年人因任何原因不具备完全民事行为能力的，参照适用本协议之未成年人使用条款之相关约定。
## 十三、其他
- 本协议的成立、生效、履行、解释及争议的解决均应适用中华人民共和国大陆地区法律。如果本协议中的任何规定因与中华人民共和国大陆地区的法律抵触而无效，则这些条款将尽可能接近本协议原条文意旨重新解析，且本协议其它规定仍应具有完整的效力及效果。
- 本协议的签署地点为中华人民共和国北京市，若您与公司发生争议，双方应尽量友好协商解决，协商不成，您同意应将争议提交至北京市法院管辖。
- 本协议条款由本协议正文及所有公司已经发布或将来可能发布的隐私政策、各项政策、规则、声明、通知、警示、提示、说明（以下简称“规则”）组成。公司将以醒目的方式提供这些协议、规则供您查阅。一旦您开始使用上述产品，则视为您同时接受该产品界面公示的所有相关协议、规则的约束。本协议条款部分内容被有管辖权的法院认定为违法或无效的，不因此影响其他内容的效力。
- 为给您提供更好的服务，或为了适应国家法律法规和政策调整需要，公司保留对本协议进行修订的权利，这些修订构成本协议的一部分。本协议更新后，我们会在City AI产品发出更新版本，并在更新后的条款生效前通过官方网站（https:// zhijiucity.com/）以及未来可能开发的App、小程序等新载体或其他适当的方式提醒您更新的内容，以便您及时了解本协议的最新版本，您也可以在网站首页或软件设置页面查阅最新版本的协议条款。如您继续使用City AI产品及相关服务，表示同意接受修订后的本协议的内容。
- 如您对修订后的协议内容存有异议的，请立即停止登录或使用City AI产品及相关服务。若您继续登录或使用City AI产品及相关服务，即视为您认可并接受修订后的协议内容。

`;

    return (
        <div className="full-screen-container">
            <div className="full-screen-content">
                <ReactMarkdown>{userAgreementContent}</ReactMarkdown>
                <Button type="primary" onClick={onClose}>
                    返回
                </Button>
            </div>
        </div>
    );
};

export default UserAgreement;

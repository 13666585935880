import React, { useState, useContext } from 'react';
import { Button, Input, Select, Collapse, Slider, Checkbox, message } from 'antd';
import '@styles/bridge/GenerationControls.css';

const { Option } = Select;
const { Panel } = Collapse;

const GenerationControls = ({
  resolution,
  customWidth,
  customHeight,
  handleResolutionChange,
  handleCustomResolutionInput,
  handleResolutionBlur,
  imageCount,
  hdOption,
  setHdOption,
  handleImageCountChange,
}) => {
  const imageCountOptions = [1, 2, 4, 8]; 

  const userInfoString = localStorage.getItem('userInfo');
  const userInfo = userInfoString ? JSON.parse(userInfoString) : {};

  const isNotMember = (userInfo?.memberships?.[0]?.member_code == 0) && 
                      (userInfo?.organization_info?.[0]?.o_groups == "0000");

  const renderCustomResolution = () => (
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
      <Input
        id="customWidth"
        placeholder="宽度"
        type="number"
        value={customWidth}
        onChange={(e) => handleCustomResolutionInput('customWidth', e.target.value)}
        style={{ width: '40%', marginRight: '8px' }}
        onBlur={handleResolutionBlur}
      />
      <span style={{ marginRight: 8 }}>×</span>
      <Input
        id="customHeight"
        placeholder="高度"
        type="number"
        value={customHeight}
        onChange={(e) => handleCustomResolutionInput('customHeight', e.target.value)}
        style={{ width: '40%' }}
        onBlur={handleResolutionBlur}
      />
    </div>
  );

  const handleHdOptionChange = (e) => {
    if (!isNotMember) {
      setHdOption(e.target.checked);
    } else {
      message.error('请先Go Pro哦！');
    }
  };

  return (
    <div className="input-section">
      <h2 className="section-title">参数</h2> 
      <div className="input-group">
        <div className="input-item">
          <span className="label">分辨率 :</span>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', width: '100%' }}>
            <Select
              value={resolution}
              style={{ marginRight: '0px' }}
              onChange={handleResolutionChange}
              className="transparent-select"
            >
              <Option value="1280x720">1280x720</Option>
              <Option value="1344x768">1344x768</Option>
              <Option value="768x1344">768x1344</Option>
              {/* <Option value="custom">自定义</Option> */}
            </Select>
            {resolution === 'custom' && renderCustomResolution()}
          </div>
        </div>
        <div className="input-item">
          <span className="label">图片数 :</span>
          <Select
            value={imageCount}
            onChange={handleImageCountChange}
            className="transparent-select"
          >
            {imageCountOptions.map((count) => (
              <Option key={count} value={count}>
                {count}
              </Option>
            ))}
          </Select>
        </div>

      <div className="input-item">
            <span className="label">
              高清 <span className="member-exclusive">pro</span>:
            </span>
            <Checkbox
              checked={hdOption}
              onChange={handleHdOptionChange}
              style={{ marginLeft: 'auto' }}
            />
          </div>
      </div>
    </div>
  );
};

export default GenerationControls;
// @configs/loras.js
export const loras = [
    {
      key: 'lora_1',
      name: '效果图',
      value: 'l0010',
    },
    {
      key: 'lora_2',
      name: '实景',
      value: 'l0011',
    }
  ];
  
  export const bridgeLoras = [
    {
      key: 'arch-bridge',
      name: '拱桥v1',
      value: 'l0001',
    },
    {
      key: 'cable-stayed-bridge',
      name: '斜拉桥',
      value: 'l0002',
    },
    {
      key: 'suspension-bridge',
      name: '悬索桥',
      value: 'l0003',
    },
    {
      key: 'arch-bridge2410',
      name: '拱桥v2',
      value: 'l0004',
    },
    {
      key: 'beam-bridge',
      name: '梁桥',
      value: 'l0005',
    },
    {
      key: 'truss-bridge',
      name: '桁架桥',
      value: 'l0006',
    },
    {
      key: 'pedestrian-overpass-bridge',
      name: '人行天桥',
      value: 'l0007',
    },
    {
      key: 'pedestrian-bridge',
      name: '人行桥',
      value: 'l0008',
    },
    {
      key: 'sdxl-bridge-000084',
      name: '混合桥型',
      value: 'l0009',
    }
  ];
  
import React, { useState, useEffect } from 'react';
import { Button, message } from 'antd';
import { autoSegment } from '@services/apiService';
import './DoodleSelector.css'; // 引入自定义样式

export const DoodleSelector = ({ onModeChange, src, setSegResult, showBubble }) => {
  const [selectedMode, setSelectedMode] = useState(null);
  const [isRequesting, setIsRequesting] = useState(false);

  useEffect(() => {
    setSelectedMode(null); 
    onModeChange(null);
  }, [src]);

  const handleButtonClick = async (mode) => {
    if (!src) {
      showBubble('请先上传图片');
      return;
    }

    const isSameMode = selectedMode === mode;
    const newMode = isSameMode ? null : mode;

    setSelectedMode(newMode);
    onModeChange(newMode);

    if (mode === 'auto' && !isSameMode) {
      const payload = { image: src };
      try {
        showBubble('请稍等，正在分割中！');
        const masks = await autoSegment(payload);
        setSegResult(masks);
        showBubble('恭喜您！自动分割成功！');
      } catch (error) {
        showBubble('自动分割失败，请稍候再试！');
        console.error('Error in autoSegment:', error);
      }
    }
  };

  return (
    <div className="doodle-selector">
      <Button
        onClick={() => handleButtonClick('manual')}
        className={`doodle-button ${selectedMode === 'manual' ? 'active' : ''}`}
      >
        手动涂鸦
      </Button>
      <Button
        onClick={() => handleButtonClick('auto')}
        className={`doodle-button ${selectedMode === 'auto' ? 'active' : ''}`}
      >
        自动选择
      </Button>
    </div>
  );
};

import React from 'react';
import './memberList.css';

const MemberList = ({
  members,
  selectedOrg,
  isAddingMember,
  newMemberData,
  setNewMemberData,
  handleDeleteMember,
  handleSaveNewMember,
  handleCancelAddMember,
  openEditModal,
  handleAddMemberClick,
}) => {
  return (
    <div className="member-list">
      <h2>{selectedOrg?.name}</h2>
      <ul>
        {members.map((member) => (
          <li key={member.uuid} className="member-item">
            <div className="member-details">
              <span>
                <strong>用户名:</strong> {member.name}
              </span>
              <span>
                <strong>UUID:</strong> {member.uuid}
              </span>
              <span>
                <strong>邮箱:</strong> {member.email}
              </span>
              <span>
                <strong>加入时间:</strong> {new Date(member.joined_at).toLocaleDateString()}
              </span>
              <span>
                <strong>角色:</strong> {member.is_admin ? '管理员' : '成员'}
              </span>
            </div>
            <div className="member-actions">
              {selectedOrg.is_admin && !member.is_admin ? (
                <>
                  <button
                    className="edit-member-button"
                    onClick={() => openEditModal(member)}
                  >
                    修改
                  </button>
                  <button
                    className="delete-member-button"
                    onClick={() => handleDeleteMember(member.uuid)}
                  >
                    删除
                  </button>
                </>
              ) : (
                <button
                  className="delete-member-button disabled"
                  disabled
                  title="您无法修改或删除管理员"
                >
                  禁止
                </button>
              )}
            </div>
          </li>
        ))}
        {isAddingMember && (
          <li className="member-item adding-member-item">
            <div className="member-details">
              <input
                type="text"
                placeholder="用户名"
                value={newMemberData.username}
                onChange={(e) =>
                  setNewMemberData({ ...newMemberData, username: e.target.value })
                }
                autoComplete="off"
              />
              <input
                type="email"
                placeholder="邮箱"
                value={newMemberData.email}
                onChange={(e) =>
                  setNewMemberData({ ...newMemberData, email: e.target.value })
                }
                autoComplete="off"
              />
              <input
                type="password"
                placeholder="密码"
                value={newMemberData.password}
                onChange={(e) =>
                  setNewMemberData({ ...newMemberData, password: e.target.value })
                }
                autoComplete="new-password"
              />
            </div>
            <div className="member-actions">
              <button className="save-member-button" onClick={handleSaveNewMember}>
                保存
              </button>
              <button className="cancel-member-button" onClick={handleCancelAddMember}>
                取消
              </button>
            </div>
          </li>
        )}
      </ul>
    </div>
  );
};

export default MemberList;

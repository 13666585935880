import React, { useState, useEffect } from 'react';
import { Modal, Button, message, Row, Col } from 'antd';
import { LikeOutlined, DislikeOutlined, CloseOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { sendifLike, sendImageFeedback } from '@services/apiService';
import '@styles/ImageDisplay.css';
import ImageDetail from '@components/ImageDetail';

function ImageDisplay({ imageDetails, imageBatch }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLiked, setIsLiked] = useState(imageDetails?.fit);
  const [selectedFeedback, setSelectedFeedback] = useState([]);
  const [isLikeVisible, setIsLikeVisible] = useState(true);
  const [currentImage, setCurrentImage] = useState(imageDetails?.fullUrl || 'https://via.placeholder.com/150');
  const [imageBatchImages, setImageBatchImages] = useState([]);
  const [selectedThumbnail, setSelectedThumbnail] = useState(currentImage);
  const [selectedImageDetail, setSelectedImageDetail] = useState(null);

  const feedbackOptions = [
    { label: '类型不符', value: '类型不符' },
    { label: '部位有错', value: '部位有错' },
    { label: '模糊', value: '模糊' },
    { label: '色彩不佳', value: '色彩不佳' },
    { label: '构图混乱', value: '构图混乱' },
  ];

  useEffect(() => {
    if (imageBatch?.images) {
      setImageBatchImages(imageBatch.images);
    } else {
      setImageBatchImages([]);
    }
    setIsLikeVisible(true);
    setIsLiked(imageDetails?.fit);
    setCurrentImage(imageDetails?.fullUrl);
  }, [imageDetails, imageBatch]);

  const handleLikeDislike = async (type) => {
    if ((type === 'like' && isLiked) || (type === 'dislike' && !isLiked)) {
      return;
    }

    try {
      await sendifLike(imageDetails.uuid);
      message.success(`${type === 'like' ? '点赞' : '踩'}成功!`);
      setIsLiked(type === 'like');
    } catch (error) {
      message.error(`${type === 'like' ? '点赞' : '踩'}失败!`);
    }

    if (type === 'dislike') {
      setIsModalVisible(true);
    }
  };

  const handleFeedbackClick = async (value) => {
    const newFeedback = selectedFeedback.includes(value)
      ? selectedFeedback.filter((item) => item !== value)
      : [...selectedFeedback, value];

    setSelectedFeedback(newFeedback);

    const feedbackString = newFeedback.join(',');
    try {
      await sendImageFeedback(imageDetails.uuid, feedbackString);
      message.success('反馈提交成功！');
    } catch (error) {
      message.error('反馈提交失败！');
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setSelectedFeedback([]);
  };

  const handleCloseLike = () => {
    setIsLikeVisible(false);
  };

  const handleThumbnailClick = (url) => {
    setCurrentImage(url);
    setSelectedThumbnail(url);
  };

  const handleCloseDetail = () => {
    setSelectedImageDetail(null);
  };

  const handleDisplayClick = (url) => {
    setSelectedImageDetail(url);
  };

  return (
    <div className="image-display-container">
      {imageBatch?.images?.length ? (
        <>
          <div className='image-display-screen'>
            <img
              src={currentImage}
              alt="Displayed"
              className="image-display-thumbnail"
              onClick={() => handleDisplayClick(currentImage)}
            />
          </div>
          <div className="thumbnail-container">
            {imageBatch.images.map((image, index) => (
              <img
                key={index}
                src={image.thumbUrl || image.fullUrl}
                alt={`Thumbnail ${index + 1}`}
                className={`thumbnail-image ${selectedThumbnail === image.fullUrl ? 'thumbnail-selected' : ''}`}
                onClick={() => handleThumbnailClick(image.fullUrl)}
              />
            ))}
          </div>
        </>
      ) : (
        <div className="placeholder">
          <p>没有可显示的图像</p>
        </div>
      )}
      {selectedImageDetail && (
        <ImageDetail
          image={imageDetails}
          batchImages={imageBatchImages}
          onClose={handleCloseDetail}
        />
      )}
      {imageDetails?.uuid && isLikeVisible && (
        <div className="like-container">
          <Button
            icon={<LikeOutlined />}
            onClick={() => handleLikeDislike('like')}
            disabled={isLiked}
            style={{ marginRight: '10px' }}
          >
            赞
          </Button>
          <Button
            icon={<DislikeOutlined />}
            onClick={() => handleLikeDislike('dislike')}
            disabled={!isLiked}
          >
            踩
          </Button>
          <Button
            icon={<CloseOutlined />}
            onClick={handleCloseLike}
            type="text"
            className="close-button"
          />
        </div>
      )}

      <Modal
        title="选择反馈类型"
        visible={isModalVisible}
        onCancel={handleModalCancel}
        footer={null}
      >
        <Row gutter={[16, 16]}>
          {feedbackOptions.map((option) => (
            <Col span={8} key={option.value}>
              <Button
                type={selectedFeedback.includes(option.value) ? 'primary' : 'default'}
                onClick={() => handleFeedbackClick(option.value)}
                style={{ marginBottom: '10px', width: '100%' }}
              >
                {option.label}
              </Button>
            </Col>
          ))}
        </Row>
      </Modal>
    </div>
  );
}

ImageDisplay.propTypes = {
  imageDetails: PropTypes.shape({
    uuid: PropTypes.string,
    fullUrl: PropTypes.string.isRequired,
    fit: PropTypes.bool,
  }),
  imageBatch: PropTypes.shape({
    images: PropTypes.arrayOf(
      PropTypes.shape({
        fullUrl: PropTypes.string.isRequired,
        thumbUrl: PropTypes.string,
      })
    ).isRequired,
  }).isRequired,
};

ImageDisplay.defaultProps = {
  imageDetails: { fullUrl: 'https://via.placeholder.com/150', uuid: '', fit: false },
};

export default ImageDisplay;

// src/components/History.js

import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import '@styles/History.css';
import { Pagination, Spin, Select, message } from 'antd';
import { debounce } from 'lodash';
import ImageDetail from '@components/ImageDetail';
import { StarFilled, StarOutlined } from '@ant-design/icons';
import { toggleFavorite } from '@services/apiService';
import useImageGallery from '@hooks/useImageGallery';
import { AuthContext } from '@contexts/AuthContext';

const { Option } = Select;

const History = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedBatchImages, setSelectedBatchImages] = useState([]);
  const { token, isAuthenticated, currentFunc, setCurrentFunc } = useContext(AuthContext);
  const imageGalleryHook = useImageGallery();

  const {
    imagesByBatch,
    loading,
    currentPage,
    totalItems,
    pageSize,
    loadHistory,
    setImagesByBatch,
  } = imageGalleryHook;

  useEffect(() => {
    setCurrentFunc("all");
    loadHistory(1, pageSize, "all");
  }, []);

  useEffect(() => {
    if (token) {
      loadHistory(1, pageSize, currentFunc);
    }
  }, [token]);
  

  const handlePageChange = useCallback(
    (page, pageSize) => {
      loadHistory(page, pageSize, currentFunc);
    },
    [loadHistory, currentFunc]
  );

  // 防抖处理
  const handlePageChangeDebounced = useMemo(
    () => debounce(handlePageChange, 300),
    [handlePageChange]
  );

  // 处理图片选择
  const handleImageSelect = (img, batchImages) => {
    setSelectedImage(img);
    setSelectedBatchImages(batchImages);
  };

  // 关闭详情
  const handleCloseDetail = () => {
    setSelectedImage(null);
    setSelectedBatchImages([]);
  };

  // 处理功能选择变化
  const handleFuncChange = (value) => {
    loadHistory(1, pageSize, value);
  };

  // 处理收藏点击
  const handleFavoriteClick = async (request_id) => {
    try {
      await toggleFavorite(request_id);
      message.success('收藏状态已更新');

      // 更新 imagesByBatch 中的 favorite 状态
      const updatedImagesByBatch = imagesByBatch.map((batch) => {
        if (batch.request_id === request_id) {
          return {
            ...batch,
            favorite: !batch.favorite,
          };
        }
        return batch;
      });

      setImagesByBatch(updatedImagesByBatch);
    } catch (error) {
      message.error('收藏操作失败');
    }
  };

  // 未登录提示
  if (!token) {
    return (
      <div className="login-prompt-container">
        <div className="login-prompt">
          <h2>请登录查看历史</h2>
        </div>
        <div className="blur-background" />
      </div>
    );
  }

  return (
    <div className="history-image-gallery-container">
      <div className="history-top-controls">
        <Select
          value={currentFunc}
          onChange={handleFuncChange}
          style={{ width: 200 }}
          className="transparent-select"
        >
          <Option value="all">全部</Option>
        </Select>

        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={totalItems}
          onChange={handlePageChangeDebounced}
          style={{ marginLeft: '20px', color: 'white' }}
        />
      </div>

      {loading ? (
        <div className="loading-container">
          <Spin />
        </div>
      ) : (
        imagesByBatch.map((batch, batchIndex) => (
          <div
            key={batchIndex}
            className={`history-batch-container ${
              batch.status === 'queueing' || batch.status === 'processing' ? 'blurred' : ''
            }`}
          >
            <h3 className="history-batch-date">
              {batch.request_type} {batch.date}
            </h3>
            {batch.status === 'queueing' || batch.status === 'processing' ? (
              <div className="processing-overlay">
                <span>
                  {batch.status === 'queueing'
                    ? '排队等待中......'
                    : 'AI正在加紧绘制！预计需要10s！'}
                </span>
              </div>
            ) : (
              <div className="history-image-gallery">
                {batch.images.map((img, index) => (
                  <div
                    className="history-image-container"
                    key={index}
                    onClick={() => handleImageSelect(img, batch.images)}
                  >
                    <img src={img.thumbUrl} alt={`图片-${index}`} loading="lazy" />
                    {img.seed ? (
                      <div className="history-seed-overlay">Seed: {img.seed}</div>
                    ) : null}
                  </div>
                ))}
                {/* 收藏按钮，位于每行图片的最后 */}
                <div
                  className="favorite-button"
                  onClick={() => handleFavoriteClick(batch.request_id)}
                >
                  {batch.favorite ? (
                    <StarFilled style={{ color: 'yellow', fontSize: '24px' }} />
                  ) : (
                    <StarOutlined style={{ color: 'white', fontSize: '24px' }} />
                  )}
                </div>
              </div>
            )}
          </div>
        ))
      )}

      <div className="history-bottom-pagination-container">
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={totalItems}
          onChange={handlePageChangeDebounced}
          style={{ color: 'white' }}
        />
      </div>

      {selectedImage && (
        <ImageDetail
          image={selectedImage}
          batchImages={selectedBatchImages}
          onClose={handleCloseDetail}
        />
      )}
    </div>
  );
};

export default History;

import axios from 'axios';
import { API_ENDPOINTS } from '@configs/api';

export const verifyToken = async (token) => {
    try {
      const response = await axios.post(API_ENDPOINTS.verifyToken, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Token verification failed', error);
      return false;
    }
  };

export const refreshToken = async () => {
  try {
    const response = await axios.get(API_ENDPOINTS.refreshToken, {
      withCredentials: true
    });

    if (response.data) {
      return response.data;
    } else {
      console.error('No access token returned from server');
      return null;
    }
  } catch (error) {
    console.error('Token refresh failed', error);
    return null;
  }
};

  

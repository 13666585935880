const categories = {
    common: {

    },
    specific: {
        "拱桥v1": {
            "桥梁规模": {
                "大桥": "large bridge",
                "中桥": "medium bridge",
                "小桥": "small bridge"
            },
            "视角": {
                "人视": "human perspective",
                "鸟瞰": "bird's-eye view",
                "车行": "vehicular view"
            },
            "灯光": {
                "日景": "day",
                "夜景": "night"
            },
            "拱体系": {
                "上承式拱桥": "deck arch bridge",
                "中承式拱桥": "half-through arch bridge",
                "下承式拱桥": "through arch bridge",
                "上下承式拱桥": "deck and through arch bridge",
                "中下承式拱桥": "half-through and through arch bridge"
            },
            "孔样式": {
                "单孔": "single span",
                "多孔连拱": "multiple span arch",
                "飞燕式": "swallowtail arch",
                "两孔飞燕式": "double swallowtail arch",
                "三角孔": "triangular span"
            },
            "拱圈线型": {
                "半圆形": "semi-circular",
                "圆弧形": "circular arc",
                "椭圆形": "elliptical",
                "悬链线形": "catenary",
                "马蹄形": "horseshoe",
                "尖拱形": "pointed arch",
                "梯形": "trapezoidal",
                "异形线": "irregular"
            },
            "拱特征": {
                "敞肩石拱": "open spandrel stone arch",
                "实肩石拱": "closed spandrel stone arch",
                "敞肩砼拱": "open spandrel concrete arch",
                "实肩砼拱": "closed spandrel concrete arch",
                "异形钢拱": "irregular steel arch",
                "钢肋拱": "steel rib arch",
                "钢桁架拱": "steel truss arch",
                "钢管拱": "steel pipe arch",
                "砼刚架拱": "concrete rigid frame arch",
                "砼双曲拱": "concrete hyperbolic arch",
                "木拱": "wooden arch",
                "钢砼架拱": "steel-concrete frame arch",
                "钢刚架拱": "steel rigid frame arch",
                "敞肩钢桁拱": "open spandrel steel truss arch",
                "敞肩钢肋拱": "open spandrel steel rib arch"
            },
            "桁索特征": {
                "网状索": "mesh cables",
                "平行索": "parallel cables",
                "空间索": "spatial cables",
                "扇面索": "fan cables",
                "钢立柱": "steel pillars",
                "钢吊杆": "steel hangers",
                "砼立柱": "concrete pillars",
                "砼横墙": "concrete cross walls",
                "石横墙": "stone cross walls",
                "砖横墙": "brick cross walls",
                "钢桁吊杆": "steel truss hangers"
            },
            "墩梁特征": {
                "钢梁": "steel beam",
                "砼梁": "concrete beam",
                "钢桁架梁": "steel truss beam",
                "薄墩薄拱": "thin pier thin arch",
                "薄墩厚拱": "thin pier thick arch",
                "厚墩厚拱": "thick pier thick arch",
                "厚墩薄拱": "thick pier thin arch",
                "砼钢架梁": "concrete steel frame beam"
            },
            "附属": {
                "桥头堡": "bridgehead",
                "栏杆": "railing",
                "路灯": "street lamp",
                "路面": "road surface",
                "桥廊": "covered bridge",
                "台阶": "stairs",
                "高架人行道": "viaduct pedestrian walkway",
                "架空线": "overhead line",
                "架空线门架": "overhead line portal",
            }
        },
        "人行天桥": {
            "视角": {
                "人视": "human perspective",
                "车行": "driving perspective",
                "鸟瞰": "bird's-eye view"
            },
            "场景": {
                "日景": "daytime",
                "夜景": "nighttime",
                "黄昏": "dusk"
            },
            "环境": {
                "城市": "urban",
                "郊野": "suburban",
                "海滨": "coastal"
            },
            "风格": {
                "现代": "modern style",
                "中式": "chinese style",
                "欧式": "european style"
            },
            "结构形式": {
                "悬索": "suspension",
                "斜拉": "cable-stayed",
                "拱": "arch",
                "梁式": "beam",
                "桁架": "truss"
            },
            "高度": {
                "4米": "4 meters",
                "6米": "6 meters",
                "8米": "8 meters",
                "10米": "10 meters"
            },
            "材料": {
                "钢主梁": "steel girder",
                "混凝土主梁": "concrete girder"
            },
            "梁型": {
                "钢箱梁": "steel box girder",
                "混凝土箱梁": "concrete box girder",
                "鱼腹梁": "fishbelly girder",
                "桁架梁": "truss girder",
                "小箱梁": "small box girder",
                "空心板": "hollow slab",
                "双层车道": "double deck lanes"
            },
            "下部结构": {
                "墙式墩": "wall pier",
                "柱式墩-圆柱": "round column pier",
                "柱式墩-方柱": "square column pier",
                "柱式墩-花瓶": "vase column pier",
                "异型桁架": "special-shaped truss",
                "柔性排架桩墩": "flexible pile pier",
                "异型墩": "special-shaped pier"
            },
            "附属": {
                "栏杆": "guardrail",
                "路灯": "streetlight",
                "桥头堡": "bridge abutment",
                "车道线": "lane marking",
                "顶棚": "canopy",
                "电梯": "elevator"
            },
        },
        "拱桥v2":{
        "桥梁主跨": {
            "特大桥（Lk>150）": "extra-large bridge",
            "大桥（40≤Lk≤150）": "large bridge",
            "中桥（20≤Lk<40）": "medium bridge",
            "小桥（5≤Lk<20）": "small bridge"
        },
        "视角": {
            "鸟瞰": "bird's-eye view"
        },
        "场景": {
            "日景": "daytime",
            "夜景": "nighttime",
            "黄昏": "dusk"
        },
        "环境": {
            "城市": "urban",
            "郊野": "suburban",
            "海滨": "coastal"
        },
        "风格": {
            "现代": "modern style",
            "中式": "chinese style",
            "欧式": "european style"
        },
        "桥面空间位置": {
            "上承式": "deck arch",
            "中承式": "half-through arch",
            "下承式": "through arch"
        },
        "孔数": {
            "单孔": "single span",
            "双孔": "double span",
            "多孔": "multiple span"
        },
        "拱轴线形状": {
            "圆弧形": "circular arch",
            "抛物线形": "parabolic arch",
            "悬链线形": "catenary arch"
        },
        "拉索立面布置": {
            "平行索面": "parallel cable plane",
            "扇形索面": "fan cable plane",
            "空间索面": "spatial cable plane",
            "网状索面": "network cable plane"
        },
        "梁型": {
            "钢箱梁": "steel box girder",
            "混凝土箱梁": "concrete box girder",
            "鱼腹梁": "fishbelly girder",
            "桁架梁": "truss girder",
            "小箱梁": "small box girder",
            "空心板": "hollow slab",
            "双层车道": "double deck lanes"
        },
        "下部结构": {
            "墙式墩": "wall pier",
            "柱式墩-圆柱": "column pier - round",
            "柱式墩-方柱": "column pier - square",
            "柱式墩-花瓶": "column pier - vase",
            "异型桁架": "special-shaped truss",
            "柔性排架桩墩": "flexible pile pier",
            "异型墩": "special-shaped pier"
        },
        "附属": {
            "栏杆": "guardrail",
            "路灯": "streetlight",
            "桥头堡": "bridge abutment",
            "车道线": "lane marking"
        }
            },
        "斜拉桥": {
            "桥梁主跨": {
        "特大桥（Lk>150）": "extra-large bridge",
        "大桥（40≤Lk≤150）": "large bridge",
        "中桥（20≤Lk<40）": "medium bridge",
        "小桥（5≤Lk<20）": "small bridge"
    },
    "索塔规模": {
        "单塔": "single-tower",
        "双塔": "double-tower",
        "多塔": "multi-tower"
    },
    "车道规模": {
        "双向2车道": "two lanes",
        "双向4车道": "four lanes",
        "双向6车道": "six lanes",
        "双向8车道": "eight lanes",
        "双向10车道": "ten lanes"
    },
    "视角": {
        "人视": "human perspective",
        "车行": "driving perspective",
        "鸟瞰": "bird's-eye view"
    },
    "场景": {
        "日景": "daytime",
        "夜景": "nighttime",
        "黄昏": "dusk"
    },
    "环境": {
        "城市": "urban",
        "郊野": "suburban",
        "海滨": "coastal"
    },
    "风格": {
        "现代": "modern style",
        "中式": "chinese style",
        "欧式": "european style"
    },
    "索塔形式": {
        "独柱式": "single-column",
        "双柱式": "double-column",
        "门式": "portal frame",
        "A型": "A-shape",
        "宝石式": "gem shape",
        "倒Y式": "inverted Y-shape",
        "异型": "special shape"
    },
    "拉索形式": {
        "单索面": "single cable plane",
        "斜向双索面": "inclined double cable plane",
        "竖向双索面": "vertical double cable plane",
        "无背索": "backless cable"
    },
    "拉索立面布置形状": {
        "扇式": "fan",
        "辐射式": "radial",
        "竖琴式": "harp",
        "空间索": "space"
    },
    "梁型": {
        "钢箱梁": "steel box girder",
        "混凝土箱梁": "concrete box girder",
        "鱼腹梁": "fishbelly girder",
        "桁架梁": "truss girder",
        "双层车道": "double deck lanes"
    },
    "下部结构": {
        "墙式墩": "wall pier",
        "柱式墩-圆柱": "round column pier",
        "柱式墩-方柱": "square column pier",
        "柱式墩-花瓶": "vase column pier",
        "异型桁架": "special-shaped truss",
        "柔性排架桩墩": "flexible pile pier",
        "异型墩": "special-shaped pier"
    },
    "附属": {
        "栏杆": "guardrail",
        "路灯": "streetlight",
        "桥头堡": "bridge abutment",
        "车道线": "lane marking"
    }
            },
        "悬索桥": {
            "桥梁主跨": {
                "特大桥(主跨大于150米)": "extra-large bridge",
                "大桥(40≤主跨≤150)": "large bridge",
                "中桥(20≤主跨<40)": "medium bridge",
                "小桥(5≤主跨<20)": "small bridge"
            },
            "车道规模": {
                "双向2车道": "two lanes",
                "双向4车道": "four lanes",
                "双向6车道": "six lanes",
                "双向8车道": "eight lanes",
                "双向10车道": "ten lanes"
            },
            "索塔规模": {
                "单塔": "single-tower",
                "双塔": "double-tower",
                "多塔": "multi-tower"
            },
            "视角": {
                "人视": "human perspective",
                "车行": "driving perspective",
                "鸟瞰": "bird's-eye view"
            },
            "场景": {
                "日景": "daytime",
                "夜景": "nighttime",
                "黄昏": "dusk"
            },
            "环境": {
                "城市": "urban",
                "郊野": "suburban",
                "海滨": "coastal"
            },
            "风格": {
                "现代": "modern style",
                "中式": "chinese style",
                "欧式": "european style"
            },
            "锚固方式": {
                "地锚": "ground anchor",
                "自锚": "self-anchored"
            },
            "悬吊方式": {
                "竖直吊索": "vertical suspension sling",
                "异型吊索": "special-shaped sling"
            },
            "索塔形式": {
                "单柱式": "single-column",
                "A型": "A-shape",
                "倒Y式": "inverted Y-shape",
                "双柱式": "double-column",
                "门式": "portal frame",
                "斜腿门式": "inclined-leg portal frame",
                "倒V式": "inverted V-shape",
                "异型": "special shape"
            },
            "梁型": {
                "钢箱梁": "steel box girder",
                "混凝土箱梁": "concrete box girder",
                "鱼腹梁": "fishbelly girder",
                "桁架梁": "truss girder",
                "双层车道": "double deck lanes"
            },
            "下部结构": {
                "墙式墩": "wall pier",
                "柱式墩-圆柱": "round column pier",
                "柱式墩-方柱": "square column pier",
                "柱式墩-花瓶": "vase column pier",
                "异型桁架": "special-shaped truss",
                "柔性排架桩墩": "flexible pile pier",
                "异型墩": "special-shaped pier"
            },
            "附属": {
                "栏杆": "guardrail",
                "路灯": "streetlight",
                "桥头堡": "bridge abutment",
                "车道线": "lane marking"
            }
        },
        // "悬索桥": {
        //     "桥梁规模": {
        //         "大桥": "large bridge",
        //         "中桥": "medium bridge",
        //         "小桥": "small bridge"
        //     },
        //     "视角": {
        //         "人视": "",
        //         "鸟瞰": "bird's-eye view",
        //         "车行": "vehicular view"
        //     },
        //     "灯光": {
        //         "日景": "day",
        //         "夜景": "night"
        //     },
        //     "悬索桥体系": {
        //         "重力锚": "gravity anchor",
        //         "嵌岩锚": "embedded rock anchor",
        //         "自锚": "self-anchored",
        //     },
        //     "加劲梁型": {
        //         "钢绗粱": "steel truss beam",
        //         "钢箱梁": "steel box beam",
        //         "砼箱/板粱": "concrete box/slab beam",
        //     },
        //     "孔跨布置": {
        //         "单跨": "single span arrangement",
        //         "三跨": "three spans arrangement",
        //         "两跨": "two spans arrangement",
        //         "多跨": "multiple spans arrangement",
        //     },
        //     "塔型": {
        //         "单柱": "single column",
        //         "双柱": "double column",
        //         "门型/H型": "portal-type/h-type",
        //         "H型塔": "h-type tower",
        //         "斜腿门型": "inclined leg portal type",
        //         "倒V型": "inverted v type",
        //         "倒Y型": "inverted y type",
        //         "A型塔": "a type tower",
        //         "异形塔": "special-shaped tower",
        //     },
        //     "主缆形式": {
        //         "双面平行": "double parallel main cable",
        //         "单面": "single side main cable",
        //         "空间": "spatial main cable",
        //     },
        //     "吊索布置": {
        //         "竖直": "vertical hanger",
        //         "竖斜": "inclined vertical hanger",
        //     },
        //     "附属": {
        //         "栏杆": "railing",
        //         "路灯": "street lamp",
        //         "车道线": "lane"
        //     }
        // },
        "梁桥": {
            "桥梁主跨": {
                "特大桥(主跨大于150米)": "extra-large bridge",
                "大桥(40≤主跨≤150)": "large bridge",
                "中桥(20≤主跨<40)": "medium bridge",
                "小桥(5≤主跨<20)": "small bridge"
            },
            "车道规模": {
                "双向2车道": "two lanes",
                "双向4车道": "four lanes",
                "双向6车道": "six lanes",
                "双向8车道": "eight lanes",
                "双向10车道": "ten lanes"
            },
            "视角": {
                "人视": "human perspective",
                "车行": "driving perspective",
                "鸟瞰": "bird's-eye view"
            },
            "场景": {
                "日景": "daytime",
                "夜景": "nighttime",
                "黄昏": "dusk"
            },
            "环境": {
                "城市": "urban",
                "郊野": "suburban",
                "海滨": "coastal"
            },
            "风格": {
                "现代": "modern style",
                "中式": "chinese style",
                "欧式": "european style"
            },
            "装饰材料": {
                "金属": "metal",
                "石材": "stone",
                "玻璃/塑料": "glass and plastic",
                "木质": "wood"
            },
            "主梁结构": {
                "等高简支/连续梁": "constant height simply supported and continuous beam",
                "变高连续梁": "variable height continuous beam",
                "刚构桥": "rigid frame bridge"
            },
            "下部结构": {
                "墙式墩": "wall pier",
                "柱式墩-圆柱": "round column pier",
                "柱式墩-方柱": "square column pier",
                "柱式墩-花瓶": "vase column pier",
                "异型桁架": "special-shaped truss",
                "柔性排架桩墩": "flexible pile pier",
                "异型墩": "special-shaped pier"
            },
            "附属": {
                "栏杆": "guardrail",
                "路灯": "streetlight",
                "桥头堡": "bridge abutment",
                "车道线": "lane marking"
            }
        },
        "桁架桥": {
            "桥梁主跨": {
                "特大桥(主跨大于150米)": "extra-large bridge",
                "大桥(40≤主跨≤150)": "large bridge",
                "中桥(20≤主跨<40)": "medium bridge",
                "小桥(5≤主跨<20)": "small bridge"
            },
            "车道规模": {
                "双向2车道": "two-way 2 lanes",
                "双向4车道": "two-way 4 lanes",
                "双向6车道": "two-way 6 lanes",
                "双向8车道": "two-way 8 lanes",
                "双向10车道": "two-way 10 lanes"
            },
            "视角": {
                "人视": "human perspective",
                "车行": "driving perspective",
                "鸟瞰": "bird's-eye view"
            },
            "场景": {
                "日景": "daytime",
                "夜景": "nighttime",
                "黄昏": "dusk"
            },
            "环境": {
                "城市": "urban",
                "郊野": "suburban",
                "海滨": "coastal"
            },
            "风格": {
                "现代": "modern style",
                "中式": "chinese style",
                "欧式": "european style"
            },
            "层数": {
                "单层": "single layer",
                "双层": "double layer"
            },
            "桁架形式": {
                "平面桁架": "plane truss",
                "异型桁架": "special-shaped truss"
            },
            "梁型": {
                "钢箱梁": "steel box girder",
                "混凝土箱梁": "concrete box girder",
                "鱼腹梁": "fishbelly girder",
                "桁架梁": "truss girder",
                "小箱梁": "small box girder",
                "空心板": "hollow slab",
                "双层车道": "double deck lanes"
            },
            "下部结构": {
                "墙式墩": "wall pier",
                "柱式墩-圆柱": "column pier - round",
                "柱式墩-方柱": "column pier - square",
                "柱式墩-花瓶": "column pier - vase",
                "异型桁架": "special-shaped truss",
                "柔性排架桩墩": "flexible pile pier",
                "异型墩": "special-shaped pier"
            },
            "附属": {
                "栏杆": "guardrail",
                "路灯": "streetlight",
                "桥头堡": "bridge abutment",
                "车道线": "lane marking"
            }
        },
        "人行桥": {
            "桥梁主跨": {
                "特大桥(主跨大于150米)": "extra-large bridge",
                "大桥(40≤主跨≤150)": "large bridge",
                "中桥(20≤主跨<40)": "medium bridge",
                "小桥(5≤主跨<20)": "small bridge"
            },
            "车道规模": {
                "双向2车道": "two lanes",
                "双向4车道": "four lanes",
                "双向6车道": "six lanes",
                "双向8车道": "eight lanes",
                "双向10车道": "ten lanes"
            },
            "视角": {
                "人视": "human perspective",
                "车行": "driving perspective",
                "鸟瞰": "bird's-eye view"
            },
            "场景": {
                "日景": "daytime",
                "夜景": "nighttime",
                "黄昏": "dusk"
            },
            "环境": {
                "城市": "urban",
                "郊野": "suburban",
                "海滨": "coastal"
            },
            "风格": {
                "现代": "modern style",
                "中式": "chinese style",
                "欧式": "european style"
            },
            "结构形式": {
                "悬索": "suspension",
                "斜拉": "cable-stayed",
                "拱": "arch",
                "梁式": "beam",
                "桁架": "truss"
            },
            "高度": {
                "4米": "4 meters",
                "6米": "6 meters",
                "8米": "8 meters",
                "10米": "10 meters"
            },
            "材料": {
                "钢主梁": "steel girder",
                "混凝土主梁": "concrete girder"
            },
            "梁型": {
                "钢箱梁": "steel box girder",
                "混凝土箱梁": "concrete box girder",
                "鱼腹梁": "fishbelly girder",
                "桁架梁": "truss girder",
                "小箱梁": "small box girder",
                "空心板": "hollow slab",
                "双层车道": "double deck lanes"
            },
            "下部结构": {
                "墙式墩": "wall pier",
                "柱式墩-圆柱": "round column pier",
                "柱式墩-方柱": "square column pier",
                "柱式墩-花瓶": "vase column pier",
                "异型桁架": "special-shaped truss",
                "柔性排架桩墩": "flexible pile pier",
                "异型墩": "special-shaped pier"
            },
            "附属": {
                "栏杆": "guardrail",
                "路灯": "streetlight",
                "桥头堡": "bridge abutment",
                "车道线": "lane marking",
                "顶棚": "canopy",
                "电梯": "elevator"
            },
        },
        // "混合桥型": {
        //         "桥梁规模": {
        //             "特大桥(主跨大于150米)": "extra-large bridge",
        //             "大桥(40≤主跨≤150)": "large bridge",
        //             "中桥(20≤主跨<40)": "medium bridge",
        //             "小桥(5≤主跨<20)": "small bridge"
        //         },
        //         "视角": {
        //             "人视": "human perspective",
        //             "车行": "driving perspective",
        //             "鸟瞰": "bird's-eye view"
        //         },
        //         "场景": {
        //             "日景": "daytime",
        //             "夜景": "nighttime",
        //             "黄昏": "dusk"
        //         },
        //         "环境": {
        //             "城市": "urban",
        //             "郊野": "suburban",
        //             "海滨": "coastal"
        //         },
        //         "风格": {
        //             "现代": "modern style",
        //             "中式": "chinese style",
        //             "欧式": "european style"
        //         }
        //     },
    },

    other: {
    },
};

export default categories;

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Entrance from '@pages/Entrance';
import Main from '@pages/Main';
import Self from '@pages/self/Self';
import ProtectedRoute from '@components/others/ProtectedRoute';
import { AuthProvider } from '@contexts/AuthContext';
import { BreadcrumbProvider } from '@contexts/BreadcrumbContext';
import NotFound from '@pages/NotFound'; // 假设你有一个 NotFound 组件

const App = () => {
    return (
        <Router>
            <AuthProvider>
                <BreadcrumbProvider>
                    <Routes>
                        <Route path="/entrance" element={<Entrance />} />
                        <Route path="/main/*" element={<Main />} />
                        <Route path="/self/*" element={<Self />} />
                        <Route path="/" element={<Entrance />} />
                        <Route path="*" element={<NotFound />} /> {/* 捕获未定义的路径 */}
                    </Routes>
                </BreadcrumbProvider>
            </AuthProvider>
        </Router>
    );
}

export default App;

const categories = {
    common: {
        通用:{
            "视角": {
                "人视": "",
                "鸟瞰": "bird's-eye view",
                "车行": "vehicular view"
            },
            "灯光": {
                "日景": "day",
                "夜景": "night"
            },
            "桥梁规模": {
                "大桥": "large bridge",
                "中桥": "medium bridge",
                "小桥": "small bridge"
            }
        }
    },
    specific: {
        
        "拱桥": {
            // "样式": {
            //     "古典": "classical",
            //     "现代": "modern",
            // },
            "拱体系": {
                "上承式拱桥": "deck arch bridge",
                "中承式拱桥": "half-through arch bridge",
                "下承式拱桥": "through arch bridge",
                "上下承式拱桥": "deck and through arch bridge",
                "中下承式拱桥": "half-through and through arch bridge"
            },
            "孔样式": {
                "单孔": "single span",
                "多孔连拱": "multiple span arch",
                "飞燕式": "swallowtail arch",
                "两孔飞燕式": "double swallowtail arch",
                "三角孔": "triangular span"
            },
            "拱圈线型": {
                "半圆形": "semi-circular",
                "圆弧形": "circular arc",
                "椭圆形": "elliptical",
                "悬链线形": "catenary",
                "马蹄形": "horseshoe",
                "尖拱形": "pointed arch",
                "梯形": "trapezoidal",
                "异形线": "irregular"
            },
            "拱特征": {
                "敞肩石拱": "open spandrel stone arch",
                "实肩石拱": "closed spandrel stone arch",
                "敞肩砼拱": "open spandrel concrete arch",
                "实肩砼拱": "closed spandrel concrete arch",
                "异形钢拱": "irregular steel arch",
                "钢肋拱": "steel rib arch",
                "钢桁架拱": "steel truss arch",
                "钢管拱": "steel pipe arch",
                "砼刚架拱": "concrete rigid frame arch",
                "砼双曲拱": "concrete hyperbolic arch",
                "木拱": "wooden arch",
                "钢砼架拱": "steel-concrete frame arch",
                "钢刚架拱": "steel rigid frame arch",
                "敞肩钢桁拱": "open spandrel steel truss arch",
                "敞肩钢肋拱": "open spandrel steel rib arch"
            },
            "桁索特征": {
                "网状索": "mesh cables",
                "平行索": "parallel cables",
                "空间索": "spatial cables",
                "扇面索": "fan cables",
                "钢立柱": "steel pillars",
                "钢吊杆": "steel hangers",
                "砼立柱": "concrete pillars",
                "砼横墙": "concrete cross walls",
                "石横墙": "stone cross walls",
                "砖横墙": "brick cross walls",
                "钢桁吊杆": "steel truss hangers"
            },
            "墩梁特征": {
                "钢梁": "steel beam",
                "砼梁": "concrete beam",
                "钢桁架梁": "steel truss beam",
                "薄墩薄拱": "thin pier thin arch",
                "薄墩厚拱": "thin pier thick arch",
                "厚墩厚拱": "thick pier thick arch",
                "厚墩薄拱": "thick pier thin arch",
                "砼钢架梁": "concrete steel frame beam"
            },
            "附属": {
                "桥头堡": "bridgehead",
                "栏杆": "railing",
                "路灯": "street lamp",
                "路面": "road surface",
                "桥廊": "covered bridge",
                "台阶": "stairs",
                "高架人行道": "viaduct pedestrian walkway",
                "架空线": "overhead line",
                "架空线门架": "overhead line portal",
            }
        },
        "斜拉桥": {
            "斜拉桥体系": {
                "单塔单索面": "single tower single cable surface",
                "单塔双索面": "single tower double cable surface",
                "双塔单索面": "double tower single cable surface",
                "双塔双索面": "double tower double cable surface",
                "多塔单索面": "multi tower single cable surface",
                "多塔双索面": "multi tower double cable surface",
                "多塔四索面": "multi tower four cable surface"
            },
            "梁型": {
                "斜腹钢箱": "inclined steel box beam",
                "矩形钢箱": "rectangular steel box beam",
                "钢桁架梁": "steel truss beam",
                "鱼腹砼梁": "fish belly concrete beam",
                "砼小箱梁": "concrete small box beam",
                "矩形砼梁": "rectangular concrete beam",
                "鱼腹钢箱梁": "fish-belly steel box beam",
                "拱形砼梁": "arch-shaped concrete beam"
            },
            "墩型": {
                "砼圆柱墩": "concrete cylindrical pier",
                "砼棱柱墩": "concrete prismatic pier",
                "砼花瓶墩": "concrete vase-shaped pier",
                "钢圆柱墩": "steel cylindrical pier",
                "钢棱柱墩": "steel prismatic pier",
                "砼棱台墩": "concrete prismoid pier",
                "钢圆台墩": "steel prismoid pier",
                "钢异形墩": "steel unusual-shaped pier",
                "砼异形墩": "concrete unusual-shaped pier"
            },
            "塔型": {
                "A形塔": "a-shaped tower",
                "门架形塔": "portal-shaped tower",
                "单柱形塔": "single pillar tower",
                "花瓶形塔": "vase-shaped tower",
                "钻石形塔": "diamond-shaped tower",
                "桅杆形塔": "mast-shaped tower",
                "拱形塔": "arch-shaped tower",
                "异形塔": "unusual-shape tower",
                "H形塔": "h-shaped tower"
            },
            "斜拉索面": {
                "平行索": "parallel cable",
                "扇面索": "flabelliform cable",
                "空间索": "space cable"
            },
            "附属": {
                "栏杆": "railing",
                "路灯": "street lamp",
                "车道线": "lane",
            }
        },
        "悬索桥": {
            "悬索桥体系": {
                "重力锚": "gravity anchor",
                "嵌岩锚": "embedded rock anchor",
                "自锚": "self-anchored",
            },
            "加劲梁型": {
                "钢绗粱": "steel truss beam",
                "钢箱梁": "steel box beam",
                "砼箱/板粱": "concrete box/slab beam",
            },
            "孔跨布置": {
                "单跨": "single span arrangement",
                "三跨": "three spans arrangement",
                "两跨": "two spans arrangement",
                "多跨": "multiple spans arrangement",
            },
            "塔型": {
                "单柱": "single column",
                "双柱": "double column",
                "门型/H型": "portal-type/h-type",
                "H型塔": "h-type tower",
                "斜腿门型": "inclined leg portal type",
                "倒V型": "inverted v type",
                "倒Y型": "inverted y type",
                "A型塔": "a type tower",
                "异形塔": "special-shaped tower",
            },
            "主缆形式": {
                "双面平行": "double parallel main cable",
                "单面": "single side main cable",
                "空间": "spatial main cable",
            },
            "吊索布置": {
                "竖直": "vertical hanger",
                "竖斜": "inclined vertical hanger",
            },
            "附属": {
                "栏杆": "railing",
                "路灯": "street lamp",
                "车道线": "lane"
            }
        }
    },
};

export default categories;

// QuickToolsIndex.js
import React, { useState } from 'react';
import './quickToolsIndex.css';
import { useBreadcrumb } from '@contexts/BreadcrumbContext';
import VideoModal from '@components/Assistant/VideoModal'; 

const QuickToolsIndex = () => {
    const { switchComponent } = useBreadcrumb();
    const [showVideoModal, setShowVideoModal] = useState(false);

    const tools = [
        { name: 'BridgeQuickPaint', label: '快速桥梁绘制', description: "桥梁标签生成", imagePath: 'https://backend.temp.fit/static/assert/T5.png' },
        { name: 'Inpaint', label: '局部修改工具', description: "桥梁局部涂抹修改", imagePath: 'https://backend.temp.fit/static/assert/T2.png' },
        { name: 'RealPaint', label: '实景生图工具', description: "现实场景生成", imagePath: 'https://backend.temp.fit/static/assert/T3.png' },
        // { name: 'Transtyle', label: '风格修改器', description: "桥梁背景风格一键修改", imagePath: 'https://backend.temp.fit/static/assert/T4.png' },
        { name: 'LineSketh', label: '线稿生图', description: "手稿一键成图", imagePath: 'https://backend.temp.fit/static/assert/T1.png' },
        { name: 'VideoTool', label: '演示', description: "视频演示功能", imagePath: 'https://backend.temp.fit/static/assert/S1.png' },
    ];

    const handleToolClick = (toolName) => {
        if (toolName === 'VideoTool') {
            setShowVideoModal(true);
        } else {
            switchComponent(toolName);
        }
    };

    const handleCloseModal = () => {
        setShowVideoModal(false);
    };

    return (
        <div className="quick-tools__container">
            {tools.map((tool) => (
                <div
                    key={tool.name}
                    className="quick-tools__item"
                    onClick={() => handleToolClick(tool.name)}
                >
                    <img
                        src={tool.imagePath}
                        alt={tool.label}
                        className="quick-tools__image"
                    />
                    <div className="quick-tools__label">{tool.label}</div>
                    <div className="quick-tools__description">{tool.description}</div>
                </div>
            ))}
            {showVideoModal && <VideoModal isOpen={showVideoModal} onClose={handleCloseModal} />}
        </div>
    );
};

export default QuickToolsIndex;

/* quickToolsIndex.css remains unchanged */

import React, { useRef, useEffect, useState } from 'react';
import '@styles/mask-editor/MaskEditor.css';

const MaskEditor = ({ src, canvasRef, isDrawingAllowed, segResults }) => {
  const [image, setImage] = useState(null);
  const [cursorSize, setCursorSize] = useState(36);
  const containerRef = useRef(null);
  const imgCanvasRef = useRef(null);
  const maskCanvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [historyIndex, setHistoryIndex] = useState(-1);
  const [canvasContent, setCanvasContent] = useState(null); 
  const [masks, setMasks] = useState([]);
  const [masksMatrixs, setMasksMatrixs] = useState([]);
  const [activeMaskIndices, setActiveMaskIndices] = useState([]);
  const [hoverMaskIndex, setHoverMaskIndex] = useState(null);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImage(img);
      adjustCanvasSize(img);
    };
    setMasks([]);
    setMasksMatrixs([]); 
  }, [src]);

  useEffect(() => {
    if(segResults == null){
      return;
    }   
    const promises = segResults.data.map(base64Img => convertBase64To2DArray(base64Img));
    Promise.all(promises).then(results => {
        const images = results.map(result => result.newBase64Img);
        const matrices = results.map(result => result.masksMatrix);
        setMasks(images);
        setMasksMatrixs(matrices); 
    });
  }, [segResults]);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (image) {
        requestAnimationFrame(() => adjustCanvasSize(image));
      }
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [image]);

  const convertBase64To2DArray = (base64Img) => {
    return new Promise((resolve) => {
        const img = new Image();
        img.src = `data:image/png;base64,${base64Img}`;
        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);

            const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            const data = imageData.data;
            const masksMatrix = [];

            for (let y = 0; y < canvas.height; y++) {
                const row = [];
                for (let x = 0; x < canvas.width; x++) {
                    const index = (y * canvas.width + x) * 4;
                    const r = data[index];

                    if (r === 255) {
                        // 将 (255, 255, 255, 1) 变成 (255, 0, 0, 0.4)
                        data[index] = 255;     // R
                        data[index + 1] = 0;   // G
                        data[index + 2] = 0;   // B
                        data[index + 3] = 255 * 0.3; // 半透明
                        row.push(true);
                    } else if (r === 0) {
                        // 将 (0, 0, 0, 1) 变成 (255, 255, 255, 0)
                        data[index] = 255;     // R
                        data[index + 1] = 255; // G
                        data[index + 2] = 255; // B
                        data[index + 3] = 0;   // 全透明
                        row.push(false); 
                    } else {
                        // 对于其他值，也可以选择是否记录，以下为示例：
                        row.push(false); // 记录为 false，或者根据实际情况调整
                    }
                }
                masksMatrix.push(row);
            }

            ctx.putImageData(imageData, 0, 0);
            const newBase64Img = canvas.toDataURL('image/png'); // 转换后的图片
            resolve({ newBase64Img, masksMatrix }); // 返回转换后的图片和矩阵
        };
    });
};

  const adjustCanvasSize = (img) => {
    if (canvasRef.current && imgCanvasRef.current && containerRef.current && maskCanvasRef.current) {
      const container = containerRef.current;
      const canvas = canvasRef.current;
      const maskCanvas = maskCanvasRef.current
      const imgCanvas = imgCanvasRef.current;

      const savedImageData = canvasContent || canvas.getContext('2d').getImageData(0, 0, canvas.width, canvas.height);
      setCanvasContent(savedImageData);

      const containerWidth = container.offsetWidth;
      const containerHeight = container.offsetHeight;

      const imgWidth = img.width;
      const imgHeight = img.height;

      const scale = Math.min(containerWidth / imgWidth, containerHeight / imgHeight);
      const offsetX = (containerWidth - imgWidth * scale) / 2;
      const offsetY = (containerHeight - imgHeight * scale) / 2;

      imgCanvas.width = imgWidth;
      imgCanvas.height = imgHeight;
      imgCanvas.style.width = `${imgWidth * scale}px`;
      imgCanvas.style.height = `${imgHeight * scale}px`;
      imgCanvas.style.marginLeft = `${offsetX}px`;
      imgCanvas.style.marginTop = `${offsetY}px`;

      maskCanvas.width = imgWidth;
      maskCanvas.height = imgHeight;
      maskCanvas.style.width = `${imgWidth * scale}px`;
      maskCanvas.style.height = `${imgHeight * scale}px`;
      maskCanvas.style.marginLeft = `${offsetX}px`;
      maskCanvas.style.marginTop = `${offsetY}px`;

      canvas.width = imgWidth;
      canvas.height = imgHeight;
      canvas.style.width = `${imgWidth * scale}px`;
      canvas.style.height = `${imgHeight * scale}px`;
      canvas.style.marginLeft = `${offsetX}px`;
      canvas.style.marginTop = `${offsetY}px`;

      const imgCtx = imgCanvas.getContext('2d');
      imgCtx.clearRect(0, 0, imgCanvas.width, imgCanvas.height);
      imgCtx.drawImage(img, 0, 0, imgWidth, imgHeight);

      const ctx = canvas.getContext('2d');
      if (savedImageData) {
        ctx.putImageData(savedImageData, 0, 0); 
      }
    }
  };

  let lastX = null;
  let lastY = null;

  const handleMouseDraw = (e) => {
    const canvas = canvasRef.current;
    const maskCanvas = maskCanvasRef.current;
    const maskCtx = maskCanvas.getContext('2d');
    const ctx = canvas.getContext('2d');
    const rect = canvas.getBoundingClientRect();
    const x = parseInt((e.clientX - rect.left) * (canvas.width / rect.width));
    const y = parseInt((e.clientY - rect.top) * (canvas.height / rect.height));

    let ifHover = false;

    setHoverMaskIndex(null);
    if (masksMatrixs.length > 0 && !isDrawingAllowed) {
      for (const [index, masksMatrix] of masksMatrixs.entries()) {
        if (masksMatrix[y] && masksMatrix[y][x] === true) {
          const maskImage = new Image();
          maskImage.src = masks[index]; // 使用原始的 base64 图像数据
          maskImage.onload = () => {
            maskCtx.clearRect(0, 0, maskCanvas.width, maskCanvas.height); // 清除之前的内容
            maskCtx.drawImage(maskImage, 0, 0, maskCanvas.width, maskCanvas.height); // 绘制新图像
          };
          ifHover = true;
          setHoverMaskIndex(index);
          break;
        }
      }
    }
    
   if(!ifHover){
    maskCtx.clearRect(0, 0, maskCanvas.width, maskCanvas.height);
   }
    if (!isDrawing || !isDrawingAllowed) return;
  
    if (lastX === null || lastY === null) {
      lastX = x;
      lastY = y;
    }
  
    const dist = Math.hypot(x - lastX, y - lastY);
    const angle = Math.atan2(y - lastY, x - lastX);
  
    const actualCursorSize = cursorSize * 2; // 将绘制区域的半径提高一倍
  
    for (let i = 0; i < dist; i++) {
      const newX = Math.round(lastX + Math.cos(angle) * i);
      const newY = Math.round(lastY + Math.sin(angle) * i);
  
      const imageData = ctx.getImageData(newX - actualCursorSize / 2, newY - actualCursorSize / 2, actualCursorSize, actualCursorSize);
      const data = imageData.data;
  
      for (let j = 0; j < data.length; j += 4) {
        const dx = j / 4 % actualCursorSize - actualCursorSize / 2;
        const dy = Math.floor(j / 4 / actualCursorSize) - actualCursorSize / 2;
        if (dx * dx + dy * dy <= (actualCursorSize / 2) * (actualCursorSize / 2)) {
          data[j] = 255;     
          data[j + 1] = 0; 
          data[j + 2] = 0; 
          data[j + 3] = 255 * 0.3;
        }
      }
  
      ctx.putImageData(imageData, newX - actualCursorSize / 2, newY - actualCursorSize / 2);
    }
  
    lastX = x;
    lastY = y;
  };

  const handleMouseDown = (e) => {
  const canvas = canvasRef.current;
  const ctx = canvas.getContext('2d');
  const maskCanvas = maskCanvasRef.current;
  const maskCtx = maskCanvas.getContext('2d');
  
  if (!isDrawingAllowed && hoverMaskIndex !== null) {
      if (activeMaskIndices.includes(hoverMaskIndex)) {
        // 取消合成
        const maskData = maskCtx.getImageData(0, 0, maskCanvas.width, maskCanvas.height).data;
        const canvasData = ctx.getImageData(0, 0, canvas.width, canvas.height);

        for (let i = 0; i < canvasData.data.length; i += 4) {
          // 如果 mask 的红色通道是 255 且透明度是 0.4
          if (maskData[i] === 255 && maskData[i + 1] === 0 && maskData[i + 2] === 0) {
            // 将画布对应像素透明化
            canvasData.data[i + 3] = 0;
          }
        }

        ctx.putImageData(canvasData, 0, 0);
        setActiveMaskIndices(activeMaskIndices.filter(index => index !== hoverMaskIndex)); // 从列表中移除
      } else {
        // 合成
        const maskData = maskCtx.getImageData(0, 0, maskCanvas.width, maskCanvas.height).data;
        const canvasData = ctx.getImageData(0, 0, canvas.width, canvas.height);

        for (let i = 0; i < canvasData.data.length; i += 4) {
          // 如果 mask 的红色通道是 255 且透明度是 0.4
          if (maskData[i] === 255 && maskData[i + 1] === 0 && maskData[i + 2] === 0) {
            // 将对应的 mask 像素合成到画布上
            canvasData.data[i] = 255;
            canvasData.data[i + 1] = 0;
            canvasData.data[i + 2] = 0;
            canvasData.data[i + 3] = 255 * 0.3;
          }
        }

        ctx.putImageData(canvasData, 0, 0);
        setActiveMaskIndices([...activeMaskIndices, hoverMaskIndex]); // 添加到已合成列表中
    }
  }else{
    if (!isDrawing) {
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const newHistoryData = historyData.slice(0, historyIndex + 1);
      setHistoryData([...newHistoryData, { imageData }]);
      setHistoryIndex(historyIndex + 1);
    }
    setIsDrawing(true);
    handleMouseDraw(e);
  }
  };
  
  
  const handleMouseLeave = () => {
    if (maskCanvasRef.current) {
      const maskCtx = maskCanvasRef.current.getContext('2d');
      maskCtx.clearRect(0, 0, maskCanvasRef.current.width, maskCanvasRef.current.height);
    }
    handleMouseUp();
  };

  const handleMouseUp = () => {
    if (isDrawing && isDrawingAllowed) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const newHistoryData = historyData.slice(0, historyIndex + 1);
      setHistoryData([...newHistoryData, { imageData }]);
      setHistoryIndex(historyIndex + 1);
      lastX = null;
      lastY = null;
    }
    setIsDrawing(false);
  };

  const handleUndo = () => {
    if (historyIndex > 0) {
      const newHistoryIndex = historyIndex - 1;
      setHistoryIndex(newHistoryIndex);
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      ctx.putImageData(historyData[newHistoryIndex].imageData, 0, 0);
    }
  };
  
  

  const handleRedo = () => {
    if (historyIndex < historyData.length - 1) {
      const newHistoryIndex = historyIndex + 1;
      setHistoryIndex(newHistoryIndex);
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      ctx.putImageData(historyData[newHistoryIndex].imageData, 0, 0);
    }
  };
  

  const handleReset = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    if (historyData.length > 0) {
      setHistoryIndex(0);
      ctx.putImageData(historyData[0].imageData, 0, 0);
    } else {
      ctx.clearRect(0, 0, canvas.width, canvas.height); // Optionally clear to blank state
    }
  };
  

  const createCursor = () => {
    if (!isDrawingAllowed) return 'default';

    const cursorCanvas = document.createElement('canvas');
    cursorCanvas.width = cursorSize;
    cursorCanvas.height = cursorSize;
    const ctx = cursorCanvas.getContext('2d');

    ctx.beginPath();
    ctx.arc(cursorSize / 2, cursorSize / 2, cursorSize / 2, 0, Math.PI * 2, true);
    ctx.fillStyle = 'rgba(255, 255, 255, 0.3)';
    ctx.fill();
    ctx.lineWidth = 1;
    ctx.strokeStyle = 'rgba(0, 0, 0, 0.3)';
    ctx.stroke();

    return cursorCanvas.toDataURL('image/png');
  };


  return (
    <div ref={containerRef} style={{ position: 'relative', width: '100%', height: '100%' }}>
      {isDrawingAllowed && (
    <div className="toolbar">
      <button onClick={handleUndo} className="icon-button">
        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <path d="M9 5l-7 7 7 7"></path>
          <path d="M22 12H9"></path>
        </svg>
      </button>
      <button onClick={handleRedo} className="icon-button">
        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <path d="M15 5l7 7-7 7"></path>
          <path d="M2 12h13"></path>
        </svg>
      </button>
      <button onClick={handleReset} className="icon-button">
        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <circle cx="12" cy="12" r="10"></circle>
          <path d="M12 6v6l4 2"></path>
        </svg>
      </button>
      <input
        type="range"
        min="10"
        max="64"
        value={cursorSize}
        onChange={(e) => setCursorSize(parseInt(e.target.value, 10))}
        className="slider"
        disabled={!isDrawingAllowed}
      />
    </div>
  )}
      <canvas
        ref={imgCanvasRef}
        style={{ position: 'absolute', top: 0, left: 0 }}
      />
      <canvas
        ref={maskCanvasRef}
        style={{ position: 'absolute', top: 0, left: 0, pointerEvents: 'none' }} // 蒙板图层
      />
      <canvas
        ref={canvasRef}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          cursor: `url(${createCursor()}) ${cursorSize / 2} ${cursorSize / 2}, auto`,
        }}
        onMouseMove={handleMouseDraw}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseLeave}
      />
    </div>
  );
};


const toMask = (canvas) => {
  if (!canvas) return '';

  const maskCanvas = document.createElement('canvas');
  maskCanvas.width = canvas.width;
  maskCanvas.height = canvas.height;
  const maskCtx = maskCanvas.getContext('2d');

  maskCtx.drawImage(canvas, 0, 0);

  const imageData = maskCtx.getImageData(0, 0, maskCanvas.width, maskCanvas.height);
  const data = imageData.data;

  for (let i = 0; i < data.length; i += 4) {
    if (data[i + 3] !== 0) {
      data[i] = 255;
      data[i + 1] = 255;
      data[i + 2] = 255;
      data[i + 3] = 255;
    } else {
      data[i] = 0;
      data[i + 1] = 0;
      data[i + 2] = 0;
      data[i + 3] = 0;
    }
  }

  maskCtx.putImageData(imageData, 0, 0);

  return maskCanvas.toDataURL('image/png');
};

export { MaskEditor, toMask };

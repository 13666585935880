import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Button } from 'antd';
import "./policy.css"
const PrivacyPolicy = ({ onClose }) => {
    const privacyPolicyContent = `
# 隐私政策

## City AI 隐私政策

**发布（生效）日期：2024年11月22日**

欢迎您使用City AI 产品与服务！

上海智玖城智能科技有限公司（以下称“我们”或“City AI”）非常重视用户（以下简称“您”）的隐私和个人信息保护。您在使用我们的产品与/或服务时，我们可能会收集和使用您的相关个人信息。我们通过本隐私政策向您说明City AI 在提供产品与/或服务时如何收集、使用、保存、共享和转让您的个人信息、您享用的个人信息用户权利以及City AI 如何保障您的个人信息安全。

本隐私政策与您所使用的City AI 以及该服务所包括的各种业务功能（以下统称“我们的产品与/或服务”）息息相关，希望您在使用我们的产品与/或服务前仔细阅读并确认您已经充分知悉并理解本政策所陈述的内容，并让您可以按照本隐私政策的指引做出您认为适当的选择。一旦您开始使用或在我们更新本隐私政策后继续使用我们的产品与/或服务，即表示您已充分理解并同意本政策(含更新版本)内容，并且同意我们按照本隐私政策收集、使用、保存和共享您的相关信息。

本隐私政策将帮助您了解以下内容：

1. [本隐私政策适用范围](#一、本隐私政策适用范围)
2. [我们如何收集和使用您的个人信息](#二、我们如何收集和使用您的个人信息)
3. [我们如何使用 Cookie 和同类技术](#三、我们如何使用-和同类技术)
4. [我们如何共享、转让、公开披露您的个人信息](#四、我们如何共享、转让、公开披露您的个人信息)
5. [我们如何保护和保存您的个人信息](#五、我们如何保护和保存您的个人信息)
6. [您如何管理个人信息](#六、您如何管理个人信息)
7. [未成年人信息的保护](#七、未成年人信息的保护)
8. [本政策如何更新](#八、本政策如何更新)
9. [如何联系我们](#九、如何联系我们)

---

## 一、本隐私政策适用范围

1. **适用范围**  
   本隐私政策适用于您通过City AI 网页、供第三方网站和应用程序使用的City AI 软件开发工具包（SDK）和应用程序编程接口（API）方式来访问和使用我们的产品和服务，但如该产品与/或服务单独设置了隐私条款/隐私协议的，单独的隐私条款/隐私协议优先适用，单独的隐私条款/隐私协议未提及的内容，适用本政策。

2. **不适用情况**  
   本政策不适用于以下情况：
   1. 我们的产品与/或服务中包含的或链接至第三方提供的信息与/或第三方服务（包括任何第三方应用、网站、产品、服务等），这些信息与/或服务由第三方负责运营，您使用该信息与/或服务适用第三方另行向您说明的个人信息收集、使用的规则。
   2. 其他非City AI 向您提供的产品与/或服务内容。

## 二、我们如何收集和使用您的个人信息

我们会根据合法正当、最小必要、公开透明的原则，基于本政策所述的目的，收集和使用您的个人信息。如果我们将您的个人信息用于本政策未载明的其他用途，或基于其他特定目的而收集和使用您的个人信息，我们将以合理的方式（包括但不限于通过更新本政策、重新签署文件、页面提示、弹窗、站内信、邮件、网站公告或其他便于您获知的方式）另行向您详细说明对应信息的收集、使用目的、方式、范围等规则，在征得您授权同意后收集和使用。

通讯录、地理位置、相机、麦克风、相册等系统权限，均不会默认开启，只有经过您的明示授权才会在为实现特定功能/服务时访问，您可以随时撤回授权。特别需要指出的是，即使经过您的授权，可以访问您的相应权限，我们仅会在您的授权范围内进行访问；且如您拒绝提供的，仅会使您无法使用相应功能，但并不影响您正常使用产品与/或服务的其他功能。

### （一）使用City AI 产品或服务所必须的功能

1. **账号注册信息**  
   当您首次注册City AI 账号时，您需要提供您实名认证的手机号码并创建登录密码，上述信息为您注册City AI 账号所必需，若您拒绝提供这类信息，您将无法注册City AI 平台账户，仅可以使用浏览、搜索服务。当您成功注册账号后，您可以通过手机号码和短信验证码登录City AI 产品或服务，也可通过手机号码和密码登录City AI 产品或服务。

2. **搜索**  
   当您使用搜索服务时，我们会收集您的搜索关键字信息、日志信息记录进行分析以便向您展现相关程度更高的搜索结果。我们还可能会记录您在使用我们服务时提供、形成或留存的信息，如您在使用搜索时输入查询词，点击历史词、提示词和点击的链接。您有权拒绝提供上述信息，但这将导致您可能无法使用特定服务或功能，或者无法达到相关服务拟达到的效果。

### （二）向您提供产品或服务

当您使用City AI 服务时，为了维护我们服务的正常运行，改进及优化我们的服务体验以及保障您的账号安全，我们会收集您下述信息：

1. **设备信息**  
   我们会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息（包括设备型号、操作系统版本、IP地址、软件版本号等软硬件特征信息）；我们会基于提供更便捷、更优的服务和体验收集您的某些设备权限。

2. **日志信息**  
   我们会收集您对我们服务的详细使用情况，作为有关日志保存，包括接入网络的方式、类型和状态、网络质量数据、操作日志、服务日志信息。

3. **客服与活动信息**  
   当您通过我们的客服或参加我们举办的活动时所提交的个人信息：当您与我们联系时，我们可能会保存您的通信/通话记录和内容或您留下的联系方式等信息，以便与您取得联系或帮助您解决/定位问题，或记录相关问题的处理方案及结果。

### （三）其他用途

如果我们基于本政策未载明的其他特定目的收集您的个人信息时，会事先征求您的授权同意。我们收集及使用上述信息的目的是为了更好地经营City AI 产品和服务，如有更新我们会通过包括但不限于通过网站公告、站内信、电话或短信等方式通知您。

如我们停止运营City AI 产品或服务，我们将及时停止继续收集您个人信息的活动，将停止运营的通知在至少30日内以逐一送达或通过公告的形式告知您，并对所持有的个人信息进行删除或匿名化处理。涉及儿童个人信息的，我们会并将停止运营的通知及时告知儿童监护人。

### （四）授权同意的例外

您充分知晓，根据相关法律法规的要求以下情形中，我们收集、使用您的个人信息无需征得您的授权同意：

1. 与个人信息控制者履行法律法规规定的义务相关的；
2. 与国家安全、国防安全直接相关的；
3. 与公共安全、公共卫生、重大公共利益直接相关的；
4. 与刑事侦查、起诉、审判和判决执行等直接相关的；
5. 出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；
6. 所涉及的个人信息是个人信息主体自行向社会公众公开的；
7. 根据个人信息主体要求签订和履行合同所必需的；
8. 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
9. 维护所提供产品或服务的安全稳定运行所必需的，如发现、处置产品或服务的故障；
10. 个人信息控制者为新闻单位，且其开展合法的新闻报道所必需的；
11. 个人信息控制者为学术研究机构，出于公共利益开展统计或学术研究所必要，且其对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的。

**特别提示**  
如信息无法单独或结合其他信息识别到您的个人身份，其不属于法律意义上您的个人信息；当您的信息可以单独或结合其他信息识别到您的个人身份时或我们将无法与任何特定个人信息建立联系的数据与其他您的个人信息结合使用时，这些信息在结合使用期间，将作为您的个人信息按照本政策处理与保护。

## 三、我们如何使用 Cookie 和同类技术

为使您获得更轻松的访问体验，您使用我们的产品或服务时，我们可能会通过采用各种技术收集和存储您访问City AI 服务的相关数据，在您访问或再次访问City AI 服务时，我们能识别您的身份，并通过分析数据为您提供更好更多的服务。包括使用小型数据文件识别您的身份，这么做是为了解您的使用习惯，帮您省去重复输入账户信息的步骤，或者帮助判断您的登录状态以及账户安全。这些数据文件可能是 Cookie，或您的浏览器或关联应用程序提供的其他本地存储（统称“Cookie”）。

我们不会将 Cookies 用于本隐私政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookies。您可以清除计算机上保存的所有 Cookies，大部分网络浏览器会自动接受 Cookies，但您通常可根据自己的需要来修改浏览器的设置以拒绝 Cookies；另外，您也可以清除软件内保存的所有 Cookies。

**您理解并知悉**：我们的某些产品/服务只能通过使用 Cookie 或同类技术才可得到实现，如您拒绝使用或删除的，您将可能无法获得我们的产品与/或服务的最佳的服务体验，同时也可能会对您的信息保护和账号安全性造成一定的影响。

## 四、我们如何共享、转让、公开披露您的个人信息

### （一）共享

我们非常重视保护您的个人信息。未经您的授权同意，我们不会与其他第三方公司、组织和个人共享您的个人信息，除非是匿名化或去标识化处理后的无法识别或关联到您的信息，且共享第三方无法重新识别此类信息的自然人主体。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享必要的个人信息。如果我们共享您的个人敏感信息，我们会将再次征求您的授权同意。如果第三方改变或超越您原授权同意范围使用您的个人信息，则我们会要求第三方再次征得您的授权同意。

1. **为实现我们的服务/功能所必需进行的共享**  
   在某些情况下，为向您提供我们的服务或实现我们的产品与/或服务的业务功能，我们必须共享您的个人信息，才能实现我们的产品与/或服务的核心功能或提供您需要的服务，例如：
   - 为我们的产品与/或服务提供功能支持的服务提供商，包括：向提供支付服务的支付机构提供您的订单支付相关信息等；
   - 我们接受尽职调查或审计时，与外部监管、专业咨询机构（如审计机构）等共享您的相关信息。

2. **与City AI 公司内部的必要、合理共享**  
   为便于我们基于统一的账号体系向您提供一致化服务、识别账号异常、保护用户或公众的人身财产安全免遭侵害以及便于您进行统一管理，您知悉并同意，您的相关个人信息可能会在我们的产品内部进行必要共享。

3. **实现安全与分析统计的共享信息**
   1. **保障使用安全**  
      我们非常重视账号与服务安全，为保障您和其他用户的账号与财产安全，使您和我们的正当合法权益免受不法侵害，我们和关联公司或服务提供商可能会共享必要的设备、账号及日志信息。
   
   2. **分析产品使用情况**  
      为分析我们服务的使用情况，提升用户使用的体验，可能会与关联方或第三方共享产品使用情况（崩溃、闪退）的统计性数据，这些数据难以与其他信息结合识别您的个人身份。

4. **帮助您参加营销推广活动**  
   当您选择参加我们及我们的关联方或第三方举办的有关营销活动时，可能需要您提供姓名、联系方式、银行账号信息。这些信息是个人敏感信息，拒绝提供可能会影响您参加相关活动，但不会影响其他功能。只有经过您的同意，我们才会将这些信息与关联方或第三方共享，以保障您在联合活动中获得体验一致的服务，或委托第三方及时向您兑现奖励。我们承诺：未经您单独授权同意，我们不会与其共享可以识别您身份的个人信息。

5. **其他情形**
   1. 为了保护您、我们的其他用户或员工、City AI 或社会公共利益、财产或安全（例如：欺诈或信用风险等）免遭损害而与第三方的共享；
   2. 依据您与我们签署的相关协议（例如：服务协议等）或法律文件而共享的；
   3. 符合您与其他第三人之间的有关约定的。

### （二）转让

我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：

1. **在获取明确同意的情况下转让**  
   获得您的明确同意后，我们会向其他方转让您的个人信息。

2. **在City AI 服务提供者发生合并、收购或破产清算情形**  
   或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。

### （三）公开披露

1. 除符合法律法规的规定进行公开披露或获得您授权同意外，我们不会公开披露您的信息，且我们公开披露您的个人信息会采用符合行业内标准的安全保护措施。
2. 对违规帐号、欺诈行为进行处罚公告时，我们会公开相关帐号信息，或因您出现违反法律法规或违反City AI 平台相关协议及规则的情况，或为保护City AI 平台或公众的人身财产安全免遭侵害，我们将可能依据法律法规、平台协议或征得您同意的情况下公开您的帐号信息。

### （四）共享、转让、公开披露个人信息时事先征得授权同意的例外

以下情形中，我们共享、转让、公开披露您的个人信息无需事先征得您的授权同意：

1. 与个人信息控制者履行法律法规规定的义务相关的；
2. 与国家安全、国防安全直接相关的；
3. 与公共安全、公共卫生、重大公共利益直接相关的；
4. 与刑事侦查犯罪侦查、起诉、审判和判决执行等直接相关的；
5. 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
6. 您自行向社会公众公开的个人信息；
7. 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。

## 五、我们如何保护和保存您的个人信息

### （一）您个人信息的保存

1. **存储地点**  
   我们在中华人民共和国境内运营City AI 服务中收集和产生的信息，仅存储在中华人民共和国境内。

2. **存储期限**  
   您提供给我们的信息可能会由我们根据备份流程定期存档或存储，并且保留的时间为该等信息收集时的目的、向您提供我们的产品和服务、解决争议、建立法律抗辩、进行审计，追求合法的商业目的、执行我们的协议并遵守适用的法律所要求的时间。例如，《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。

   我们判断个人信息的存储期限主要依据以下标准：

   1. 完成与您相关的交易目的、维护相应交易及业务记录，以应对您可能的查询或投诉；
   2. 保证我们为您提供服务的安全和质量；
   3. 您是否同意更长的留存期间；
   4. 是否存在关于保留期限的其他特别约定或法律法规规定。

   在超出保留期间后，我们会根据适用法律的要求删除或匿名化处理您的个人信息。

### （二）我们将采取以下手段保护您的信息

1. 我们一直都极为重视保护用户的个人信息安全，为此我们采用了符合行业标准的安全技术措施及组织和管理措施等保护措施以最大程度降低您的信息被泄露、毁损、误用、非授权访问、非授权披露和更改的风险。
2. 我们会采取符合业界标准的合理可行的安全措施和技术手段存储和保护您的个人信息，以防止您的信息丢失、遭到被未经授权的访问、公开披露、使用、修改、毁损、丢失或泄漏。我们会采取一切合理可行的措施，保护您的个人信息。我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击。
3. 我们定期对员工进行数据安全的意识培养和安全能力的培训，加强员工对于保护个人信息重要性的认识。我们会与接触您个人信息的员工、合作伙伴签署保密协议，明确岗位职责及行为准则，并建立了严格的访问权限控制、权限审批流程，确保只有授权人员才可访问个人信息。若有违反保密协议的行为，会被追究相关责任。
4. 我们有责任和义务提醒您互联网并非绝对安全的环境，我们建议您按照要求使用复杂密码，不要泄露自己的密码/验证码，不要随意转借账号和设备给他人使用，注意保护您的设备和个人信息安全。如在公共设备上登录使用City AI 的服务切勿记住密码，使用完毕后及时退出登录，谨防被他人冒用等。如您发现您的账号出现异常或盗用时，请及时修改您的密码，必要时可按照本政策中告知的方式与我们取得联系寻求帮忙。
5. 如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损的，我们会及时启动应急预案，采取合理救济措施，以尽可能降低对您个人信息和其他权益的影响。

### （三）个人隐私数据泄露事件处置

在不幸发生个人隐私数据泄露事件后，我们将按照相关法律法规的要求及时向您告知：

- 个人隐私数据泄露的基本情况和可能的影响；
- 我们已采取或将要采取的处置措施；
- 您可自主防范和降低风险的建议；
- 对您的补救措施等。

我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人隐私数据泄露事件的处置情况。

## 六、您如何管理个人信息

我们非常重视您对个人信息的关注，并尽全力保护您对于您个人信息访问、更正、删除的权利，以使您拥有充分的能力保障您的隐私和安全。您的权利包括：

### （一）访问、更正

我们鼓励您更新和修改您的信息以使其更准确有效。您可以查询、访问、管理您的个人账号信息。您可以点击“我的”，按访问、管理您的个人信息，并根据对应信息的管理方式自行完成或要求我们进行修改、补充。您可以在个人中心-设置界面更改您的密码，您也可以联系我们的人工客服或发送电子邮件至：feedback@zhijiucity.com，申请更正您的信息，我们承诺在15个工作日内完成核查和处理。

在访问、更正和删除前述信息时，我们可能会要求您进行身份验证，以保障您的账户安全。

### （二）删除

在以下情形中，您可以向我们提出删除个人信息的请求，但已做数据匿名化处理或法律法规另有规定的除外。

1. 处理目的已实现、无法实现或者为实现处理目的不再必要；
2. 我们停止提供产品或者服务，或者保存期限已届满；
3. 个人撤回同意；
4. 如果我们处理个人信息的行为违反了法律、行政法规或与您的约定；
5. 法律、行政法规规定的其他情形。

**个人信息删除**：指在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。当您或我们协助您删除相关信息后，因为适用的法律和安全技术限制，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并限制对其的任何进一步的处理，直到备份可以清除或实现匿名化。

### （三）拒绝、返还、转移、索取数据副本

您可以通过本政策文末提供的方式联系我们，向我们提出拒绝、返还、转移、索取个人信息副本的请求。我们承诺在15个工作日内完成核查和处理。

### （四）改变您授权同意的范围

如您想更改相关权限的授权（例如：存储数据等），您可以通过您的硬件设备进行修改。

如您想控制消息通知的接收机制，您可以在产品或服务中的相关功能设置界面进行操作处理。

您也可以通过本政策文末提供的方式联系我们，收回您的授权同意。当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。您也可以通过注销账号的方式，永久撤回我们继续收集您个人信息的全部授权。

请您理解，每个业务功能需要一些基本的个人信息才能得以完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理。

### （五）提出异议

若您认为我们对您个人信息的处理违反了本政策或相关法律法规的要求，您可以发送邮件方式（邮件地址：feedback@zhijiucity.com）提出您的异议。您的每一条宝贵建议我们承诺在15个工作日内完成回复和处理。

### （六）注销

在您需要终止使用我们的服务时，您可以申请注销您的账户，您可以联系我们的人工客服，申请注销您的账户。我们承诺在15个工作日内完成核查和处理。

在您注销账号后，您将无法再以此账号登录和使用我们的产品与服务及使用该账号进行登录的第三方产品和服务；该账号在我们的产品与服务使用期间已产生的但未消耗完毕的权益及未来的预期利益等全部权益将被清除；该账号下的内容、信息、数据、记录等将会被删除或匿名化处理（但法律法规另有规定或监管部门另有要求的除外）；同时，账号一旦注销完成，将无法恢复。

## 七、未成年人信息的保护

我们非常重视对未成年个人信息的保护。City AI 主要面向成年人。若您是未满18周岁的未成年人，在使用本产品前，应在您的父母或其他监护人的监护、指导下共同阅读并同意本隐私政策。若您是未满16周岁的未成年人的监护人，在使用City AI 及相关服务前，应为您的被监护人阅读并同意本隐私政策。

我们根据国家相关法律法规的规定保护未成年人的个人信息，只会在法律允许、父母或其他监护人明确同意或保护未成年人所必要的情况下收集、使用或披露未成年人的个人信息；如果我们发现在未事先获得可证实的监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关信息。

若您是未成年人的监护人，当您对您所监护的未成年人的个人信息有其他疑问时，请通过本隐私政策公示的联系方式与我们联系。

## 八、本政策如何更新

### （一）政策更新

为了给您提供更好的服务，本隐私政策也会随之更新。我们会通过在City AI 网站、移动端上发出更新版本并在生效前通过网站公告或以其他适当方式提醒您相关内容的更新，也请您访问City AI 以便及时了解最新的隐私政策。

### （二）重大变更

对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于邮件、短信或在浏览页面做特别提示等方式，说明隐私政策的具体变更内容）。

本政策所指的重大变更包括但不限于：

1. 我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
2. 我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
3. 个人信息共享、转让或公开披露的主要对象发生变化；
4. 第三方供应商合作发生变更时；
5. 我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；
6. 个人信息安全影响评估报告表明存在高风险时。

本隐私政策更新后，我们会在City AI 发出更新版本，并在更新后的条款生效前通过官方网站（https://zhijiucity.com/）公告或其他适当的方式提醒您更新的内容，以便您及时了解本隐私政策的最新版本。

## 九、如何联系我们

如您对本隐私政策有任何问题、意见或建议，或者需要就个人信息安全进行投诉、举报的，请发送邮件至 [feedback@zhijiucity.com](mailto:feedback@zhijiucity.com) 与我们联系。

我们将在15个工作日内回复。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益时，您还可以通过公司所在地人民法院寻求解决方案。

---  


**官方网站**: [https://zhijiucity.com/](https://zhijiucity.com/)

**联系邮箱**: [feedback@zhijiucity.com](mailto:feedback@zhijiucity.com)
`;

    return (
        <div className="full-screen-container">
            <div className="full-screen-content">
                <ReactMarkdown>{privacyPolicyContent}</ReactMarkdown>
                <Button type="primary" onClick={onClose}>
                    返回
                </Button>
            </div>
        </div>
    );
};

export default PrivacyPolicy;

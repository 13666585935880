import React, { createContext, useContext, useState } from 'react';

const BreadcrumbContext = createContext();

export const useBreadcrumb = () => useContext(BreadcrumbContext);

export const BreadcrumbProvider = ({ children }) => {
  const [breadcrumbPath, setBreadcrumbPath] = useState([]);
  const [currentComponent, setCurrentComponent] = useState('Home');

    const switchComponent = (componentName) => {
        setCurrentComponent(componentName);
    };

  return (
    <BreadcrumbContext.Provider value={{ breadcrumbPath, setBreadcrumbPath, currentComponent, switchComponent }}>
      {children}
    </BreadcrumbContext.Provider>
  );
};

// LineSketch.js
import React, { useState, useEffect, useRef, useContext } from 'react';
import { Button, Layout, Input, Card, Tooltip } from 'antd';
import ImageGallery from '@components/ImageGallery';
import BridgeSelector from '@components/bridge/BridgeSelector';
import Login from '@components/login/Login';
import { CloseCircleOutlined } from '@ant-design/icons';
import '@styles/bridge/Realpaint.css';
import { loras, bridgeLoras } from '@configs/loras';
import categories from '@configs/categories';
import { sendGenerationRequest, uploadImage } from '@services/apiService';
import '@styles/SplitPane.css';
import { MaskEditor, toMask } from '@components/mask-editor/MaskEditor';
import { AssistantContext } from '@contexts/AssistantContext';
import { handleError } from '@utils/apiUtils'; 
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '@contexts/AuthContext';
import ImageUploader from '@components/image/ImageUploader';
import CustomTextArea from '@components/input/CustomTextArea';
import ImageDisplay from '@components/ImageDisplay';
import { useBreadcrumb } from '@contexts/BreadcrumbContext';
import Parameters from '@components/bridge/Parameters';

const { Content } = Layout;
const { TextArea } = Input;

const LineSketch = (props) => {
  const { showBubble, showVideoBubble } = useContext(AssistantContext);
  const [inputOne, setInputOne] = useState('');
  const [buttonPrompt, setButtonPrompt] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [isUploadedImage, setIsUploadedImage] = useState(false); // New state to track image source
  const [rawImage, setRawImage] = useState(null);
  const canvasRef = useRef(null);
  const [toggles, setToggles] = useState(loras.map(() => false));
  const togglesInfo = useRef(loras.reduce((acc, lora, index) => ({ ...acc, [index]: lora.value }), {})).current;
  const [modelVersion, setModelVersion] = useState(bridgeLoras[0].key);
  const models = useRef(bridgeLoras.reduce((acc, lora) => ({ ...acc, [lora.key]: lora.value }), {})).current;
  const [loraInput, setLoraInput] = useState(models[bridgeLoras[0].key]);
  const [selectedBridgeType, setSelectedBridgeType] = useState(Object.keys(categories.specific)[0]);
  const [resolution, setResolution] = useState('1280x720');
  const [customWidth, setCustomWidth] = useState(1280);
  const [customHeight, setCustomHeight] = useState(720);
  const [imageDetails, setImageDetails] = useState(null);
  const [imageBatch, setImageBatch] = useState(null);
  const [imageCount, setImageCount] = useState(1);
  const [seed, setSeed] = useState(-1);
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [page] = useState(1);
  const [size] = useState(10);
  const [isSessionStorageSet, setIsSessionStorageSet] = useState(false);
  const [hdOption, setHdOption] = useState(false);
  const { breadcrumbPath } = useBreadcrumb();

  const imageGalleryRef = useRef(null);
  const categorySelectorRef = useRef(null);
  const { token, isAuthenticated, currentFunc, setCurrentFunc } = useContext(AuthContext);
  
  // 新增的状态，用于存放参考图的imageUrl
  const [referenceImageUrl, setReferenceImageUrl] = useState(null);

  const navigate = useNavigate();
  
  useEffect(() => {
    showVideoBubble();
    setCurrentFunc('linesketch');
    setLoraInput(buildLoraInput(modelVersion, toggles));
    setIsSessionStorageSet(true);
  }, []);

  const handleBridgeTypeChange = (value) => {
    const selectedModel = bridgeLoras.find((lora) => lora.name === value);
    if (!selectedModel) {
      console.error(`No model found for bridge type: ${value}`);
      return;
    }
    setSelectedBridgeType(value);
    setModelVersion(selectedModel.key);
    setLoraInput(buildLoraInput(selectedModel.key, toggles));
    if (categorySelectorRef.current) categorySelectorRef.current.clearSelections();
  };

  const handleResolutionChange = (value) => {
    if (value === 'custom') {
      setResolution('custom');
    } else if (value.includes('x')) {
      const [width, height] = value.split('x').map(Number);
      setResolution(value);
      setCustomWidth(width);
      setCustomHeight(height);
    }
  };

  const handleCustomResolutionInput = (key, value) => {
    const filteredValue = value.replace(/[^0-9]/g, '');
    if (key === 'customWidth') setCustomWidth(filteredValue);
    if (key === 'customHeight') setCustomHeight(filteredValue);
  };

  const handleImageCountChange = (count) => setImageCount(count);

  const buildLoraInput = (modelVersion, toggles) => {
    let loraInput = models[modelVersion];
    toggles.forEach((active, index) => {
      if (active) loraInput += togglesInfo[index];
    });
    return loraInput;
  };

  const onClickSend = async () => {
    if (!isAuthenticated) {
      setIsLoginModalVisible(true);
      return;
    }
    if (!selectedImage) {
      showBubble('请先选选择你的线稿哦！');
      return;
    }
    if (!referenceImageUrl) {
      showBubble('请上传参考图哦！');
      return;
    }
    const canvas = canvasRef.current;
    const mask = toMask(canvas);

    // Determine init_images based on the source of selectedImage
    const initImages = isUploadedImage ? [selectedImage] : [];

    // 在payload中增加 reference_image 参数
    const payload = JSON.stringify({
      button_prompt: buttonPrompt,
      lora: loraInput,
      prompt: inputOne,
      n_iter: imageCount,
      seed,
      currentFunc,
      wf_code: 10002,
      width: customWidth,
      height: customHeight,
      force_task_id: "x-" + Math.random().toString(36).slice(-8),
      init_images: initImages, // Updated to prefer uploaded image
      mask,
      controlnet: [],
      reference_image: referenceImageUrl || null,
    });
    try {
      const response = await sendGenerationRequest(payload);
      handleResponse(response);
    } catch (error) {
      handleError(error, showBubble);
    }
  };

  const handleResponse = (response) => {
    if (response.code === 1000) {
      showBubble('提交任务成功！正在生成，请耐心等待哦!');
    }
    if (imageGalleryRef.current) {
      imageGalleryRef.current.loadHistory(page, size, currentFunc);
    }
  };

  const handleLoginModalClose = () => setIsLoginModalVisible(false);

  const handleLoginSuccess = () => {
    imageGalleryRef.current?.loadHistory(1, 10, currentFunc);
    setIsLoginModalVisible(false);
    navigate(0);
  };

  // Modified selectImage to track the source
  const selectImage = (imageDetails, imageBatch) => {
    setSelectedImage(imageDetails.fullUrl);
    setImageDetails(imageDetails);
    setImageBatch(imageBatch);
    setIsUploadedImage(false); // Image selected from gallery
  };
  
  const handleInputOneChange = (e) => {
    setInputOne(e.target.value);
  };

  const handleHdOptionChange = (value) => {
    setHdOption(value);
  };

  // 新增的函数用于上传参考图
  const handleReferenceImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = async () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        
        const maxWidth = 800; 
        const scaleFactor = maxWidth / img.width;
        canvas.width = maxWidth;
        canvas.height = img.height * scaleFactor;
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        const resizedImageBase64 = canvas.toDataURL('image/jpeg', 0.8); 
        const base64Image = resizedImageBase64.split(',')[1];

        try {
          const response = await uploadImage(base64Image, currentFunc);
          const imageUrl = response.imageUrl;
          setReferenceImageUrl(imageUrl);
          showBubble('参考图上传成功！');
        } catch (error) {
          console.error("参考图上传失败:", error);
          showBubble('参考图上传失败，请重试');
        }
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };

  const clearReferenceImage = () => {
    setReferenceImageUrl(null);
  };

  return (
    <Layout style={{ height: '100%', width: '100%', display: 'flex', backgroundColor: '#101214' }}>
      <div className="flex-container">
        <Content className="l1-left-container">
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
            <span className="label-style" style={{ marginRight: '8px' }}>桥型</span>
            <BridgeSelector selectedBridgeType={selectedBridgeType} handleBridgeTypeChange={handleBridgeTypeChange} />
          </div>
          <Parameters 
            imageCount={imageCount}
            resolution={resolution}
            customWidth={customWidth}
            customHeight={customHeight}
            hdOption={hdOption}
            setHdOption={handleHdOptionChange}
            handleResolutionChange={handleResolutionChange}
            handleCustomResolutionInput={handleCustomResolutionInput}
            handleImageCountChange={handleImageCountChange}
          />
          <div style={{ marginTop: '20px', color: '#fff' }}>
            <span className="label-style" style={{ marginRight: '8px' }}>上传参考图：</span>
            <input 
              type="file" 
              onChange={handleReferenceImageUpload} 
              accept="image/*" 
              style={{ marginBottom: '10px', color: '#fff' }}
            />
            {referenceImageUrl && (
              <Card 
                style={{
                  width: '150px',
                  position: 'relative',
                  backgroundColor: '#2a2a2a',
                  border: '1px solid #444',
                  borderRadius: '4px'
                }}
                cover={
                  <img 
                    src={referenceImageUrl} 
                    alt="参考图"
                    style={{ 
                      width: '100%',
                      height: 'auto',
                      borderTopLeftRadius: '4px',
                      borderTopRightRadius: '4px'
                    }}
                  />
                }
              >
                <CloseCircleOutlined
                  onClick={clearReferenceImage}
                  style={{
                    position: 'absolute',
                    top: '8px',
                    right: '8px',
                    fontSize: '20px',
                    color: '#ff4d4f',
                    cursor: 'pointer',
                  }}
                />
                <div style={{ marginTop: '5px', textAlign: 'center', color: '#ccc' }}>参考图已上传</div>
              </Card>
            )}
          </div>
        </Content>

        <Content className="l1-middle-container">
          <div className="breadcrumb__path">
            {breadcrumbPath.join(' / ')}
          </div>
          {selectedImage ? (
            isUploadedImage ? (
              // Display MaskEditor if image is uploaded
              <MaskEditor src={selectedImage} canvasRef={canvasRef} isDrawingAllowed={false} />
            ) : (
              // Display ImageDisplay if image is selected from gallery
              <div className="canvas-container">
                <ImageDisplay imageDetails={imageDetails} imageBatch={imageBatch}/>
                <CloseCircleOutlined
                  style={{
                    position: 'absolute',
                    bottom: 10,
                    right: 10,
                    fontSize: '24px',
                    color: 'red',
                    cursor: 'pointer',
                  }}
                  onClick={() => setSelectedImage(null)}
                />
              </div>
            )
          ) : (
            // Wrap ImageUploader with a custom handler to set isUploadedImage
            <ImageUploader onImageSelected={(imageUrl) => {
              setSelectedImage(imageUrl);
              setIsUploadedImage(true);
            }}/>
          )}
          <CustomTextArea
            imageCount={imageCount}
            resolution={resolution}
            customWidth={customWidth}
            customHeight={customHeight}
            hdOption={hdOption}
            setHdOption={handleHdOptionChange}
            handleResolutionChange={handleResolutionChange}
            handleCustomResolutionInput={handleCustomResolutionInput}
            handleImageCountChange={handleImageCountChange}
            inputValue={inputOne}
            onInputChange={handleInputOneChange}
            onGenerate={onClickSend}
          />
        </Content>

        <Content className='l1-right-container'>
          <div className="breadcrumb__down__section">
            <span className="breadcrumb__path">历史记录</span>
            <div style={{ flex: 1 }}>
              {isSessionStorageSet && (
                <ImageGallery
                  ref={imageGalleryRef}
                  size={size}
                  page={page}
                  onSelectImage={selectImage}
                  setRawImage={setRawImage}
                />
              )}
            </div>
          </div>
        </Content>

        {isLoginModalVisible && <Login onClose={handleLoginModalClose} onLogin={handleLoginSuccess} />}
      </div>
    </Layout>
  );
};

export default LineSketch;

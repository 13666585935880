import React, { useState } from 'react';
import '@styles/Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useBreadcrumb } from '@contexts/BreadcrumbContext';
import { useNavigate } from 'react-router-dom';

import {
  faBridge,
  faPerson,
  faPeopleGroup,
  faBook,
  faHistory,
  faComment,
  faUser,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons';

const SelfNavbar = ({ onNavClick }) => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const { setBreadcrumbPath } = useBreadcrumb();
  const navigate = useNavigate();

  const mainMenuItems = [
    { name: 'Home', label: '主页', icon: faBridge },
    { name: 'Info', label: '个人', icon: faPerson },
    {
      name: 'Organization',
      label: '组织',
      icon: faPeopleGroup,
      subMenu: [
        { name: 'MyOrganization', label: '我的组织'},
        // { name: 'MyModel', label: '模型管理'},
      ],
    }
  ];

  const bottomMenuItems = [
    // { name: 'Guide', label: '指南', icon: faBook },
    { name: 'History', label: '历史', icon: faHistory },
    { name: 'Feedback', label: '反馈', icon: faComment },
  ];

  const handleMenuClick = (item) => {
    if(item.name == "Home"){
      navigate('/main');
    }
    if (item.subMenu && item.subMenu.length > 0) {
      setActiveMenu(activeMenu === item.name ? null : item.name);
      setActiveSubMenu(null);
    } else {
      setBreadcrumbPath([item.label]);
      onNavClick(item.name);
      setActiveMenu(null);
    }
  };
  

  const handleSubMenuClick = (subItem) => {
    if (subItem.thirdLevelMenu) {
      setBreadcrumbPath([subItem.label]);
      setActiveSubMenu(activeSubMenu === subItem.name ? null : subItem.name);
    } else {
      setBreadcrumbPath([subItem.label]);
      onNavClick(subItem.name);
      setActiveMenu(null);
    }
  };

  const handleThirdMenuClick = (thirdItem) => {
    setBreadcrumbPath([
      mainMenuItems.find((menu) => menu.name === activeMenu)?.label,
      mainMenuItems
        .find((menu) => menu.name === activeMenu)
        ?.subMenu.find((sub) => sub.name === activeSubMenu)?.label,
      thirdItem.label,
    ]);
    
    onNavClick(thirdItem.name);
    setActiveMenu(null);
    setActiveSubMenu(null);
  };

  return (
    <div className="navbar">
      <ul className="main-menu">
        {mainMenuItems.map((item) => (
          <li key={item.name} className="menu-item-wrapper">
            <div onClick={() => handleMenuClick(item)} className="menu-item">
              <div className="menu-item-content">
                <FontAwesomeIcon icon={item.icon} className="menu-icon" />
                <span>{item.label}</span>
              </div>
            </div>
            {activeMenu === item.name && item.subMenu && (
              <div className="submenu-popup">
                {item.subMenu.map((subItem) => (
                  <div key={subItem.name} className="submenu-item">
                    <div
                      onClick={() => handleSubMenuClick(subItem)}
                      className="submenu-label"
                    >
                      <span>{subItem.label}</span>
                      {subItem.thirdLevelMenu && (
                        <FontAwesomeIcon
                          icon={
                            activeSubMenu === subItem.name
                              ? faChevronUp
                              : faChevronDown
                          }
                          className="submenu-arrow"
                        />
                      )}
                    </div>
                    {activeSubMenu === subItem.name &&
                      subItem.thirdLevelMenu && (
                        <div className="third-menu show">
                          {subItem.thirdLevelMenu.map((thirdItem) => (
                            <div
                              key={thirdItem.name}
                              onClick={() => handleThirdMenuClick(thirdItem)}
                              className="third-menu-item"
                              style={{ color: '#7f7f7f' }}
                            >
                              {thirdItem.label}
                            </div>
                          ))}
                        </div>
                      )}
                  </div>
                ))}
              
              </div>
            )}
          </li>
        ))}
      </ul>
      <ul className="bottom-menu">
        {bottomMenuItems.map((item) => (
          <li key={item.name} className="bottom-item">
            <a
              onClick={() => onNavClick(item.name)}
              className="menu-item"
            >
              <div className="menu-item-content">
                <FontAwesomeIcon icon={item.icon} className="menu-icon" />
                <span>{item.label}</span>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SelfNavbar;

import React, { useState, useEffect } from 'react';
import { API_ENDPOINTS, API_HEADERS } from '@configs/api';
import { message } from 'antd';
import { getLoginImage } from '@services/apiService';
import { useNavigate } from 'react-router-dom';
import UserAgreement from './UserAgreement';
import PrivacyPolicy from './PrivacyPolicy';

const Register = ({ onClose, onRegister }) => {
    const [registerType, setRegisterType] = useState('phone_number');
    const [username, setUsername] = useState('');
    const [contact, setContact] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [code, setCode] = useState('');
    const [imageList, setImageList] = useState([]);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [countdown, setCountdown] = useState(0);
    const [isSending, setIsSending] = useState(false);
    const [isAgreementChecked, setIsAgreementChecked] = useState(false);
    const [showUserAgreement, setShowUserAgreement] = useState(false);
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const images = await getLoginImage();
                setImageList(images);
            } catch (error) {
                console.error('获取注册页面图片失败：', error);
            }
        };
        fetchImages();
    }, []);

    useEffect(() => {
        if (imageList.length > 0) {
            const intervalId = setInterval(() => {
                setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageList.length);
            }, 3000);
            return () => clearInterval(intervalId);
        }
    }, [imageList]);

    const handleSendCode = async () => {
        if (!contact) {
            message.error(`请输入有效的${registerType === 'phone_number' ? '手机号' : '邮箱地址'}`);
            return;
        }
        setIsSending(true);
        try {
            const endpoint = registerType === 'phone_number' 
                ? `${API_ENDPOINTS.sendPhoneCode}?phone_number=${contact}`
                : `${API_ENDPOINTS.sendCode}?email=${contact}`;

            const response = await fetch(endpoint, {
                method: 'POST',
                headers: API_HEADERS,
            });

            if (response.ok) {
                message.success('验证码已发送，请检查您的短信或邮箱');
                setCountdown(60);
            } else {
                message.error('发送验证码失败，请稍后重试');
            }
        } catch (error) {
            message.error('请求失败，请稍后再试');
        }
        setIsSending(false);
    };

    useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
            return () => clearTimeout(timer);
        }
    }, [countdown]);

    const handleRegister = async () => {
        if (!isAgreementChecked) {
            message.error('请先同意用户协议和隐私协议');
            return;
        }

        if (password !== confirmPassword) {
            message.error('密码不一致，请重新输入', 1.5);
            return;
        }

        if (username.length < 3) {
            message.error('用户名长度必须至少3位', 1.5);
            return;
        }

        if (password.length < 8) {
            message.error('密码长度必须至少8位', 1.5);
            return;
        }

        const requestBody = {
            username,
            [registerType]: contact,
            password,
            code,
        };

        try {
            const response = await fetch(API_ENDPOINTS.register, {
                method: 'POST',
                headers: {
                    ...API_HEADERS,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            if (response.ok) {
                const data = await response.json();
                onRegister();
            } else {
                const errorData = await response.json();
                const errorMessage = errorData.detail.message || '注册失败';
                message.error(errorMessage, 1.5);
            }
        } catch (error) {
            message.error('请求失败，请稍后再试', 1.5);
        }
    };

    return (
        <div className="register-modal">
            {showUserAgreement && <UserAgreement onClose={() => setShowUserAgreement(false)} />}
            {showPrivacyPolicy && <PrivacyPolicy onClose={() => setShowPrivacyPolicy(false)} />}

            <div className="register-container">
                <div className="register-image">
                    {imageList.length > 0 && (
                        <img
                            src={imageList[currentImageIndex]}
                            alt="Register"
                            className="fade-in"
                        />
                    )}
                </div>
                <div className="login-content">
                    <h2>注册 Register</h2>
                    <div className="switch-register-type">
                        <button
                            onClick={() => setRegisterType('phone_number')}
                            className={registerType === 'phone_number' ? 'active' : ''}
                        >
                            手机注册
                        </button>
                        <button
                            onClick={() => setRegisterType('email')}
                            className={registerType === 'email' ? 'active' : ''}
                        >
                            邮箱注册
                        </button>
                    </div>
                    <input
                        type="text"
                        placeholder="用户名，至少3位数字或字母"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <div className="code-group">
                        <input
                            type={registerType === 'phone_number' ? 'tel' : 'email'}
                            placeholder={registerType === 'phone_number' ? '手机号' : '邮箱'}
                            value={contact}
                            onChange={(e) => setContact(e.target.value)}
                        />
                        <button
                            onClick={handleSendCode}
                            disabled={countdown > 0 || isSending}
                        >
                            {countdown > 0 ? `${countdown}秒后重试` : '发送验证码'}
                        </button>
                    </div>
                    <input
                        type="text"
                        placeholder="验证码"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                    />
                    <input
                        type="password"
                        placeholder="密码，至少8位数字或字母"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <input
                        type="password"
                        placeholder="确认密码"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <div className="agreement-section">
                        <label>
                            <input
                                type="checkbox"
                                checked={isAgreementChecked}
                                onChange={(e) => setIsAgreementChecked(e.target.checked)}
                            />
                            我已阅读并同意
                            <span onClick={() => setShowUserAgreement(true)}>《用户协议》</span>
                            和
                            <span onClick={() => setShowPrivacyPolicy(true)}>《隐私协议》</span>
                        </label>
                    </div>
                    <div className="button-group">
                        <button onClick={handleRegister} disabled={!isAgreementChecked}>
                            注册
                        </button>
                        <button onClick={onClose}>取消</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;

import React, { useEffect, useState } from 'react';
import '@styles/Assistant/VideoModal.css';

const VideoModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;
    const [videoUrl, setVideoUrl] = useState('');
    const [activeTab, setActiveTab] = useState('bridge-q');

    const videoMapping = {
        "bridge-q": 'https://backend.temp.fit/static/demo_video/q01.mp4',
        "bridge-r": 'https://backend.temp.fit/static/demo_video/r01.mp4',
        "bridge-i": 'https://backend.temp.fit/static/demo_video/i01.mp4',
        "bridge-t": 'https://backend.temp.fit/static/demo_video/t01.mp4',
    };

    const tabNames = {
        "bridge-q": "快速出图",
        "bridge-r": "实景生图",
        "bridge-i": "局部涂鸦",
        "bridge-t": "风格迁移",
    };

    useEffect(() => {
        if (isOpen) {
            const currentFunc = sessionStorage.getItem('currentFunc');
            if (currentFunc && videoMapping[currentFunc]) {
                setActiveTab(currentFunc);
            } else {
                setActiveTab('bridge-q'); // 默认标签
            }
        }
    }, [isOpen]);

    useEffect(() => {
        if (videoMapping[activeTab]) {
            setVideoUrl(`${videoMapping[activeTab]}?t=${Date.now()}`);
        }
    }, [activeTab]);
    

    return (
        <div className="demo-video-modal">
            <div className="demo-video-content">
                <span className="demo-video-close" onClick={onClose}>&times;</span>
                <h2>演示视频</h2>

                <div className="video-tabs">
                    {Object.keys(tabNames).map(tabKey => (
                        <button
                            key={tabKey}
                            className={activeTab === tabKey ? 'active' : ''}
                            onClick={() => {
                                setActiveTab(tabKey);
                            }}
                        >
                            {tabNames[tabKey]}
                        </button>
                    ))}
                </div>

                <video key={videoUrl} controls muted>
                    <source src={videoUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
    );
};

export default VideoModal;

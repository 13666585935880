export const handleError = (error, showBubble) => {
    if (error.response) {
      const { data } = error.response;
      const errorMessages = {
        1001: 'Token已过期，请重新登录！',
        1002: '抱歉，您暂无权限！',
        1003: '额度不足，请充值或续费高级会员！',
        1005: '有正在请求的任务！',
      };
      showBubble(errorMessages[data.detail.error_code] || `生成图片失败: ${data.detail.message}`);
      if (data.detail.error_code === 1001) {
        localStorage.removeItem('token');
        localStorage.removeItem('token_type');
      }
    } else if (error.request) {
      showBubble('无法连接到服务器');
    } else {
      showBubble(`请求失败: ${error.message}`);
    }
  };